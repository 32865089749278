import React from 'react'
import './caru.scss'
import { Link } from 'react-router-dom';
import NoImage from '../../assets/images/download.png'



const CarouselOne = ({title,description, date,id,image, category})=>{
    return (
        <Link to={`/single/post/${id}`}>
            <div className="carousel_one">
                <div className="left">
                    <span className="pin-block">{category}</span>
                    <img className="block-image" src={image === ""?NoImage:image} alt="" />
                    <span className="date-place">{date}</span>
                </div>
                <div className="right">
                    <h3 className="carousel-name">
                        {title}
                    </h3>
                    <div className="carousel-title">
                        {description}
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default CarouselOne