import React from 'react'
import { Fragment } from "react"
import '../../pages/about/about.scss'
import { useEffect, useState } from "react"
import { request } from "../../api/request"
import { useSelector } from "react-redux"
import parser from 'html-react-parser'
const AboutCenter = ()=>{
    const lang = useSelector(state =>state.data.lang)
    const [aboutCenter, setAboutCenter] = useState({
        isFetched:false,
        data:{},
        error:null
    })

    useEffect(()=>{
        request
        .get(`/content/one/about?id=44&lang=${lang === null ? 'uz':lang}`,{
            headers:{
                "api-token":"p12378"
            }
        })

        .then((res)=>{
            setAboutCenter({
                isFetched:true,
                data:res.data.data,
                error:false 
            })
        })

        .catch((err)=>{
            setAboutCenter({
                isFetched:true,
                data:[],
                error:err
            })
        })
    },[lang])


    return (
        <Fragment>
            <div className="place-holder">
                        <h3 className="center-holder">{aboutCenter.data && aboutCenter.data.title}</h3>
                    </div>
                    <div className="main-image">
                        <img src={aboutCenter.data && aboutCenter.data.image} alt="" />
                    </div>

                    <div className="main-page_overview">
                        <div className="place-block place-name">
                            {parser(`${aboutCenter.data && aboutCenter.data.description}`)}
                        </div>
                    </div>
        </Fragment>
    )
}

export default AboutCenter