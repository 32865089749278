import React, { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 20px;
  .question {
    width: 100%;
    background: #f2f2f2;
    padding: 10px;
    padding-left: 20px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    transition: all 0.6s cubic-bezier(0, 1, 0, 1);
    cursor: pointer;
    &:hover {
      background: #3987f3;
      h1 {
        color: white;
      }
      span {
        color: white;
      }
    }
    span {
      width: 4%;
      color: #808080;
      font-weight: bold;
      font-size: 20px;
    }
    h1 {
      width: 96%;
      font-size: 14px;
      color: #808080;
    }
  }
  .question.active {
    background: #3987f3;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: white;
    h1 {
      color: white;
    }
    span {
      color: white;
    }
  }
  .answer {
    max-height: 0;
    overflow: hidden;
    padding: 0 20px;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }
  .answer_show {
    height: auto;
    max-height: 1000px;
    padding: 10px 20px;
    font-size: 18px;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  }
`;

const Acardion = (props) => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <Wrapper>
      {props.data.map((e, i) => (
        <div key={i}>
          <div
            className={selected === i ? "question active" : "question"}
            onClick={() => toggle(i)}
          >
            <h1>{e.question}</h1>
            <span>{selected === i ? " -" : "+"}</span>
          </div>
          <div className={selected === i ? "answer_show" : "answer"}>
            {e.answer}
          </div>
        </div>
      ))}
    </Wrapper>
  );
};
export default Acardion;
