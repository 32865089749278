import React from 'react'
import './chart.scss'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    ArcElement,
    PointElement,
    LineElement,
    Filler
} from 'chart.js';
import { saveAs } from 'file-saver'; 
import { Bar , PolarArea, Pie, Line, Doughnut, Radar} from 'react-chartjs-2';
import Download from '../../assets/icons/download (1).png'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    ArcElement,
    PointElement,
    LineElement,
    Filler
);






const  PieChart = ({labelData, datas, color, type, id, title, description})=> {
    const labels = labelData;
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'right',
          },
          title: {
            display: true,
            text: title,
            font: {
              weight: 'bold',
              size: '17'
            },
          },
        },
      };
    const data = {
        labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: datas,
            backgroundColor: color,
          },
        ],
      };
      const saveCanvas = ()=> {
        const canvasSave = document.getElementById(id)
        canvasSave.toBlob(function (blob) {
            saveAs(blob, "diagram")
        })
      }
  return (
      <div className='wr-chr'>
            <button className='save-btn' onClick={saveCanvas}><img src={Download} alt="" /></button>
            {/* {
              description === "" ? '' : (
                <p className='chart-description'>
                  {
                    description
                  }
                </p>
              )
            } */}
            {
                type === 'vertical-bar-chart' ? (<Bar options={options} data={data} id={id} />):
                type === 'pie' ? (<Pie options={options} data={data} id={id} />):
                type === 'polar-area' ? (<PolarArea options={options} data={data} id={id} />):
                type === 'stacked-bar-line' ? (<Line options={options} data={data} id={id} />):
                type === 'line-chart-boundaries'? (<Doughnut options={options} data={data} id={id} />):
                type === 'linear-scale-suggested-min-max' ? (<Radar options={options} data={data} id={id} />) : null
            }
      </div>
  )
      
  ;
}

export default PieChart
