export const set_data = (data) => {
    return{
        type: "SET_DATA",
        payload: data
    }
}
export const set_lang = (lang)=>{
    return{
        type: "SET_LANG",
        payload:lang
    }
}
export const set_home = (home) =>{
    return {
        type: "SET_HOME",
        payload:home
    }
}
export const set_chart = (data) => {
  return {
    type: "SET_CHART",
    payload: data,
  };
};
export const set_app = (app)=>{
    return {
        type:"SET_APP",
        payload:app
    }
}

export const set_water = (water)=>{
    return {
        type: "SET_WATER",
        payload: water
    }
}

export const set_news = (news) =>{
    return {
        type: "SET_NEWS",
        payload: news
    }
}

export const set_post = (post) =>{
    return {
        type: "SET_POST",
        payload: post
    }
}


export const set_title = (post) => {
    return {
        type: "SET_TITLE",
        payload: post
    }
}

export const set_dark = (dark) =>{
    return {
        type:'SET_DARK',
        payload: dark
    }
}