import React, {useEffect} from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {request} from "../../../api/request";

const Wrapper = styled.div`
  p {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-variant: tabular-nums;
    line-height: 1.5715;
    background-color: #fff;
    font-feature-settings: "tnum", "tnum";
  }
`;

export default function MilLoView({lang}) {

  const { id } = useParams();
    const fetchViev = async () => {
        request
            .get(`/tomchi/nwrmp_view/${id}?lang=${lang === null ? 'uz' : lang}`, {
                headers:{
                    "api-token":"p12378"
                }
            })
            .then((res) => {
                if(typeof res.data.data !== 'undefined'){
                    if(typeof res.data.data.content !== 'undefined'){
                        document.getElementById('title').innerHTML = res.data.data.title
                        document.getElementById('content').innerHTML = res.data.data.content
                    }
                }
            })
            .catch((err) => console.log(err))
    }
    useEffect(() => {
        fetchViev()
    },[lang,id]);
  return (
    <Wrapper id="nwrmp_wrap">
        <h2 style={{"textAlign":"center"}} id="title"></h2>
        <div id="content"></div>
    </Wrapper>
  );
}
