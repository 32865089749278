import { Pagination } from "antd";
import React, {useEffect, useState} from "react";
import Card2 from "../../../../components/Card/Card2";
import { paginate } from "../../../../components/paginate/paginate";
import {request} from "../../../../api/request";

export default function Hikoya({lang}) {
    const [stories, setStories] = useState([]);
    const [page, setPage] = useState(1);
    const paginated = paginate(stories, page, 10);
    const fetchStories = async () =>{
        request
            .get(`/tomchi/story?lang=${lang === null ? 'uz' : lang}`, {
                headers:{
                    "api-token":"p12378"
                }
            })
            // .then((res) => setContent(JSON))
            .then((res) => {
                if(typeof res.data.data !== 'undefined'){
                    setStories(res.data.data)
                }
            })
            .catch((err) => console.log(err))
    }
    useEffect(()=>{
        fetchStories()
    },[lang])
    return (
        <div>
            {paginated.map((e, index) => (
                <Card2
                    key={index}
                    img={e.img}
                    title={e.title}
                    link={`/project/activities/nwrmp/story/${e.id}`}
                />
            ))}
            <Pagination
                defaultCurrent={1}
                total={stories.length}
                onChange={(e) => setPage(e)}
            />
        </div>
    );
}
