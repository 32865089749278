import './news.scss'
import './newsresponse.scss'
import { Pagination } from 'antd';
import { useState, useEffect } from 'react'
import { request } from '../../api/request'
import React from 'react'
import TomchiLogo from '../../assets/icons/TOMCHILOGO.png'
import QRcode from '../../assets/qrCode.png'


import {
  SectionCard,
  News,
  Helm
} from '../../components/index'

import {
  Hero
} from '../../container/index'
import { useSelector } from 'react-redux';

const NewsPage = () => {

  const [active, setActive] = useState('')
  const newsTwo = useSelector(state => state.data.news)


  const [news, setNews] = useState({ isFetched: false, data: {}, error: null })
  const [miniNewsTwo, setMiniNewsTwo] = useState({ isFetched: false, data: {}, error: null })
  const [announc, setAnnounc] = useState({ isFetched: false, data: {}, error: null })
  const [section, setSection] = useState(true)
  const [ page, setPage ] = useState(1) 
  const [ pageTwo, setPageTwo ] = useState(1) 
  const [itemCount, setItemCount] = useState({isFetched: false, data: {}, error: null })
  const [itemCountTwo, setItemCountTwo] = useState({isFetched: false, data: {}, error: null })

  const lang = useSelector((state) => state.data.lang)

  useEffect(() => {
    request
      .get(`/content/all/post?lang=${lang === null ? 'uz' : lang}&category=${active}&page=${page}&sortby=created_on&sort=desc`, { headers: { "api-token": "p12378" } })
      .then((res) => { setNews({ isFetched: true, data: res.data.data, error: false }) })
      .catch((err) => { setNews({ isFetched: true, data: [], error: err }) })

    request
      .get(`/segment/all/${section ? 'post_category' : 'announce_category'}?lang=${lang === null ? 'uz' : lang}`, { headers: { "api-token": "p12378" } })
      .then((res) => { setMiniNewsTwo({ isFetched: true, data: res.data.data.reverse(), error: false }) })
      .catch((err) => { setMiniNewsTwo({ isFetched: true, data: [], error: err }) })

    request
      .get(`/content/all/announcement?lang=${lang === null ? 'uz' : lang}&category=${active}&page=${pageTwo}`, { headers: { "api-token": "p12378" } })
      .then((res) => { setAnnounc({ isFetched: true, data: res.data.data.reverse(), error: false }) })
      .catch((err) => { setAnnounc({ isFetched: true, data: [], error: err }) })

      request
      .get(`/content/all/announcement?lang=${lang === null ? 'uz' : lang}`, { headers: { "api-token": "p12378" } })
      .then((res) => { setItemCountTwo({ isFetched: true, data: res.data.data, error: false }) })
      .catch((err) => { setItemCountTwo({ isFetched: true, data: [], error: err }) })


    request
      .get(`/content/all/post?lang=${lang === null ? 'uz' : lang}`, { headers: { "api-token": "p12378" } })
      .then((res) => { setItemCount({ isFetched: true, data: res.data.data, error: false }) })
      .catch((err) => { setItemCount({ isFetched: true, data: [], error: err }) })
      
      window.scrollTo(0,0)
  }, [lang, section, active, page, pageTwo])

  const int = itemCount && itemCount.data.length
  const intTwo = itemCountTwo && itemCountTwo.data.length
  return (
    <div className="newspage">
      <Helm id={'32'} />
      <div className="hero-page">
        <Hero
          id={"32"}
          nameOne={lang === 'uz' ? 'Bosh sahifa' : lang === 'ru' ? 'Домой' : lang === 'en' ? 'Home' : ''}
          nameTwo={lang === 'uz' ? 'Yangiliklar va e\'lonlar' : lang === 'ru' ? 'Новости и объявления' : lang === 'en' ? 'News and Announcements' : ''}
          linkTwo={"/news"}
          linkOne={"/"}
        />
      </div>
      <div className="inner-page container">
        <div className="column-one">
          <div className="ul-wrapper">
            <span>{
              lang === 'uz' ? 'Bo\'limlar' :
                lang === 'ru' ? 'Разделы' :
                  lang === 'en' ? 'Sections' : ''
            }</span>
            <ul>
              <button
                onClick={() => setSection(true, setActive(""))}
                className={`${section === true ? "active" : ""}`}
              >
                {
                  lang === 'uz' ? 'Yangiliklar' :
                    lang === 'ru' ? 'Новости' :
                      lang === 'en' ? 'News' : ''
                }
              </button>
              <button
                onClick={() => setSection(false, setActive(""))}
                className={`${section === false ? "active" : ""}`}
              >
                {
                  lang === 'uz' ? 'E\'lonlar' :
                    lang === 'ru' ? 'Анонсы' :
                      lang === 'en' ? 'Announcements' : ""
                }
              </button>
            </ul>
          </div>

          <div className="ul-wrapper two">
            <span>{lang === 'uz' ? 'Kategoriyalar' : lang === 'ru' ? 'Категории' : lang === 'en' ? 'Categories' : ''}</span>
            <ul>
              <button
                onClick={() => setActive("")}
                className={`${active === "" ? "active" : ""}`}
              >
                {
                  lang === 'uz' ? 'Barchasi' :
                    lang === 'ru' ? 'Все' :
                      lang === 'en' ? 'All' : ''
                }
              </button>
              {miniNewsTwo.isFetched ? (
                miniNewsTwo.data &&
                miniNewsTwo.data.map((el, inx) => (
                  <button
                    key={inx}
                    onClick={() =>
                      el.id !== active ? setActive(el.id) : setActive("fak")
                    }
                    className={`${active === el.id ? "active" : ""}`}
                  >
                    {el.title}
                  </button>
                ))
              ) : (
                <></>
              )}
            </ul>
          </div>

          <div className="tomchi">
            <img src={QRcode} alt="" />
          </div>
          <div className="tomchi-links">
            <img src={TomchiLogo} alt="" />
            <h3 style={{
              fontSize: '13px'
            }} className='tomchi-title'>QR code skanerlang va Tomchi mobil ilovasini yuklab oling.</h3>
          </div>
        </div>
        <div className="column-two">
          {section ? (
            <>
              <div className="page-holder">
                <h1>{
                  lang === 'uz' ? 'Yangiliklar' :
                    lang === 'ru' ? 'Новости' :
                      lang === 'en' ? 'News' : ''
                }</h1>
              </div>
              <div className="section">
                {news.isFetched ? (
                  news.data &&
                  news.data.map((item, index) => (
                      <SectionCard
                        key={index}
                        title={item.title}
                        description={item.extra_desc}
                        date={item.created_on}
                        id={item.id}
                        type={"post"}
                        image={item.image}
                      />
                  ))
                ) : (
                  <h1>Loading ...</h1>
                )}
                <div className="pagination">
                    <Pagination defaultCurrent={1} total={int} onChange={(e) => setPage(e)}/>
                </div>
              </div>

            </>
          ) : (
            <>
              <div className="page-holder">
                <h1>{
                  lang === 'uz' ? 'E\'lonlar' :
                    lang === 'ru' ? 'Анонсы' :
                      lang === 'en' ? 'Announcements' : ""
                }</h1>
              </div>
              <div className="announcement">
                {announc.isFetched ? (
                  announc.data &&
                  announc.data.map((item, inx) => (
                    <SectionCard
                      key={inx}
                      title={item.title}
                      description={item.extra_desc}
                      date={item.created_on}
                      id={item.id}
                      type={"announcement"}
                      image={item.image}
                    />
                  ))
                ) : (
                  <h1>Loading ...</h1>
                )}
                <div className="pagination">
                    <Pagination defaultCurrent={1} total={intTwo} onChange={(e) => setPageTwo(e)}/>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="column-thre">
          <div className="page-holder">
            <h1>{
              lang === 'uz' ? 'So’ngi yangiliklar' :
                lang === 'ru' ? 'Последние новости' :
                  lang === 'en' ? 'Latest news' : ''
            }</h1>
          </div>

          {newsTwo && newsTwo.status === 1 ? (
            newsTwo.data &&
            newsTwo.data.map((item, index) => (
              <News
                key={index}
                date={item.created_on}
                title={item.title}
                id={item.id}
              />
            ))
          ) : (
            <h1>Loading ...</h1>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsPage