import './knowpack.scss'
import './knowpackResponse.scss'
import {
    Hero
}from '../../container/index'
import {
    News,
    SectionCard,
    Helm
}from '../../components/index'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { request } from '../../api/request'
import React from 'react'
import TomchiLogo from '../../assets/icons/TOMCHILOGO.png'
import { Pagination } from 'antd'
import QRcode from '../../assets/qrCode.png'


const KnowPack = ()=>{
  const headerState = useSelector(state => state.data.HeaderState);
    const [ open, setOpen ] = useState(headerState);
    const lang = useSelector(state=>state.data.lang)

    const [pageList, setPageList] = useState({ isFetched:false,data:{}, error:null})
    const [newList, setNewsList] = useState({isFetched:false,data:{},error:null})
    const [cate, setCate]=useState({isFetched:false,data:{},error:null})
    const [changeCate, setChangeCate]=useState('')
    const [ page, setPage ] = useState(1)


    const hasToken = JSON.parse(window.localStorage.getItem( "USER_CREDENTIALS"))
    const [privateData, setPrivateData] = useState({
      isFetched:false,
      data:{},
      error:null
    })

    const [itemCount, setItemCount] = useState({isFetched:false,data:{},error:null})

    const [title, setTitle] = useState('')
    const [titleTwo, setTitleTwo] = useState('')

    useEffect(()=>{


        request
        .get(`/content/all/knowledge?lang=${lang === null?'uz':lang}&category=${changeCate}&page=${page}`,{headers:{"api-token":"p12378"}})
        .then((res)=>setPageList({isFetched:true,data:res.data.data.reverse(),error:false}))
        .catch((err)=>setPageList({isFetched:true,data:[],error:err}))
        


        request
          .get(`/content/all/knowledge?lang=${lang === null?'uz':lang}`,{headers:{"api-token":"p12378"}})
          .then((res)=>setItemCount({isFetched:true,data:res.data.data.reverse(),error:false}))
          .catch((err)=>setItemCount({isFetched:true,data:[],error:err}))

        request
        .get(`/content/all/knowledge?lang=${lang === null?'uz':lang}&sortby=view_count&sort=DESC&limit=5`,{headers:{"api-token":"p12378"}})
        .then((res)=>setNewsList({isFetched:true,data:res.data.data.reverse(),error:false}))
        .catch((err)=>setNewsList({isFetched:true,data:[],error:err}))

        request
        .get(`/segment/category/knowledge_category?lang=${lang === null?'uz':lang}`,{headers:{"api-token":"p12378"}})
        .then((res)=>{
            setCate({isFetched:true,data:res.data.data,error:false})
        })
        .catch((err)=>setCate({isFetched:true,data:[],error:err}))

        request
        .get(`/content/all/knowledge?lang=${lang}`,{
          headers:{
            "api-token":"p12378",
            "X-Auth-Token" : hasToken && hasToken.token,
            "X-Auth-Signature": hasToken && hasToken.signature
          }
        })

        .then(res=>{
          setPrivateData({
            isFetched:true,
            data:res.data.data.reverse(),
            error:false
          })
        })

        .catch(err=>{
          setPrivateData({
            isFetched:true,
            data:[],
            error:err
          })
        })
      },[lang,changeCate, page, hasToken])

    const int = itemCount && itemCount.data.length



    return (
      <div className="knowledge-package">
        <Helm id={'34'}/>
        <div className="hero-page">
          <Hero
            id={"34"}
            nameOne={lang === 'uz' ? 'Bosh sahifa' : lang === 'ru' ? 'Домой' : lang === 'en' ? 'Home page' : ''}
            nameTwo={lang === 'uz' ? 'Bilimlar to\'plami' : lang === 'ru' ? 'Пакет знаний' : lang === 'en' ? 'Knowledge package' : ''}
            linkOne={"/"}
            linkTwo={"/knowpack"}
          />
        </div>
        <div className="inner-page container">
          <div className="column-one">
            <div className="open-blocks_on">
              {cate.isFetched ? (
                cate.data &&
                cate.data[0].map((el, inx) => (
                  <div className="open-block" key={inx}>
                    <div className="opener">
                      <button
                        onClick={() =>
                          {
                            el.title !== open ? setOpen(el.title) : setOpen("fak");
                            setTitle(el.title);
                            setTitleTwo(el.title)
                          }
                        }
                        className={`${open === el.title || headerState === el.title ? "active" : ""}`}
                      >
                        {el.title}
                      </button>
                    </div>
                    <div className={`opened ${open === el.title || headerState === el.title ? "active" : ""}`}>
                      <ul className="blocks-ul">
                        { el.child.map((el, inx) => (
                          <>
                          
                          <button
                            key={inx}
                            onClick={() =>
                              {
                                el.id !== changeCate
                                ? setChangeCate(el.id)
                                : setChangeCate('');
                                setTitle(el.title);
                                setTitleTwo(el.title)
                              }
                            }
                            className={` blocks-link ${
                              title === el.title ? 'active' : ''
                            }`}
                          >
                            {el.title}
                          </button>
                          <li className='last-openers'>
                          {
                              el.child && el.child.map((el,index) =>(
                                <button key={index} className={`blocks-link-two ${titleTwo === el.title ? 'active' : ''}`}
                                onClick={()=> {
                                  setChangeCate(el.id);
                                  setTitleTwo(el.title)
                                }}
                                >
                                  {el.title}
                              </button>
                              ))
                          }
                        </li>
                        </>
                        ))}
                        
                      </ul>
                    </div>
                  </div>
                ))
              ) : (
                <h1>Loading ...</h1>
              )}
            </div>

            <div className="tomchi" style={{marginTop: '20px'}}>
              
                <img src={QRcode} alt="" />
            </div>
            <div className="tomchi-links">
                <img src={TomchiLogo} alt="" />
                <h3 style={{
                  fontSize: '13px'
                }} className='tomchi-title'>QR code skanerlang va Tomchi mobil ilovasini yuklab oling.</h3>
              </div>
          </div>
          <div className="column-two">
            <div className="section-one" style={{marginBottom: '30px'}}>
              <h2 className="news-holder">{lang === 'uz' ?  'Bilimlar to\'plami' : lang==='ru' ? 'Сборник знаний': lang === 'en' ? 'A collection of knowledge': ''}</h2>
              {
                hasToken ? (
                  privateData.isFetched ?(
                    privateData.data && privateData.data.map((el, index)=>(
                      <SectionCard
                      key={index}
                      id={el.id}
                      date={el.created_on}
                      title={el.title}
                      description={el.extra_desc}
                      image={el.image}
                      type={"knowledge"}
                    />
                    ))
                  ):(
                    <h1>Loading ...</h1>
                  )
                ):(
                  pageList.isFetched ? (
                    pageList.data && pageList.data.map((el, inx) => (
                      <SectionCard
                        key={inx}
                        id={el.id}
                        date={el.created_on}
                        title={el.title}
                        description={el.extra_desc}
                        image={el.image}
                        type={"knowledge"}
                      />
                    ))
                  ) : (
                    <h1>Loading ...</h1>
                  )
                )
              }

              <Pagination defaultCurrent={1} total={int} onChange={(e) => setPage(e)}/>
            </div>
          </div>
          <div className="column-thr">
          <div className="block-holder">
              <h2>{lang === 'uz' ? 'So’ngi yangiliklar' : lang === 'ru' ? 'последние новости' : lang === 'en' ? 'Last news' : ''}</h2>
            </div>
            {newList.isFetched ? (
              newList.data &&
              newList.data.map((el, inx) => (
                <News
                  key={inx}
                  date={el.created_on}
                  title={el.title}
                  id={el.id}
                />
              ))
            ) : (
              <h1>Loading ...</h1>
            )}
          </div>
        </div>
      </div>
    );
}


export default KnowPack