import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {request} from "../../../api/request";

const Wrapper = styled.div`
  p {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-variant: tabular-nums;
    line-height: 1.5715;
    background-color: #fff;
    font-feature-settings: "tnum", "tnum";
  }
`;

export default function LoHiView({lang}) {
  const { id } = useParams();
    const fetchStory = async () =>{
        request
            .get(`/tomchi/story_view/${id}?lang=${lang === null ? 'uz' : lang}`, {
                headers:{
                    "api-token":"p12378"
                }
            })
            .then((res) => {
                if(typeof res.data.data !== 'undefined'){
                    document.getElementById("title").innerHTML = res.data.data.title
                    document.getElementById("content").innerHTML = res.data.data.content
                }
            })
            .catch((err) => console.log(err))
    }
    useEffect(()=>{
        fetchStory()
    },[lang,id])
  return (
    <Wrapper>
      <h2 style={{"textAlign":"center"}} id="title"></h2>
      <div id="content"></div>
    </Wrapper>
  );
}
