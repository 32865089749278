import './breadcrumb.scss'
import React from 'react'
import { Link } from 'react-router-dom'

const BreadCrumb = ({nameOne,nameTwo,nameThree,linkOne,linkTwo,linkThree})=>{
    return (
        <div className="crumb">
            <ul>
                <li>
                    <Link className="link" to={`${linkOne}`}>{nameOne}</Link>
                </li>
                <span className="line"></span>
                <li>
                    <Link className="link actibe" to={`${linkTwo}`}>{nameTwo}</Link>
                </li>
                <li>
                    <Link className="link" to={`${linkThree}`}>{nameThree}</Link>
                </li>
            </ul>
        </div>
    )
}

export default BreadCrumb