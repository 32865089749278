import './card.scss'
import CardIcon from '../../assets/icons/icon_inf.png'
import Bg from '../../assets/images/download.png'
import { Link } from 'react-router-dom'
import React from 'react'


const Card = ({theme, title, id,image})=>{
    return (
       
             <div className="card-wrapper">
            <div className="card-top">
                <img src={image === ""? Bg : image} alt="" />
            </div>

            <div className="card-bottom">
                <div className="card-info">
                    <div className="icon-wrap">
                        <img src={CardIcon} alt="" className="info-icon" />
                    </div>
                    <Link to={`/singleTwo/${id}`} className="card-theme">
                        {theme}
                    </Link>
                </div>

                <p className="card-title">
                    {title}
                </p>
            </div>
        </div>
     
    )
}


export default Card