import React from 'react'
import "./about.scss";
import "./aboutResponse.scss";
import { Hero } from "../../container/index";
import QRcode from '../../assets/qrCode.png'
import { Helm } from '../../components/index';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";

import Slider from "react-slick";
import TomchiLogo from '../../assets/icons/TOMCHILOGO.png'

import {
    News,
}from '../../components/index'
import {
    AbotCenter,
    Task,
    Leaders,
    Structure,
    Down
}from '../../container/index'

import { useState,useEffect} from 'react'

import {request}from '../../api/request'




const About = ()=>{
  const lang = useSelector(state => state.data.lang)
  const news = useSelector(state=> state.data.news)
   const [category, setCategory] = useState({
       isFetched:false,
       data:{},
       error:null
   })

   const [tabbar, setTabbar] = useState('44')

    useEffect(()=>{
        request
        .get('/content/all/about?sort=asc&sortby=sort&lang=uz',{
            headers:{
                "api-token":"p12378"
            }
        })

        .then((res)=>{
            setCategory({
                isFetched:true,
                data:res.data.data,
                error:false
            })
        })

        .catch((err)=>{
            setCategory({
                isFetched:true,
                data:[],
                error:err
            })
        })
    },[])


   


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 410,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            }
        ]
    };
    

    return (
      <div className="about-wrapper">
        <Helm id={'33'}/>
        <Hero
          id={"33"}
          nameOne={lang === 'uz' ? 'Bosh sahifa' : lang === 'ru' ? 'Домой' : lang === 'en' ? 'Home page' : ''}
          nameTwo={lang === 'uz' ? 'Markaz haqida' : lang === 'ru' ? 'О центре' : lang === 'en' ? 'About center' : ''}
          linkOne={"/"}
          linkTwo={"/about"}
        />
        <div className="inner-hero container">
          <div className="column_one">
            <div className="active-link">
              <ul>
                <div className="simple-navbar">
                  {category.isFetched ? (
                    category.data &&
                    category.data.map((el, index) => (
                      <li key={index}>
                        <button
                          className={`${tabbar === el.id ? "active" : ""}`}
                          id={el.id}
                          onClick={(e) => setTabbar(e.target.id)}
                        >
                          {el.title}
                        </button>
                      </li>
                    ))
                  ) : (
                    <h1>Loading ...</h1>
                  )}
                </div>
                <div className="slider-navbar">
                  <Slider {...settings}>
                    {category.isFetched ? (
                      category.data &&
                      category.data.map((el, index) => (
                        <div key={index} className="slick-link">
                          <li>
                            <button className={`${tabbar === el.id ? "active" : ""}`} id={el.id} onClick={(e) => setTabbar(e.target.id)}>{el.title}</button>
                          </li>
                        </div>
                      ))
                    ) : (
                      <h1>Loading ...</h1>
                    )}
                  </Slider>
                </div>
              </ul>
              <div
                className="tomchi-image"
              >
                <img src={QRcode} alt="" />
              </div>

              <div className="tomchi-links">
                <img src={TomchiLogo} alt="" />
                <h3 style={{
                  fontSize: '13px'
                }} className='tomchi-title'>QR code skanerlang va Tomchi mobil ilovasini yuklab oling.</h3>
              </div>

              
            </div>
          </div>
          <div className="column_two">
            {category.isFetched ? (
              tabbar === category.data[0].id ? (
                <AbotCenter />
              ) : tabbar === category.data[1].id ? (
                <Task />
              ) : tabbar === category.data[2].id ? (
                <Down />
              ) : tabbar === category.data[3].id ? (
                <Leaders />
              ) : tabbar === category.data[4].id ? (
                <Structure />
              ) : null
            ) : (
              <h1>Loading ...</h1>
            )}
          </div>
          <div className="column_thr">
            <div className="block-holder">
              <h2>{lang === 'uz' ? 'So’ngi yangiliklar' : lang === 'ru' ? 'последние новости' : lang === 'en' ? 'Last news' : ''}</h2>
            </div>

            {news && news.status === 1 ? (
              news.data &&
              news.data.map((el, index) => (
                <News
                  key={index}
                  date={el.created_on}
                  id={el.id}
                  title={el.title}
                />
              ))
            ) : (
              <h1>Loading ...</h1>
            )}
          </div>
        </div>
      </div>
    );
}


export default About
