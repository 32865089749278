import './water.scss'
import './waterSavingCardsResponse.scss'

import { HeroTwo } from '../../container'
import Tomchi from '../../assets/images/tomchi.png'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { request } from '../../api/request'
import { News } from '../../components'
import { Link } from 'react-router-dom'
import TomchiLogo from '../../assets/icons/TOMCHILOGO.png'

import React from 'react'

import parser from 'html-react-parser'
const WaterSaving = () => {
    const mobile_app = useSelector(state => state.data.app)
    const lang = useSelector(state => state.data.lang)
    const waterSaving = useSelector(state => state.data.water)

    const [active, setActive] = useState('one')

    const [news, setNews] = useState({
        isFetched: false,
        data: {},
        error: null
    })
    useEffect(() => {
        request
            .get(`/content/all/post?lang=${lang === null ? 'uz' : lang}`, {
                headers: {
                    "api-token": "p12378"
                }
            })

            .then((res) => {
                setNews({
                    isFetched: true,
                    data: res.data.data.reverse(),
                    error: false
                })
            })

            .catch((err) => {
                setNews({
                    isFetched: true,
                    data: [],
                    error: err
                })
            })
    }, [lang])

    return (
        <div className="page-wrapper">
            <div className="hero-place">
                <HeroTwo
                    title={'Suv tejash texnologiyalari'} nameOne={'Asosiy sahifa'} nameTwo={'Suv tejash texnologiyalari'} linkOne={'/'}
                />
            </div>

            <div className="container">
                <div className="main-place">
                    <div className="col-one">

                        <ul className='section-ul'>
                            <li>
                                <button onClick={() => setActive('one')} className={`section-btn ${active === 'one' ? "active" : ''}`}>Barchasi</button>
                            </li>
                            <li>
                                <button onClick={() => setActive('two')} className={`section-btn ${active === 'two' ? "active" : ''}`}>Yangiliklar</button>
                            </li>
                            <li>
                                <button onClick={() => setActive('three')} className={`section-btn ${active === 'three' ? 'active' : ''}`}>Maqolalar</button>
                            </li>
                        </ul>


                        <a href={mobile_app && mobile_app.data[43].settings_value}>
                            <img src={Tomchi} alt="" />
                        </a>

                        <div className="tomchi-links">
                <img src={TomchiLogo} alt="" />
                <h3 style={{
                  fontSize: '13px'
                }} className='tomchi-title'>QR code skanerlang va Tomchi mobil ilovasini yuklab oling.</h3>
              </div>
                    </div>
                    <div className="col-two">
                        {
                            active === 'one' ? (
                                <>
                                    <h1>Yangiliklar</h1>
                                    <div className="div">
                                        {
                                            waterSaving && waterSaving.status === 1 ? (
                                                waterSaving.data.reverse().filter(item => item.type === 'news').slice(0,2).map((el, index) => (
                                                        <div className="water-card" key={index}>
                                                            <Link to={`/singleTwo/${el.id}`}>
                                                                <img className="top-image" src={el.image} alt="" />
                                                                <div className="name-card">
                                                                    <img src="" alt="" />
                                                                    <p className="extra-text">{el.title}</p>
                                                                </div>
                                                                <div className="description">{parser(`${el.extra_desc}`)}</div>
                                                            </Link>
                                                        </div>
                                                ))
                                            ) : (
                                                <h1>Loading ...</h1>
                                            )
                                        }
                                    </div>
                                        <h1>Maqolalar</h1>
                                    <div className="div">

                                        {
                                            waterSaving && waterSaving.status === 1 ? (
                                                waterSaving.data.reverse().filter(item => item.type === 'article').slice(0,2).map((el, index) => (
                                                        <div className="water-card" key={index}>
                                                            <Link to={`/singleTwo/${el.id}`}>
                                                                <img className="top-image" src={el.image} alt="" />
                                                                <div className="name-card">
                                                                    <img src="" alt="" />
                                                                    <p className="extra-text">{el.title}</p>
                                                                </div>
                                                                <div className="description">{parser(`${el.extra_desc}`)}</div>
                                                            </Link>
                                                        </div>
                                                ))
                                            ) : (
                                                <h1>Loading ...</h1>
                                            )
                                        }
                                    </div>
                                </>
                            ) : active === 'two' ? (
                                <>
                                    {
                                        waterSaving && waterSaving.status === 1 ? (
                                            waterSaving.data.reverse().map((el, index) => (
                                                el.type === 'news' ? (
                                                    <div className="water-card" key={index}>
                                                        <Link to={`/singleTwo/${el.id}`}>
                                                            <img className="top-image" src={el.image} alt="" />
                                                            <div className="name-card">
                                                                <img src="" alt="" />
                                                                <p className="extra-text">{el.title}</p>
                                                            </div>
                                                            <div className="description">{parser(`${el.extra_desc}`)}</div>
                                                        </Link>
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            ))
                                        ) : (
                                            <h1>Loading ...</h1>
                                        )
                                    }
                                </>
                            ) : active === 'three' ? (
                                <>
                                    {
                                        waterSaving && waterSaving.status === 1 ? (
                                            waterSaving.data.reverse().map((el, index) => (
                                                el.type === 'article' ? (
                                                    <div className="water-card" key={index}>
                                                        <Link to={`/singleTwo/${el.id}`}>
                                                            <img className="top-image" src={el.image} alt="" />
                                                            <div className="name-card">
                                                                <img src="" alt="" />
                                                                <p className="extra-text">{el.title}</p>
                                                            </div>
                                                            <div className="description">{parser(`${el.extra_desc}`)}</div>
                                                        </Link>
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            ))
                                        ) : (
                                            <h1>Loading ...</h1>
                                        )
                                    }
                                </>
                            ) : null
                        }
                    </div>
                    <div className="col-thre">
                        <h1>So'ngi yangiliklar</h1>
                        {
                            news.isFetched ? (
                                news.data.map((el, index) => (
                                    <News
                                        key={index}
                                        date={el.created_on}
                                        title={el.title}
                                    />
                                ))
                            ) : (
                                <h1>Loading ...</h1>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default WaterSaving