import { Fragment } from "react"
import '../../pages/about/about.scss'
import { useSelector } from "react-redux"
import { useState, useEffect} from "react"
import parser from 'html-react-parser'
import React from 'react'
import { request } from "../../api/request"

const Task = ()=>{
    const lang = useSelector(state=>state.data.lang)

    const [task, setTask] = useState({
        isFetched:false,
        data:{},
        error:null
    })

    useEffect(()=>{
        request
        .get(`/content/one/about?id=45&lang=${lang === null ? 'uz':lang}`,{
            headers:{
                "api-token":"p12378"
            }
        })

        .then((res)=>{
            setTask({
                isFetched:true,
                data:res.data.data,
                error:false
            })
        })

        .catch((err)=>{
            setTask({
                isFetched:true,
                data:[],
                error:err
            })
        })
    },[lang])
    return (
        <Fragment>
            <div className="reja-maqsad" id="maqsad">
                        <h3 className="reja-title">{task.data && task.data.title}</h3>
                    </div>

                    <div className="maqsadlar">
                        {/*<h3>*/}
                        {/*    Вазифалари*/}
                        {/*</h3>*/}
                        <p>
                            {task.data && task.data.extra_desc}
                        </p>
                    </div>

                    <div className="maqsadlar" >
                        {/*<h3>*/}
                        {/*    Мақсади*/}
                        {/*</h3>*/}
                        <p>
                            {parser(`${task.data && task.data.description}`)}
                        </p>
                    </div>
        </Fragment>
    )
}

export default Task