import React from 'react'
import { HeroTwo } from "../../container"
import './seminar.scss'
import {News} from '../../components'
import { useSelector } from "react-redux"
import parser from 'html-react-parser'
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { request } from "../../api/request"
import TomchiLogo from '../../assets/icons/TOMCHILOGO.png'
import QRcode from '../../assets/qrCode.png'

import './seminarResponse.scss'

const Seminar = ({match})=>{
    const mobile_app = useSelector(state =>state.data.app)
    const news = useSelector(state => state.data.news)
    const lang = useSelector(state=>state.data.lang)
    const [seminar, setSeminar] =  useState({
        isFetched:false,
        data:{},
        error:null
    })

    const [apps, setApps] = useState({
        isFetched:false,
        data:{},
        error:null
    })

    const [material, setMaterial] = useState({
        isFetched:false,
        data:{},
        error:null
    })

    const [scheldule, setScheldule] = useState({
        isFetched:false,
        data:{},
        error:null
    })
    

    useEffect(()=>{
        request
        .get(`/segment/one/seminar?id=${match.params.id}&lang=${lang === null?'uz':lang}`,{
            headers:{
                "api-token":"p12378"
            }
        })

        .then((res)=>{
            setSeminar({
                isFetched:true,
                data:res.data.data,
                error:false
            })
        })

        .catch((err)=>{
            setSeminar({
                isFetched:true,
                data:[],
                error:err
            })
        })

        request
        .get(`/content/all/program?category=${match.params.id}&lang=${lang === null ? 'uz':lang}`,{
            headers:{
                "api-token":"p12378"
            }
        })
        .then((res)=>{setApps({isFetched:true,data:res.data.data,error:false})})
        .catch((err)=>{setApps({isFetched:true,data:[],error:err})})

        request
        .get(`/content/all/material?category=${match.params.id}&lang=${lang === null ? 'uz':lang}`,{
            headers:{
                "api-token":"p12378"
            }
        })
        .then((res)=>{setMaterial({isFetched:true,data:res.data.data,error:false})})
        .catch((err)=>{setMaterial({isFetched:true,data:[],error:err})})

        request
        .get(`/content/all/schedule?category=${match.params.id}&lang=${lang === null ? 'uz':lang}`,{
            headers:{
                "api-token":"p12378"
            }
        })
        .then((res)=>{setScheldule({isFetched:true,data:res.data.data,error:false})})
        .catch((err)=>{setScheldule({isFetched:true,data:[],error:err})})

    },[match.params.id,lang])

    const info = seminar.data && seminar.data

    return (
      <div className="seminar-wrapper">
        <HeroTwo
          title={lang === 'uz' ?  'Seminarlar' : lang === 'ru' ? 'Семинары' : lang === 'en' ? 'Seminars' : ''}
          nameOne={"Malaka oshirish"}
          nameTwo={"Seminarlar"}
          linkOne={"/experience"}
        />
        <div className="margin"></div>
        <div className="container">
          <div className="main-page">
            <div className="col-one">
              <a
                href={`${mobile_app ? mobile_app.data[43].settings_value : ""}`}
              >
                <img src={QRcode} alt="" />
              </a>

              <div className="tomchi-links">
                <img src={TomchiLogo} alt="" />
                <h3 style={{
                  fontSize: '13px'
                }} className='tomchi-title'>QR code skanerlang va Tomchi mobil ilovasini yuklab oling.</h3>
              </div>
            </div>
            <div className="col-two">
              <div className="image-holder">
                {
                  info.image === "" ? (
                    <></>
                  ):(
                    <img src={info.image} alt="" />
                  )
                }
              </div>

              <div className="information">
                <h1 className="title">{info.title}</h1>
                {parser(`${info.description}`)}
              </div>

              <div className="app">
                <h1>{lang === 'uz' ? 'Dasturlar' : lang === 'ru' ? 'Программное обеспечение' : lang === 'en' ? 'Programs' : ''}</h1>
                {apps.isFetched ? (
                  apps.data &&
                  apps.data.map((el, index) => (
                      
                    <>
                      <div className="exper-card">
                    <Link to={`/single/program/${el.id}`} key={index}>
                        <div className="download-text">
                          <img className="card-image" src={el.image} alt="" />
                          <h3 className="card-title">{el.title}</h3>
                        </div>
                    </Link>
                        <div className={`files ${el.link === "" ? 'activeTwo' : ''}` }>
                          <span className="file-size">{el.file_size}</span>
                          <a href={el.file} download className="download-file">
                          {lang === 'uz' ? 'Yuklab olish' : lang === 'ru' ? 'Скачать' : lang ==='en' ? 'Download' : ''}
                          </a>
                        </div>
                      </div>
                      </>
                  ))
                ) : (
                  <h1>Loading ...</h1>
                )}
              </div>

              <div className="materials">
                <h1>{lang === 'uz' ? 'Materiallar' : lang === 'ru' ? 'Материалы' : lang === 'en' ? 'Materials':''}</h1>
                {material.isFetched ? (
                  material.data &&
                  material.data.map((el, index) => (
                    <>
                    
                      <div className="exper-card">
                    <Link to={`/single/material/${el.id}`} key={index}>
                        <div className="download-text">
                          <img className="card-image" src={el.image} alt="" />
                          <h3 className="card-title">{el.title}</h3>
                        </div>
                    </Link>
                        <div className={`files ${el.link === "" ? 'activeTwo' : ''}` }>
                          <span className="file-size">{el.file_size}</span>
                          <a href={el.file} download className="download-file">
                          {lang === 'uz' ? 'Yuklab olish' : lang === 'ru' ? 'Скачать' : lang ==='en' ? 'Download' : ''}
                          </a>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <h1>Loading ...</h1>
                )}
              </div>

              <div className="sceldules">
                <h1>{lang === 'uz' ?  'Seminarlar jadvali' : lang === 'ru' ? 'Расписание семинаров' : lang === 'en' ? 'Schedule of seminars' : ''}</h1>

                {scheldule.isFetched ? (
                  scheldule.data &&
                  scheldule.data.map((el, index) => (
                    <div className="exper-card">
                        <Link to={`/single/schedule/${el.id}`} key={index}>
                          <div className="download-text">
                            <img className="card-image" src={el.image} alt="" />
                            <h3 className="card-title">{el.title}</h3>
                          </div>
                        </Link>
                        <div className={`files ${el.link === "" ? 'activeTwo' : ''}` }>
                          <span className="file-size">{el.file_size}</span>
                          <a href={el.file} download className="download-file">
                          {lang === 'uz' ? 'Yuklab olish' : lang === 'ru' ? 'Скачать' : lang ==='en' ? 'Download' : ''}
                          </a>
                        </div>
                      </div>
                  ))
                ) : (
                  <h1>Loading ...</h1>
                )}
              </div>
            </div>
            <div className="col-thre">
              <h1>So'ngi yangiliklar</h1>
              {news && news.status === 1 ? (
                news.data && news.data.map((el, index) => (
                  <News key={index} date={el.created_on} title={el.title} />
                ))
              ) : (
                <h1>Loading ...</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    );
}

export default Seminar