import React from "react";
import styled from "styled-components";

// const Table = styled.table`
//   margin: 20px 0;
//   thead {
//     th {
//       border: 1px solid black;
//       font-size: 20px;
//       padding: 10px;
//     }
//   }
//   tbody {
//     td {
//       border: 1px solid black;
//       padding: 10px;
//       font-size: 18px;
//       h5 {
//         font-weight: bold;
//         font-size: 20px;
//       }
//       p {
//         font-size: 18px;
//       }
//     }
//   }
// `;
const Wrapper = styled.div`
  .top-img {
    display: flex;
    justify-content: space-between;
  }
  img {
    width: 100%;
  }
  .one {
    width: 20%;
    h1 {
      font-size: 12px;
    }
  }
  .two {
    width: 30%;
    h1 {
      font-size: 20px;
      text-align: center;
    }
  }
  .three {
    width: 20%;
    h1 {
      font-size: 12px;
    }
  }
  .coordination_Downloads {
    padding-bottom: 20px;
    a {
      border: none;
      padding: 5px 30px 5px 22px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      color: #3987f3;
      background: rgba(57, 135, 243, 0.2);
      border-radius: 10px;
      cursor: pointer;
      display: inline-block;
      margin: 0 5px 5px 0;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 11.67px;
        height: 10.5px;
        background-size: contain;
        right: 6px;
        top: 50%;
        transform: translateY(-56%);
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEUSURBVHgBpVJLUoNAEO1uRi0XWvEGHIElriQn8AgmJ0iypNxguWCrJzA30BuwlGWOwA1IycLSDNNpJgn5VSCp9OZRvHmv34MBaJggyjtNPB0i/Nci+LtSuR8XwcliUOxaNEs8SXzEnCXG3Rd+nLuoHY8VdBDwgw33EWHKWE7S8C7bPKv27JgiUPhEjBNGBkIaCHoEF2Nh+9AYG00kDlklsF4WOTMwe2mNXUcHJxHatUZQdncjW3F1EeR/JtLtPX2+HW8bqCGDftsU+vFPTyoNLv91d9XZI6IHwVq8FAz3tiE+spyvY9/HRVIZyNf9gpYRYVBV+Q5vFpu1gyNVmk8hetA6PNVOOao3r/v8um3SNLzOVs9znwloNQHLRowAAAAASUVORK5CYII=);
      }
    }
  }
  table{
    tr{
      td:first-child{
        width: 15%;
        padding: 5px;
        text-align: center;
        vertical-align: middle;
        font-weight: 700;
      }
      td{
        padding: 5px;
      }
    }
  }
`;

export default function Rview() {
    return (
        <Wrapper>
            <div className="top-img">
                <div className="one">
                    <img
                        src="https://careerdevelopmentroundtable.com/wp-content/uploads/2019/09/logo-confederation-suisse-e1568383635140.jpg"
                        alt=""
                    />
                    <h1>Швейцарское агентство развития и сотрудничества SDC</h1>
                </div>
                <div className="two">
                    <img
                        src="https://iarc-assets.iarc.uz/uploads/iarclogo.gif "
                        alt=""
                    />
                </div>
                <div className="three">
                    <img src="https://water.gov.uz/images/logo2.png" alt="" />
                    <h1>Министерство водного хозяйства Республики Узбекистан</h1>
                </div>
            </div>
            <br/><br/>
            <div>
                <h2 style={{"textAlign":"center"}}>
                    ВСТРЕЧА ПАРТНЕРОВ ПО РАЗВИТИЮ УПРАВЛЕНИЯ ВОДНЫМИ СРЕДСТВАМИ
                </h2>
            </div>

            <div style={{"marginTop":"15px","textAlign":"justify"}}>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;31 мая 2022 года в Ташкенте состоялось заседание Рабочей группы сети по координации партнеров по развитию водного хозяйства.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;В развитии водного хозяйства нашей страны налажено тесное сотрудничество с международными и донорскими организациями. В связи с этим реализуется множество идей и проектов.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;Заседания Рабочей группы сети, ставшей площадкой для диалога партнеров по развитию водных ресурсов, проводятся регулярно с 2015 года.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;В его состав входят Министерство водного хозяйства Республики Узбекистан, профильные министерства и ведомства, международные и донорские организации, действующие в стране. Его основной задачей является налаживание эффективного диалога между донорами и государством для обеспечения эффективного сотрудничества в целях развития водного хозяйства и координации деятельности.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;На встрече руководитель Центра реализации иностранных инвестиционных проектов в водном хозяйстве Б. Камалов проинформировал о реформах, проводимых в рамках Концепции и Стратегии развития водного хозяйства.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;- Мы готовы поддержать реформы, проводимые Правительством Узбекистана, - сказала заведующая отделом сотрудничества Посольства Швейцарии в Узбекистане Рэйчел Биош. «С этой целью сегодня здесь собрались представители различных министерств, ведомств, международных и донорских организаций.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;Участники встречи обсудили вопросы повестки дня в целях согласования грантовых проектов, реализуемых в водном хозяйстве республики, недопущения дублирования деятельности в рамках проектов, повышения эффективности.</p>
                <p style={{"margin":"10px 0"}}>&nbsp;&nbsp;Мероприятие было организовано при поддержке Посольства Швейцарии и Швейцарского агентства по развитию и сотрудничеству.</p>
                <br/><br/>
            </div>
            <div>
                <h2>Файлы для скачивания</h2>
                <br />
            </div>
            <div className="coordination_Downloads">
                <a
                    className="btn btn-primary"
                    href="https://iarc-assets.iarc.uz/uploads/files/coordination/01iarcwsdm-ppt-eng.pptx"
                >
                    IARC WSDM PPT (Eng)
                </a>
                <a href="https://iarc-assets.iarc.uz/uploads/files/coordination/02sdcnwrmpstatus-eng.pptx">
                    SDC NWRMP status (Eng){" "}
                </a>
                <a href="https://iarc-assets.iarc.uz/uploads/files/coordination/03swissemnassypppforwatersectordonormeeting3.pptx">
                    Swiss Embassy PPT WSDM
                </a>
                <a href="https://iarc-assets.iarc.uz/uploads/files/coordination/04gizpptwater-sector-donor-meeting.pptx">
                    GIZ PPT WSDM 4 <i className="fa fa-download"></i>
                </a>
                <a href="https://iarc-assets.iarc.uz/uploads/files/coordination/05afd-for-water-sector-donor-meeting.pptx">
                    AFD WSDM
                </a>
                <a href="https://iarc-assets.iarc.uz/uploads/files/coordination/06eu-ppp-for-water-sector-donor-meeting-220530.pptx">
                    EU PPT WSDM{" "}
                </a>
                <a
                    target="_blank"
                    href="https://iarc-assets.iarc.uz/uploads/files/coordination/07adb-water-sector-donor-meeting-final.pdf"
                >
                    ADB WSDM FINAL{" "}
                </a>
                <a href="https://iarc-assets.iarc.uz/uploads/files/coordination/agenda.docx">Agenda (uzb/eng)</a>
                <a href="https://iarc-assets.iarc.uz/uploads/files/coordination/lopwsdm.docx">LOP_WSDPCM</a>
                <a href="https://iarc-assets.iarc.uz/uploads/files/coordination/final-to-circulate-water-sector-development-partners-meeting-minutes31-may-2022feedback-brx.docx
">Протокол заседаний</a>
            </div>

            <div><h2>Видео с WSDM</h2></div>
            <br/>
            <div>

                <iframe width="100%" height="400" src="https://www.youtube.com/embed/Q3HOUijuAQw" title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
            </div>
            <br/><br/>
            <div><h2>Проекты партнеров по развитию</h2></div>
            <br/>
            <ul>
                <li>
                    <h3>
                        <a target="_blank" href="https://projects.worldbank.org/en/projects-operations/projects-list?lang=en&countrycode_exact=UZ&os=0">
                            Проекты Всемирного банка в Узбекистане
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.adb.org/projects/country/uzb">
                            Проекты Азиатского банка развития в Узбекистане
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.undp.org/uzbekistan/projects">
                            Проекты ПРООН в Узбекистане
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.eeas.europa.eu/filter-page/projects_en?fulltext=Uzbekistan&start_from=&start_to=">
                            Проекты ЕС в Центральной Азии
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.eda.admin.ch/deza/en/home/countries/central-asia.html">
                            База данных Центральной Азии Швейцарского Агентства по развитию и сотрудничеству
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.giz.de/en/worldwide/364.html">
                            Проекты и программы Германского общества по международному сотрудничеству (GIZ)
                        </a>
                    </h3>
                </li>
                <li>
                    <h3>
                        <a target="_blank" href="https://www.afd.fr/en/page-region-pays/uzbekistan">
                            Проекты Французского агентства по развитию (AFD)
                        </a>
                    </h3>
                </li>
            </ul>
            <br/><br/><br/>
        </Wrapper>
    );
}
