import React, { useState } from "react";
import { News } from "../../components";
import "./tomchi.scss";
import "./tomchiResponsive.scss";
import { useSelector } from "react-redux";
import QRcode from "../../assets/qrCode.png";
import TomchiLogo from "../../assets/icons/TOMCHILOGO.png";
import { NavLink, Route } from "react-router-dom";
import Benefitsiarlar from "./Benefitsiars/Benefitsarlar";
import SavolJavob from "./FAQ/SavolJavob";
import MilliyLoyiha from "./nwrmp/MilliyLoyiha";
import LoyihaHikoyasi from "./story/LoyihaHikoyasi";
import MenuComponent from "./index";
import LoHiView from "./story/LoHiView";
import MilLoView from "./nwrmp/MilLoView";
import About from "./About/About";
import Youtube from "./youtube/Youtube";
import Socials from "./socials/Socials";

export default function Tomchi() {
  const lang = useSelector((state) => state.data.lang);
  const news = useSelector((state) => state.data.news);
  const [need, setNeed] = useState("");
  const forTitle = useSelector((state) => state.data.forTitle);

  const [menu, setMenu] = useState([
    {
      path: "/tomchi/about",
      title: {
        uz:"TOMCHI mobile ilova haqida",
        ru:"О мобильном приложении TOMCHI",
        en:"About TOMCHI mobile application"
      },
    },
    {
      path: "/tomchi/savol_javob",
      title: {
        uz:"Sizdan savol - bizdan javob",
        ru:"Вопрос от вас - ответ от нас",
        en:"Question from you - an answer from us"
      },
    },
    {
      path: "/tomchi/youtube",
      title: {
        uz:"TOMCHI Youtube",
        ru:"ТОМЧИ Youtube",
        en:"TOMCHI Youtube"
      },
    },
    {
      path: "/tomchi/socials",
      title: {
        uz:"TOMCHI ijtimoiy tarmoqlar",
        ru:"ТОМЧИ социальные сети",
        en:"TOMCHI social networks"
      },
    },
    // {
    //   path: "/tomchi/milliy_loyiha",
    //   title: {
    //     uz:"O'zbekiston suv resurslarini boshqarish milliy loyihasi",
    //     ru:"Национальный проект управления водными ресурсами Узбекистана",
    //     en:"National project of water resources management of Uzbekistan"
    //   },
    // },
    // {
    //   path: "/tomchi/benefitsiarlar",
    //   title: {
    //     uz:"Benefitsiarlar ovozi",
    //     ru:"Мнение бенефициара",
    //     en:"Beneficiary opinion",
    //   },
    // },
    // {
    //   path: "/tomchi/loyiha_hikoyasi",
    //   title: {
    //     uz:"Loyiha hikoyasi",
    //     ru:"История проекта",
    //     en:"Project story"
    //   },
    // },
  ]);
  return (
    <>
      <div className="title_main_tomchi">
        <h1>TOMCHI </h1>
      </div>
      <div className="container">
        <div className="tomchi">
          <div className="column_one">
            <ul className="menyu">
              {menu.map((el, index) => (
                <li key={index}>
                  {" "}
                  <NavLink to={el.path}>
                    {lang === "uz"
                        ? el.title.uz
                        : lang === "ru"
                            ? el.title.ru
                            : lang === "en"
                                ? el.title.en
                                : el.title.uz}
                  </NavLink>
                </li>
              ))}
            </ul>
            <img src={QRcode} alt="" />
            <div className="tomchi-links">
              <img src={TomchiLogo} alt="" />
              <h3
                style={{
                  fontSize: "13px",
                }}
                className="tomchi-title"
              >
                {lang === "uz"
                    ? "QR code skanerlang va Tomchi mobil ilovasini yuklab oling."
                    : lang === "ru"
                        ? "Отсканируйте QR-код и загрузите мобильное приложение Томчи."
                        : lang === "en"
                            ? "Scan the QR code and download the Tomchi mobile application."
                            : "QR code skanerlang va Tomchi mobil ilovasini yuklab oling."}


              </h3>
            </div>
          </div>

          <div className="column_two">
            <header>
              <h1 style={{textTransform:"uppercase"}}>{forTitle}</h1>
            </header>
            <div>
              <Route path="/tomchi" exact>
                <MenuComponent lang={lang}/>
              </Route>
              <Route path="/tomchi/about">
                <About lang={lang}/>
              </Route>
              <Route path="/tomchi/savol_javob" >
                <SavolJavob lang={lang} />
              </Route>
              {/*<Route path="/tomchi/milliy_loyiha/:id">*/}
              {/*  <MilLoView lang={lang}/>*/}
              {/*</Route>*/}
              {/*<Route exact path="/tomchi/milliy_loyiha">*/}
              {/*  <MilliyLoyiha lang={lang}/>*/}
              {/*</Route>*/}
              {/*<Route path="/tomchi/benefitsiarlar">*/}
              {/*  <Benefitsiarlar lang={lang}/>*/}
              {/*</Route>*/}
              {/*<Route*/}
              {/*    path="/tomchi/loyiha_hikoyasi/:id">*/}
              {/*  <LoHiView lang={lang}/>*/}
              {/*</Route>*/}
              {/*<Route*/}
              {/*    exact*/}
              {/*  path="/tomchi/loyiha_hikoyasi">*/}
              {/*  <LoyihaHikoyasi lang={lang}/>*/}
              {/*</Route>*/}
              <Route path="/tomchi/youtube">
                <Youtube lang={lang}/>
              </Route>
              <Route path="/tomchi/socials">
                <Socials lang={lang}/>
              </Route>
            </div>
          </div>

          <div className="column_three">
            <div className="block-holder">
              <h2>
                {lang === "uz"
                  ? "So’ngi yangiliklar"
                  : lang === "ru"
                  ? "последние новости"
                  : lang === "en"
                  ? "Last news"
                  : ""}
              </h2>
            </div>

            {news && news.status === 1 ? (
              news.data &&
              news.data.map((el, index) => (
                <News
                  key={index}
                  date={el.created_on}
                  id={el.id}
                  title={el.title}
                />
              ))
            ) : (
              <h1>Loading ...</h1>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
