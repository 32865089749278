import React from 'react';
import {NavLink, useParams} from "react-router-dom";

function Menu(props) {
    const w = window.location
    return (
        <ul className="multimenu">
            <li>
                <NavLink className={w.href.includes("nwrmp")?"first-step active":"first-step"} to="/project/activities/nwrmp/about">NWRMP</NavLink>
                <ul>
                    <li>
                        <NavLink className="second-step" to="/project/activities/nwrmp/about">Loyiha haqida</NavLink>
                    </li>
                    <li>
                        <NavLink className="second-step" to="/project/activities/nwrmp/beneficiaries">Benefitsiarlar ovozi</NavLink>
                    </li>
                    <li>
                        <NavLink className="second-step" to="/project/activities/nwrmp/story">Loyiha hikoyalari</NavLink>
                    </li>
                </ul>
            </li>
        </ul>
    );
}

export default Menu;