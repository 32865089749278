import './footer.scss'
import { Link } from 'react-router-dom'
import SocialOne from '../../assets/icons/face.png'
import SocialTwo from '../../assets/icons/teleg.png'
import SocialThr from '../../assets/icons/ins.png'
import './footerResponse.scss'
import Service from '../../assets/icons/service.png'
import ZipCode from '../../assets/images/footerPost.png'
import Logo from '../../assets/icons/iarc_logo.gif'
import { useSelector } from 'react-redux'
import React from 'react'
import { useState, useEffect } from 'react'
import { request } from '../../api/request'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

import AppStore from '../../assets/icons/app-store.png'
import GooglePlay from '../../assets/icons/google-play.png'
  



const Footer = ()=>{
    const [active, setActive] = useState(false)
    const logo = useSelector(state=>state.data.app)
    const lang = useSelector((state) => state.data.lang)


    const [menuLink, setMenuLink] = useState({
        isFetched: false,
        data: {},
        error:null
    })
    

    const [infoData, setInfoData] = useState({
        name:"",
        email: "",
        message:""
    })
    useEffect(()=>{ 
        request
        .get(`/menu/all?lang=${lang}&sort=sort`,{
            headers:{
                "api-token":"p12378"
            }
        })

        .then(res =>{
            setMenuLink({
                isFetched:true,
                data:res.data.data,
                error:false
            })
        })

        .catch(err =>{
            setMenuLink({
                isFetched:true,
                data:[],
                error:err
            })
        })
    },[lang])

    const axios = require('axios');
    function submit(e){
        e.preventDefault()
        const FormData = require('form-data');
        const data = new FormData();

        data.append('name', infoData.name);
        data.append('email', infoData.email);
        data.append('message', infoData.message);

        const config = {
            method: 'post',
            url: `http://iarc-api.iarc.uz/forms/contact-form?lang=${lang === null ? 'uz':lang}`,
            headers: { 
                'api-token': 'p12378'
            },
            data : data
        };
        axios(config)
        .then(function (response) {
            setInfoData({
                name:"",
                email: "",
                message:""
            })
        })

        .catch(function (error) {
            console.log(error);
        });
    }
    const notify = () => toast("Your request successfully sent !");

    

    return (
        <div className="footer-wrapper">
                <div className="footer-top">
                    <div className="top-inner container">
                    <div className={`footer-block_one`}>
                            {
                                logo && logo.status === 1 ? (
                                    logo.data && logo.data.map((el, index)=>(
                                        el.settings_key === 'site_name' ? (
                                            <h1 key={index} className="block-holder">{el.settings_value}</h1>
                                        ): null
                                    ))
                                ): null
                            }
                            {
                                logo && logo.status === 1 ? (
                                    logo.data && logo.data.map((el, index) =>(
                                        el.settings_key === 'site_info' ? (
                                            <p className="block-text" key={index}>
                                                {el.settings_value}
                                            </p>
                                            ):  null
                                    ))
                                ): null
                            }
                        <ul className="social-ul">
                            {
                                logo && logo.status === 1 ? (
                                    logo.data && logo.data.map((el, index)=>(
                                        el.settings_key === 'social_fb' ? <li key={index}><a href={el.settings_value} target="_blank" rel="noopener noreferrer"><img src={SocialOne} alt="" /></a></li> : null
                                    ))
                                ): null
                            }
                            {
                                logo && logo.status === 1 ? (
                                    logo.data && logo.data.map((el, index)=>(
                                        el.settings_key === 'social_instagram' ? <li key={index}><a href={el.settings_value} target="_blank" rel="noopener noreferrer"><img src={SocialThr} alt="" /></a></li> : null
                                    ))
                                ): null
                            }
                            {
                                logo && logo.status === 1 ? (
                                    logo.data && logo.data.map((el, index)=>(
                                        el.settings_key === 'telegram_number'? <li key={index}><a href={el.settings_value} target="_blank" rel="noopener noreferrer"><img src={SocialTwo} alt="" /></a></li> : null
                                    ))
                                ): null
                            }
                        </ul>
                        <img src={ZipCode} alt="" />
                        <div className="fixed-link">
                            <button 
                             className="sms-btn"
                             onClick={()=>setActive(true)}
                            >
                                <img src={Service} alt="" />
                            </button>
                            <form action="" className={`${active ? 'active' : ''}`} onSubmit={(e) => submit(e)} require="true"> 
                                 <img src={Logo} alt="" />
                                <input onChange={(e)=> setInfoData({...infoData, [e.target.name] : e.target.value})} name="name" value={infoData.name} placeholder="Name" type="text" />
                                <input onChange={(e)=> setInfoData({...infoData, [e.target.name] : e.target.value})} name="email"  value={infoData.email} placeholder="Email" type="mail" />
                                <textarea onChange={(e)=> setInfoData({...infoData, [e.target.name] : e.target.value})}  value={infoData.message} name="message" cols="30" rows="10"></textarea>
                                <div className="btns">
                                    <button onClick={()=> {
                                        notify()
                                    }} type="submit">Send</button>
                                    <button onClick={()=>setActive(false)}>Cancel</button>
                                </div> 
                            </form>
                        </div>
                    </div>
                    <div className="footer-block_one">
                        <ul className="link-ul">
                            {
                                menuLink.isFetched ? (
                                    menuLink.data.map((el, index) =>(
                                        el.group_key === 'footer-menu' ? (
                                            el.child.map((el, index)=>(
                                                <li key={index}><Link to={el.data.link} className="page-link">{el.data.name}</Link></li> 
                                            ))
                                        ): null
                                    ))
                                ):(
                                    <h1>Loading ...</h1>
                                )
                            }
                        </ul>
                    </div>
                    <div className="footer-block_one">
                        <h3 className="adress-name">{lang === 'uz' ? 'Manzil' : lang === 'ru' ? 'Адрес' : lang === 'en' ? 'Address' : ''}</h3>
                        <ul>
                        {
                            logo && logo.status === 1 ? (
                                logo.data && logo.data.map((el, index)=>(
                                    el.settings_key === 'company_address' ? <li key={index}><span className="our-info one">{el.settings_value}</span></li> :
                                    el.settings_key === 'phone_number' ?  <li key={index}><span className="our-info two"><a href="tel:+998712411923">{el.settings_value}</a></span></li> :
                                    // el.settings_key === 'email_address' ? <li key={index}> <span className="our-info thr">{el.settings_value}</span></li> : null
                                    el.settings_key === 'email_address' ? <li key={index}> <span className="our-info thr"><a href={"mailto:"+el.settings_value}>{el.settings_value}</a></span></li> : null
                                ))
                            ):(
                                <></>
                            )
                        }
                        </ul>
                        <div className="platform-apps">
                            {
                                logo && logo.status === 1 ? (
                                    logo.data && logo.data.map((el, index)=>(
                                        el.settings_key === 'social_pinterest' ? <a key={index} href={el.settings_value}><img src={AppStore} alt="" /></a> : 
                                        el.settings_key === 'mobile_app' ? <a key={index} href={el.settings_value}><img src={GooglePlay} alt="" /></a> : null
                                    ))
                                ):null
                            }
                          </div>
                    </div>
                    <div className="footer-block_one">
                        <div className="maps">
                            {
                                logo && logo.status === 1 ? (
                                    logo.data && logo.data.map((el, index)=>(
                                        el.settings_key === 'google_map' ? (
                                            <iframe key={index}  src={el.settings_value} title="location" loading="lazy"></iframe>
                                        ): null
                                    ))
                                ):(
                                    <></>
                                )
                            }
                        </div>
                    </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="inner-bottom container">
                        <p>{lang === 'uz' ?  '© IARC 2021. Barcha huquqlar himoyalangan. Saytdan olingan barcha maʼlumotlar chop etilganda veb-saytga havola qilish majburiy.' : lang === 'ru' ? '© IARC 2021. Все права защищены. Ссылка на сайт обязательна при публикации всей информации с сайта.' : lang === 'en' ? '© IARC 2021. All rights reserved. It is mandatory to link to the website when all information from the site is published.' : ''}</p>
                    </div>
                </div>
                <ToastContainer />
        </div>
    )
}


export default Footer

