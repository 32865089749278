import React, {useEffect, useState} from "react";
import Card2 from "../../components/Card/Card2";
import PhotoMeeting from "../../assets/images/Meeting.JPG";
import {request} from "../../api/request";
import {paginate} from "../../components/paginate/paginate";
import {Pagination} from "antd";

export default function Menu({lang}) {
    const [dclist, setDclist] = useState([]);
    const [page, setPage] = useState(1);
    const paginated = paginate(dclist, page, 10);

    const fetchDCList = async () => {
        request
            .get(`/tomchi/coordination?lang=${lang === null ? 'uz' : lang}`, {
                headers:{
                    "api-token":"p12378"
                }
            })
            .then((res) => {
                if(typeof res.data.data !== 'undefined'){
                    setDclist(res.data.data.filter(e => {
                        return e.sort != 1
                    }).reverse())
                    // setDclist(res.data.data.reverse())
                }
            })
            .catch((err) => console.log(err))
    }
    useEffect(() => {
        fetchDCList()
    },[lang]);
  return (
      <div>
          {paginated.map((e, index) => (
              <Card2
                  key={index}
                  img={e.img}
                  title={e.title}
                  link={`/project/meetings/${e.id}`}
              />
          ))}
          <Pagination
              defaultCurrent={1}
              total={dclist.length}
              onChange={(e) => setPage(e)}
          />
      </div>
  );
}
