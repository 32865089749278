import { Fragment } from "react"
import { useState, useEffect } from "react"
import '../../pages/about/about.scss'
import { useSelector } from "react-redux"
import { request } from "../../api/request"
import React from 'react'
import Card from "../../components/Card/Card"

const Leaders = () => {

    const [leadInfo, setLeadInfo] = useState({
        isFetched: false,
        data: {},
        error: null
    })
    const lang = useSelector(state => state.data.lang)


    useEffect(() => {

        request
            .get(`/leader/all/lead?lang=${lang}`, {
                headers: {
                    "api-token": "p12378"
                }
            })

            .then(res => {
                setLeadInfo({
                    isFetched: true,
                    data: res.data.data,
                    error: false
                })
            })

            .catch(err => {
                setLeadInfo({
                    isFetched: true,
                    data: [],
                    error: err
                })
            })


    }, [lang])




    return (
        <Fragment>
            <div className="reja-maqsad alone" id="rahbariyat">
                <h3 className="reja-title">Rahbariyat</h3>
            </div>
            {
                leadInfo.isFetched ? (
                    leadInfo.data && leadInfo.data.map((el, index) => (
                        <Card el={el} key={index} />
                    ))
                ) : (
                    <h1>Loading ...</h1>
                )
            }
        </Fragment>
    )
}

export default Leaders