import './downloadzip.scss'
import CardImage from '../../assets/images/cardimage.png'
import { Link } from 'react-router-dom'
import React from 'react'
import { useSelector } from 'react-redux'



const DownloadZip = ({image,title,id, link, size})=>{

    const location = window.location.pathname

    const lang = useSelector(state => state.data.lang)
    return (
        <div className="exper-card">
                <Link to={`/seminar/${id}`}>
                <div className="download-text">
                    <img className="card-image" src={image === "" ? CardImage:image} alt="" />
                    <h3 className="card-title">{title}</h3>
                </div>
       </Link>
                {
                    location === '/experience' ? (
                        <></>
                    ):(
                        <div className="files">
                    <span className="file-size">{size || size === "" ? '0mb' : size}</span>
                    <a disabled={true} href={link}  download className={`download-file ${link !== "" ? 'active' : null}`}>{lang === 'uz' ? 'Yuklab oxzxzlish' : lang === 'ru' ? 'Скачать' : lang ==='en' ? 'Download' : ''}</a>
                </div>
                    )
                }
            </div>
    )
}

export default DownloadZip