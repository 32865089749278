import './hero.scss'
import { useState, useEffect } from 'react'
import { request } from '../../api/request'
import { BreadCrumb } from '../../components'
import React from 'react'
import { useSelector } from 'react-redux'


const Hero = ({id,images,titles, nameOne,nameTwo,linkOne,linkTwo})=>{

const lang = useSelector((state) => state.data.lang)
    const [hero,setHero]=useState({
        isFetched:false,
        data:{},
        error:null
    })


    useEffect(()=>{
        request
        .get(`/content/one/page?id=${id}&lang=${lang === null ? 'uz' : lang}`,{headers:{
            "api-token":"p12378"
        },
            params: {
                lang,
            }
        })
        

        .then((res)=>{
            setHero({
                isFetched:true,
                data:res.data.data,
                error:false
            })
        })

        .catch((err)=>{
            setHero({
                isFetched:true,
                data:[],
                error:err
            })
        })
    },[id,lang])
    

    return (
        <div
            className="hero-about"
            style={{ backgroundImage: `url(${hero.data.image? hero.data.image : images})` }}
        >
            <h1 className="hero-title">{hero.data.title? hero.data.title : titles}</h1>
            <div className="bread">
                <BreadCrumb 
                    nameOne={nameOne}
                    nameTwo={nameTwo}
                    linkOne={linkOne}
                    linkTwo={linkTwo}
                />
            </div>
            <div className="overlay"></div>
        </div>
    )
}

export default Hero