import React from 'react';

function Popup({setPopup}) {
    return (
        <div className="social-popup" onClick={()=>setPopup(false)}>
            <div className="body">
                <span className="close-button" onClick={()=>setPopup(false)}>&#x2715;</span>
                <h2>
                    <a href="https://t.me/tomchikanali" target="_blank">
                        <img style={{width:"20px",marginBottom:"5px"}} src="/img/tg_mini.webp" alt=""/>
                        &nbsp;
                        TOMCHI kanali
                    </a>
                </h2>
                <br/>
                <h2>
                    <a href="https://t.me/tomchiguruhi" target="_blank">
                        <img style={{width:"20px",marginBottom:"5px"}} src="/img/tg_mini.webp" alt=""/>
                        &nbsp;
                        TOMCHI mutaxasislari
                    </a>
                </h2>
            </div>
        </div>
    );
}

export default Popup;