import {Pagination} from "antd";
import React, {useEffect, useState} from "react";
import {paginate} from "../../../components/paginate/paginate";
import {request} from "../../../api/request";

const Youtube = ({lang}) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const paginated = paginate(data, page, 5);

    useEffect(()=>{
        const fetchContent = async () => {
            request
                .get(`/tomchi/youtube?lang=${lang === null ? 'uz' : lang}`, {
                    headers:{
                        "api-token":"p12378"
                    }
                })
                .then((res) => {
                    if(typeof res.data.data !== 'undefined'){
                        setData(res.data.data)
                    }
                })
                .catch((err) => console.log(err))
        }
        fetchContent()
    },[lang])
    return (

                data.length > 0 ?<div>
                        {paginated.map((e,index) => {
                            return e.video_link?<div key={index} className="embed-responsive embed-responsive-4by3" style={{marginBottom:"20px"}}>
                                <h2>{e.title}</h2>
                                <iframe
                                    width="100%"
                                    height="377"
                                    src={"https://www.youtube.com/embed/"+getV(e.video_link)}
                                    title={e.title}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen>
                                </iframe>
                                <hr/>
                            </div>:null
                        })}
                    <Pagination
                        defaultCurrent={1}
                        total={data.length}
                        onChange={(e) => setPage(e)}
                    />
                </div>:
                    <h2>Ma'lumot topilmadi</h2>

    );
}
export default Youtube;

const getV = (link) => {
    return link.split("watch?v=")[1]
}
