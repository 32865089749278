import "./project.scss";
import { useState, useEffect } from "react";
import "./projectResponse.scss";
import { request } from "../../api/request";
import React from "react";
import { Pagination } from "antd";
import QRcode from "../../assets/qrCode.png";
import bank from "../../assets/images/bank_1.png";
import adb from "../../assets/images/adb_1.png";
import sds from "../../assets/images/sds_1.png";
import undp from "../../assets/images/undp_1.jpg";

import koica_1 from "../../assets/images/koica_1.png";
import usaid_1 from "../../assets/images/usaid_1.png";
import giz_1 from "../../assets/images/giz_1.png";
import afd_1 from "../../assets/images/afd_1.png";
import jica_1 from "../../assets/images/jica_1.png";
import eib_1 from "../../assets/images/eib_1.png";
import isdb_1 from "../../assets/images/isdb_1.png";
import eunion_1 from "../../assets/images/eunion_1.png";
import fao_1 from "../../assets/images/fao_1.png";
import kfw_1 from "../../assets/images/kfw_1.png";
import unece_1 from "../../assets/images/unece_1.png";
import who_1 from "../../assets/images/who_1.png";
import ActivitiesMenu from './joinActivities/Menu'
import {
  SectionCard,
  News,
  DownloadZipTwo,
  Helm,
} from "../../components/index";
import { Hero } from "../../container/index";
import parser from "html-react-parser";
import { useSelector } from "react-redux";
import {Link, Route, useParams} from "react-router-dom";
import TomchiLogo from "../../assets/icons/TOMCHILOGO.png";
import ConrdinationView from "./ConrdinationView";
import Menu from ".";
import Nwrmp from "./joinActivities/Nwrmp";
import Menu2 from ".";
import GetAll from "./Techcordinations/GetAll";
import GetOne from "./Techcordinations/GetOne";
const menus = {projects:1,matrix:3,partners:0,meetings:2,activities:4,tech_meetings:5}
const Project = () => {
  const lang = useSelector((state) => state.data.lang);
  const news = useSelector((state) => state.data.news);
  const params = useParams();
  const [project, setProject] = useState({
    isFetched: false,
    data: {},
    error: null,
  });
  const [donor, setDonor] = useState({
    isFetched: false,
    data: {},
    error: null,
  });
  const [changeBar, setChangeBar] = useState(menus[params.page]);
  console.log(changeBar)
  const [categoriesOne, setcategoriesOne] = useState({
    isFetched: false,
    data: {},
    error: null,
  });
  useEffect(()=>{
    setChangeBar(menus[params.page])
  },[params.page])
  const [changeApi, setChangeApi] = useState("");
  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState({
    isFetched: false,
    data: {},
    error: null,
  });

  useEffect(() => {
    request
      .get(
        `/content/all/project?lang=${
          lang === null ? "uz" : lang
        }&category=${changeApi}&page=${page}`,
        { headers: { "api-token": "p12378" } }
      )
      .then((res) => {
        setProject({
          isFetched: true,
          data: res.data.data.reverse(),
          error: false,
        });
      })
      .catch((err) => {
        setProject({ isFetched: true, data: [], error: err });
      });

    request
      .get(`/content/all/project?lang=${lang === null ? "uz" : lang}`, {
        headers: { "api-token": "p12378" },
      })
      .then((res) => {
        setItemsCount({ isFetched: true, data: res.data.data, error: false });
      })
      .catch((err) => {
        setItemsCount({ isFetched: true, data: [], error: err });
      });

    request
      .get(
        `/content/all/donor?lang=${
          lang === null ? "uz" : lang
        }&category=${changeApi}`,
        { headers: { "api-token": "p12378" } }
      )
      .then((res) => {
        setDonor({
          isFetched: true,
          data: res.data.data.reverse(),
          error: false,
        });
      })
      .catch((err) => setDonor({ isFetched: true, data: [], error: err }));

    request
      .get(
        `/segment/all/${
          changeBar ? "project_category" : "donor_category"
        }?lang=${lang === null ? "uz" : lang}`,
        { headers: { "api-token": "p12378" }}
      )
      .then((res) =>
        setcategoriesOne({ isFetched: true, data: res.data.data, error: false })
      )
      .catch((err) =>
        setcategoriesOne({ isFetched: true, data: [], error: err })
      );
  }, [changeBar, lang, changeApi, page]);

  const int = itemsCount && itemsCount.data.length;


  const pages = () => {
    if (params.page == 'projects') {
      return (
        <div
          className="project-card"
          id="loyiha"
          style={{ marginBottom: "30px" }}
        >
          <h5 className="section-holder ">
            {lang === "uz"
              ? "Projects"
              : lang === "ru"
              ? "Проекты"
              : lang === "en"
              ? "Projects"
              : ""}
          </h5>
          {project.isFetched ? (
            project.data &&
            project.data.map((el, inx) => (
              <SectionCard
                key={inx}
                id={el.id}
                date={el.created_on}
                title={el.title}
                description={parser(`${el.extra_desc}`)}
                image={el.image}
                type={"project"}
              />
            ))
          ) : (
            <h5>Loading ...</h5>
          )}
          <Pagination
            defaultCurrent={1}
            total={int}
            onChange={(e) => setPage(e)}
          />
        </div>
      );
    // } else if (changeBar === 0) {
    } else if (params.page == 'partners') {
      return (
        <div className="donr-cards" id="donr">
          <h5 className="section-holder ">
            {lang === "uz"
              ? "Development partners"
              : lang === "ru"
              ? "Доноры"
              : lang === "en"
              ? "Donors"
              : ""}
          </h5>
          <h5 style={{marginBottom: "20px", fontSize: 20}}>Click on logos to see partners websites</h5>
          <div className="partners-logo">
            <a href="https://www.adb.org/projects/country/uzbekistan" className="partners-link">
              <div className="partners-logo-inner">
                <img src={adb} alt="404" />
              </div>
            </a>
            <a href="https://www.afd.fr/en/page-region-pays/uzbekistan" className="partners-link">
              <div className="partners-logo-inner">
                <img src={afd_1} alt="404" />
              </div>
            </a>
            <a href="https://www.eib.org/en/index" className="partners-link">
              <div className="partners-logo-inner">
                <img src={eib_1} alt="404" />
              </div>
            </a>
            <a href="https://www.eeas.europa.eu/eeas/projects_en?fulltext=Uzbekistan&start_from=&start_to=" className="partners-link">
              <div className="partners-logo-inner">
                <img src={eunion_1} alt="404" />
              </div>
            </a>
            <a href="https://www.fao.org/countryprofiles/index/en/?iso3=UZB" className="partners-link">
              <div className="partners-logo-inner">
                <img src={fao_1} alt="404" />
              </div>
            </a>
            <a href="https://www.giz.de/en/worldwide/364.html" className="partners-link">
              <div className="partners-logo-inner">
                <img src={giz_1} alt="404" />
              </div>
            </a>
            <a href="https://www.isdb.org/" className="partners-link">
              <div className="partners-logo-inner">
                <img src={isdb_1} alt="404" />
              </div>
            </a>
            <a href="https://www.jica.go.jp/english/index.html" className="partners-link">
              <div className="partners-logo-inner">
                <img src={jica_1} alt="404" />
              </div>
            </a>
            <a href="http://www.koica.go.kr/sites/uzb_en/index.do" className="partners-link">
              <div className="partners-logo-inner">
                <img src={koica_1} alt="404" />
              </div>
            </a>
            <a href="https://www.kfw-entwicklungsbank.de/International-financing/KfW-Development-Bank/Local-presence/Asia/Uzbekistan/" className="partners-link">
              <div className="partners-logo-inner">
                <img src={kfw_1} alt="404" />
              </div>
            </a>
            <a href="https://www.eda.admin.ch/deza/en/home/countries/central-asia.html" className="partners-link">
              <div className="partners-logo-inner">
                <img src={sds} alt="404" />
              </div>
            </a>
            <a href="https://www.eeas.europa.eu/eeas/projects_en?fulltext=Uzbekistan&start_from=&start_to=" className="partners-link">
              <div className="partners-logo-inner">
                <img src={undp} alt="404" />
              </div>
            </a>
            <a href="https://unece.org/technical-cooperation/uzbekistan" className="partners-link">
              <div className="partners-logo-inner">
                <img src={unece_1} alt="404" />
              </div>
            </a>
            <a href="https://www.usaid.gov/" className="partners-link">
              <div className="partners-logo-inner">
                <img src={usaid_1} alt="404" />
              </div>
            </a>
            <a href="https://projects.worldbank.org/en/projects-operations/projects-list?lang=en&countrycode_exact=UZ&os=0" className="partners-link">
              <div className="partners-logo-inner">
                <img src={bank} alt="404" />
              </div>
            </a>
            <a href="https://www.who.int/uzbekistan" className="partners-link">
              <div className="partners-logo-inner">
                <img src={who_1} alt="404" />
              </div>
            </a>
          </div>

        </div>
      );
    // } else if (changeBar === 3) {
    } else if (params.page == 'matrix') {
      return (
          <div className="donr-cards" id="donr">
            <h5 className="section-holder ">
              {lang === "uz"
                  ? "Project matrix"
                  : lang === "ru"
                      ? "Доноры"
                      : lang === "en"
                          ? "Donors"
                          : ""}
            </h5>

            <div>
              <a className="section-card" style={{height: "60px"}} target="_blank" href="https://docs.google.com/spreadsheets/d/1YAYr25Ctn7TSkQaX6CVhjErvSotqBeqsGNRa26AU91M/edit#gid=1971399641">
                <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                  <span>Donors matrix</span>
                  <span>...</span>
                </div>
              </a>
              <a
                  target="_blank"
                  style={{
                    color:"rgb(57, 135, 243)",
                    border:"none",
                    padding:"5px 30px 5px 22px",
                    fontFamily:"Poppins","fontWeight":"600",
                    background:"rgba(57, 135, 243, 0.2)",
                    borderRadius:"10px","cursor":"pointer",
                    display:"inline-block",
                    margin:"0px 5px 5px 0px"}}
                  href="https://drive.google.com/file/d/1FkdttbvgK10eFyoTz5RqRNm04FH4Dvi_/view">Instructions: How to use donor matrix</a>
            </div>
          </div>
      );
    } else  if(params.page == 'meetings'){
      return (
        <div className="donr-success">
          <h5 className="section-holder ">
            {lang === "uz"
              ? "Coordination meetings"
              : lang === "ru"
              ? "Координация доноров"
              : lang === "en"
              ? "Donor coordination"
              : ""}
          </h5>
          <Route exact path="/project/meetings">
            <Menu lang={lang} />
          </Route>
          <Route path="/project/meetings/:id">
            <ConrdinationView lang={lang}/>
          </Route>
          <Route exact path="/project" >
            <Menu lang={lang} />
          </Route>
        </div>
      );
    }else  if(params.page == 'tech_meetings'){
      return (
          <div className="donr-success">
            <h5 className="section-holder ">
              {lang === "uz"
                  ? "Technical meetings"
                  : lang === "ru"
                      ? "Координация доноров"
                      : lang === "en"
                          ? "Donor coordination"
                          : ""}
            </h5>
            <Route exact path="/project/tech_meetings">
              <GetAll lang={lang} />
            </Route>
            <Route path="/project/tech_meetings/:id">
              <GetOne lang={lang}/>
            </Route>
            <Route exact path="/project" >
              <GetAll lang={lang} />
            </Route>
          </div>
      );
    }else{
      return (
          <div className="donr-success" style={{paddingBottom:'40px'}}>
            <h5 className="section-holder ">
              {lang === "uz"
                  ? "Joint Activities"
                  : lang === "ru"
                      ? "Координация доноров"
                      : lang === "en"
                          ? "Donor coordination"
                          : ""}
            </h5>
            <Route path="/project/activities/nwrmp/*">
              <Nwrmp lang={lang} />
            </Route>
          </div>
      );
    }
  };

  return (
    <div className="project-page">
      <Helm id={"36"} />
      <div className="hero-page">
        <Hero
          id={"36"}
          nameOne={
            lang === "uz"
              ? "Bosh sahifa"
              : lang === "ru"
              ? "Домой"
              : lang === "en"
              ? "Home page"
              : ""
          }
          nameTwo={
            lang === "uz"
              ? "Loyihalar va donorlar"
              : lang === "ru"
              ? "Проекты и мудрые"
              : lang === "en"
              ? "Projects and wise"
              : ""
          }
          linkOne={"/"}
          linkTwo={"/project"}
        />
      </div>

      <div className="inner-project container">
        <div className="column-one">
          <div className="open-blocks">

            {/*#######################Interactive map#######################*/}
            <div className="open-block">
              <div className="opener">
                <Link
                  to="/map"
                  onClick={() => {
                    setChangeBar(true);
                    setChangeApi("");
                  }}
                  className={`button`}
                >
                  {lang === "uz"
                    ? "Interactive map"
                    : lang === "ru"
                    ? "Интерактивная карта"
                    : lang === "en"
                    ? "Interactive map"
                    : ""}
                </Link>
              </div>
            </div>

            {/*#######################Projects#######################*/}
            <div className="open-block">
              <div className="opener">
                <Link to="/project/projects">
                  <button
                    onClick={() => {
                      setChangeBar(1);
                      setChangeApi("");
                    }}
                    className={`button ${changeBar === 1 ? "active" : ""}`}
                  >
                    {lang === "uz"
                      ? "Projects"
                      : lang === "ru"
                      ? "Проекты"
                      : lang === "en"
                      ? "Projects"
                      : ""}
                  </button>
                </Link>
              </div>
            </div>

            {/*#######################Project matrix#######################*/}
            <div className="open-block">
              <div className="opener">
                <Link to="/project/matrix">
                  <button
                    onClick={() => {
                      setChangeBar(3);
                      setChangeApi("");
                    }}
                    className={`button ${changeBar === 3 ? "active" : ""}`}
                  >
                    {lang === "uz"
                      ? "Project matrix"
                      : lang === "ru"
                      ? "Доноры"
                      : lang === "en"
                      ? "Donors"
                      : ""}
                  </button>
                </Link>
              </div>
            </div>

            {/*#######################Development partners#######################*/}
            <div className="open-block">
              <div className="opener">
                <Link to="/project/partners">
                  <button
                      onClick={() => {
                        setChangeBar(0);
                        setChangeApi("");
                      }}
                      className={`button ${changeBar === 0 ? "active" : ""}`}
                  >
                    {lang === "uz"
                        ? "Development partners"
                        : lang === "ru"
                            ? "Доноры"
                            : lang === "en"
                                ? "Donors"
                                : ""}
                  </button>
                </Link>
              </div>
            </div>

          {/*#######################Coordination meetings#######################*/}
          <div className="open-block">
            <div className="opener">
              <Link to="/project/meetings">
                <button
                  onClick={() => {
                    setChangeBar(2);
                  }}
                  className={`button ${changeBar === 2 ? "active" : ""}`}
                >
                  {lang === "uz"
                    ? "Coordination meetings"
                    : lang === "ru"
                    ? "Координация доноров"
                    : lang === "en"
                    ? "Donor coordination"
                    : ""}
                </button>
              </Link>
            </div>
          </div>

            {/*#######################Technical meetings#######################*/}
            <div className="open-block">
              <div className="opener">
                <Link to="/project/tech_meetings">
                  <button
                      onClick={() => {
                        setChangeBar(5);
                      }}
                      className={`button ${changeBar === 5 ? "active" : ""}`}
                  >
                    {lang === "uz"
                        ? "Technical meetings"
                        : lang === "ru"
                            ? "Координация доноров"
                            : lang === "en"
                                ? "Donor coordination"
                                : ""}
                  </button>
                </Link>
              </div>
            </div>

          {/*#######################Join activities#######################*/}
          <div className="open-block">
            <div className={changeBar === 4?"opener active":"opener"}>
              <Link to="/project/activities/nwrmp/about">
                <button
                  onClick={() => {
                    setChangeBar(4);
                  }}
                  className={`button ${changeBar === 4 ? "active" : ""}`}
                  style={{borderBottomLeftRadius:0,borderBottomRightRadius:0}}
                >
                  {lang === "uz"
                    ? "Joint activities"
                    : lang === "ru"
                    ? "Присоединяйтесь к мероприятиям"
                    : lang === "en"
                    ? "Joint activities"
                    : ""}
                </button>
              </Link>
              {
                changeBar === 4 ? <ActivitiesMenu />:null
              }
              </div>
            </div>
          </div>

          <h5 id="cate-name" style={{display: 'none'}}>Categories</h5>
          <div className="categories" style={{display: 'none'}}>
            <ul className="cate-ul">
              <button
                onClick={() => setChangeApi("")}
                className={`cate-btn ${changeApi === "" ? "active" : ""}`}
              >
                {lang === "uz"
                  ? "Barchasi"
                  : lang === "ru"
                  ? "Все"
                  : lang === "en"
                  ? "All contents"
                  : ""}
              </button>
              {categoriesOne.isFetched ? (
                categoriesOne.data &&
                categoriesOne.data.map((el, inx) => (
                  <button
                    onClick={() =>
                      el.id !== changeApi
                        ? setChangeApi(el.id)
                        : setChangeApi("fak")
                    }
                    key={inx}
                    className={`cate-btn ${
                      changeApi === el.id ? "active" : ""
                    }`}
                  >
                    {el.title}
                  </button>
                ))
              ) : (
                <h5>Loading ...</h5>
              )}
            </ul>
          </div>
          <div className="tomchi" style={{ marginTop: "20px" }}>
            <img src={QRcode} alt="" />
          </div>
          <div className="tomchi-links">
            <img src={TomchiLogo} alt="" />
            <h3
              style={{
                fontSize: "13px",
              }}
              className="tomchi-title"
            >
              {lang === "uz"
                  ? "QR code skanerlang va Tomchi mobil ilovasini yuklab oling."
                  : lang === "ru"
                      ? "Отсканируйте QR-код и загрузите мобильное приложение Tomchi."
                      : lang === "en"
                          ? "Scan the QR code and download the Tomchi mobile application."
                          : "QR code skanerlang va Tomchi mobil ilovasini yuklab oling."}
            </h3>
          </div>
        </div>

        <div className="column-two">
          <div className="projects">{pages()}</div>
        </div>

        <div className="column-thr">
          <h5 className="news-holder">
            {lang === "uz"
              ? "So’ngi yangiliklar"
              : lang === "ru"
              ? "Последние новости"
              : lang === "en"
              ? "Last news"
              : ""}
          </h5>
          {news && news.status === 1 ? (
            news.data &&
            news.data.map((el, inx) => (
              <News
                key={inx}
                date={el.created_on}
                title={el.title}
                id={el.id}
              />
            ))
          ) : (
            <h5>Loading ...</h5>
          )}
        </div>
      </div>
    </div>
  );
};

export default Project;
