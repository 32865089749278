import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  a {
    height: 160px;
    margin: 10px auto;
    border: 2px solid rgb(233, 228, 228);
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #f6f6f7;
    padding: 10px;
    .left {
      box-sizing: border-box;
      width: 30%;
      height: 100%;
      padding: 10px 0;
      border-radius: 10px;
      background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8));
    }
    .right {
      width: 70%;
      padding-left: 10px;
      h3 {
        font-size: 18px;
      }
    }
  }
  &:hover h3 {
    color: rgb(82, 169, 175);
  }
`;

const Card2 = (props) => {
  return (
    <Wrapper>
      <a href={props.link} target={props.target ? "_blank" : null}>
        <div
          className="left"
          style={{
            backgroundImage:
              "linear-gradient(360deg, #2A2A2A 0%, rgba(97, 97, 97, 0) 54.17%, rgba(255, 255, 255, 0) 100%), url(" +
              props.img +
              ")",
            backgroundSize: "cover",
            backgroundPositiond: "center",
          }}
        ></div>
        <div className="right">
          <h3>{props.title}</h3>
        </div>
      </a>
    </Wrapper>
  );
};

export default Card2;
