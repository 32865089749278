import React from "react";
import "./singleTwo.scss";
import "./singleTwoRes.scss";
import calendar from "../../assets/icons/date.png";
import eye from "../../assets/icons/Vector.png";
import NoImage from "../../assets/images/download.png";
import Telegram from '../../assets/icons/telegram (1).png'
import QRcode from '../../assets/qrCode.png'

import { News } from "../../components/index";

import { HeroTwo } from "../../container/index";
import Helmet from "react-helmet";
import parser from "html-react-parser";
import TomchiLogo from '../../assets/icons/TOMCHILOGO.png'
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";


import {
  FacebookShareButton, InstapaperShareButton,
  TelegramShareButton
} from 'react-share'
import TelegramTwo from "../../assets/icons/telegram.png";
import Facebook from "../../assets/icons/facebook-app-symbol.png";
import Instagram from "../../assets/icons/instagram.png";
import Close from "../../assets/images/close.png";
import Share from "../../assets/icons/share.png";

const SingleTwo = ({ match }) => {
  const water = useSelector(state => state.data.water)
  const lang = useSelector(state => state.data.lang)
  const news = useSelector(state => state.data.news)
  const [active, setActive] = useState(false);



  const [object, setObject] = useState([])

  useEffect(() => {
    if (water) {
      if (water.status === 1) {
        console.log(water.data)
        setObject(water.data.filter(item => item.id === match.params.id))
      }
    }
  }, [water, match.params.id])



  return (
    <div className="page-wrapper">
      
      <HeroTwo
        title='Suv tejash texnologiyalari'
        linkOne={'/'}
        nameOne={'Bosh sahifa'}
        nameTwo={'Suv tejash texnologiyalari'}
      />
      <div className="container">
        <div className="inner">
          <div className="col-one">
            <div className="link-tomchi" >
              <img src={QRcode} alt="" />
            </div>
            <div className="tomchi-links">
              <img src={TomchiLogo} alt="" />
              <h3 style={{
                fontSize: '13px'
              }} className='tomchi-title'>QR code skanerlang va Tomchi mobil ilovasini yuklab oling.</h3>
            </div>
          </div>


          {/* main info */}

          {
            object.length !== 0 ? (
              object.map((el, index) => (
                <div className="col-two" key={index}>
                  <h1 className="page-title">{el.title ? el.title : ""}</h1>

                  <div className="below-icons">
                    <div className="inner-div">
                      <img src={calendar} alt="nophoto" />
                      <span>{el.created_on}</span>
                    </div>

                    <div className="inner-div">
                      <img src={eye} alt="" />
                      <span>{el.view_count}</span>
                    </div>
                    <div className="sheyr-btns">
                      <div className="share-btn">
                        <a href={el.tg_link}><img src={Telegram} alt="" /></a>
                      </div>
                      <div className="share-icons-two">
                        <button
                          className="share-btn-two"
                          onClick={() => setActive(!active)}
                        >
                          <img src={active ? Close : Share} alt="" />
                        </button>

                        <div className={`social-icons ${active ? "active" : ""}`}>
                          <ul>
                            <li>
                              <FacebookShareButton
                                quote="share-to-facebook"
                                className={`${active ? "active" : ""}`}
                                url={window.location.href}
                              >
                                <img src={Facebook} alt="" />
                              </FacebookShareButton>
                            </li>
                            <li>
                              <InstapaperShareButton
                                quote="share-to-instagram"
                                className={`${active ? "active" : ""}`}
                                url={String(window.location)}
                              >
                                <img src={Instagram} alt="" />
                              </InstapaperShareButton>
                            </li>
                            <li>
                              <TelegramShareButton
                                quote="share-to-telegram"
                                className={`${active ? "active" : ""}`}
                                url={String(window.location)}
                              >
                                <img src={TelegramTwo} alt="" />
                              </TelegramShareButton>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="central-img">
                    <img
                      className="main-image"
                      src={el.image === "" ? NoImage : el.image}
                      alt=""
                    />
                  </div>
                  <div className="second-para">{parser(`${el.description}`)}</div>
                  <Helmet>
                    <title>IARC - Ichki sahifa</title>
                    <meta property="og:title" content={el.title}/>
                    <meta property="og:description" content={el.extra_desc}/>
                    <meta property="og:image" content={el.image}/>
                    <meta property="og:url" content={window.location.pathname}/>
                    <meta name="twitter:card" content={el.image}/>
        
                  </Helmet>
                </div>
              ))
            ) : null
          }


          {/* main info */}

          <div className="col-thr">
            <div className="block-one">
              <h1>
                {lang === "uz"
                  ? "So’ngi yangiliklar"
                  : lang === "ru"
                    ? "последние новости"
                    : lang === "en"
                      ? "Last news"
                      : ""}
              </h1>
              {news && news.status === 1 ? (
                news.data && news.data.map((el, inx) => (
                  <News
                    key={inx}
                    date={el.created_on}
                    id={el.id}
                    title={el.title}
                  />
                ))
              ) : (
                <h1>Loading ...</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleTwo;
