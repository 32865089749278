import React from "react";
import "./Single.scss";
import calendar from "../../assets/icons/date.png";
import eye from "../../assets/icons/Vector.png";
import { request } from "../../api/request";
import NoImage from "../../assets/images/download.png";
import QRcode from '../../assets/qrCode.png'

import { News } from "../../components/index";

import { HeroTwo } from "../../container/index";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Helmet from "react-helmet";
import parser from "html-react-parser";
import "./singleResponse.scss";
import TomchiLogo from '../../assets/icons/TOMCHILOGO.png'
import {
  FacebookShareButton,InstapaperShareButton,
  TelegramShareButton
}from 'react-share'
import Telegram from "../../assets/icons/telegram.png";
import Facebook from "../../assets/icons/facebook-app-symbol.png";
import Instagram from "../../assets/icons/instagram.png";
import Close from "../../assets/images/close.png";
import Share from "../../assets/icons/share.png";



function Page({ match }) {  
  const [active, setActive] = useState(false);
  const [single, setSingle] = useState({
    isFetched: false,
    data: {},
    error: null,
  })
  const lang = useSelector((state) => state.data.lang);
  const news = useSelector(state => state.data.news)

  const hasToken = JSON.parse(window.localStorage.getItem("USER_CREDENTIALS"))
  

  const options = hasToken
    ? {
        "api-token": "p12378",
        "X-Auth-Token": hasToken.token,
        "X-Auth-Signature": hasToken.signature,
      }
    : {
        "api-token": "p12378",
      };

  useEffect(() => {
    request
      .get(
        `/content/one/${match.params.type}?id=${match.params.id}&lang=${lang}`,
        {
          headers: options,
        }
      )

      .then((res) => {
        setSingle({
          isFetched: true,
          data: res.data.data,
          error: false,
        });
      })

      .catch((err) => {
        setSingle({
          isFetched: true,
          data: [],
          error: err,
        });
      });
  }, [lang, match.params.id, match.params.type]);
  const info = single.data && single.data;
  const locaName = window.location.pathname;


  const localName = locaName.includes("post")
    ? "Yangiliklar va e'lonlar"
    : locaName.includes("knowledge")
    ? "Bilimlar to'plami"
    : locaName.includes("program")
    ? "Malaka oshirish"
    : locaName.includes("material")
    ? "Malaka oshirish"
    : locaName.includes("schedule")
    ? "Malaka oshirish"
    : locaName.includes("seminar")
    ? "Malaka oshirish"
    : locaName.includes("project")
    ? "Loyihalar va donrlar"
    : locaName.includes("donor")
    ? "Loyihalar va donrlar"
    : locaName.includes("event")
    ? "Tadbirlar"
    : "";

  const linkName = locaName.includes("post")
    ? "/news"
    : locaName.includes("knowledge")
    ? "/knowpack"
    : locaName.includes("program")
    ? `/experience`
    : locaName.includes("material")
    ? "/experience"
    : locaName.includes("schedule")
    ? "/experience"
    : locaName.includes("experience")
    ? "/Malaka oshirish"
    : locaName.includes("project")
    ? "/project"
    : locaName.includes("donor")
    ? "/project"
    : locaName.includes("event")
    ? "/events"
    : "";

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>IARC - Ichki sahifa</title>
        <meta property="og:title" content={info.title}/>
        <meta property="og:description" content={info.extra_desc}/>
        <meta property="og:image" content={info.image}/>
        <meta property="og:url" content={window.location.pathname}/>
      </Helmet>
      <HeroTwo
        title={localName}
        linkOne={'/'}
        nameOne={'Bosh sahifa'}
        nameTwo={localName}
        linkTwo={linkName}
      />
      <div className="container">
        <div className="inner">
          <div className="col-one">
            <div className="link-tomchi" >
              <img src={QRcode} alt="" />
            </div>
            <div className="tomchi-links">
                <img src={TomchiLogo} alt="" />
                <h3 style={{
                  fontSize: '13px'
                }} className='tomchi-title'>QR code skanerlang va Tomchi mobil ilovasini yuklab oling.</h3>
              </div>
          </div>
          <div className="col-two">
            <h1 className="page-title">{info.title ? info.title : ""}</h1>

            <div className="below-icons">
              <div className="inner-div">
                <img src={calendar} alt="nophoto" />
                <span>{info.created_on}</span>
              </div>

              <div className="inner-div">
                <img src={eye} alt="" />
                <span>{info.view_count}</span>
              </div>

              <div className="inner-div">
              <div className="share-icons-two">
                <button
                  className="share-btn-two"
                  onClick={() => setActive(!active)}
                >
                  <img src={active ? Close : Share} alt="" />
                </button>

                <div className={`social-icons ${active ? "active" : ""}`}>
                  <ul>
                    <li>
                      <FacebookShareButton
                        quote="share-to-facebook"
                        className={`${active ? "active" : ""}`}
                        url={window.location.href}
                      >
                        <img src={Facebook} alt="" />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <InstapaperShareButton
                        quote="share-to-instagram"
                        className={`${active ? "active" : ""}`}
                        url={String(window.location)}
                      >
                        <img src={Instagram} alt="" />
                      </InstapaperShareButton>
                    </li>
                    <li>
                      <TelegramShareButton
                        quote="share-to-telegram"
                        className={`${active ? "active" : ""}`}
                        url={String(window.location)}
                      >
                        <img src={Telegram} alt="" />
                      </TelegramShareButton>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
            </div>
            <div className="central-img">
              <img
                className="main-image"
                style={{height: "unset"}}
                src={info.image === "" ? NoImage : info.image}
                alt=""
              />
              {
                window.location.pathname.includes('/single/event') ? (
                  <div className="events-date">
                  <div className="dates">
                    <h4>Бошланиш вақти: </h4>
                    <span>{info.begin_date_time}</span>
                  </div>
                  <div className="dates">
                    <h4>Тугаш вақти: </h4>
                    <span>{info.end_date_time}</span>
                  </div>
                </div>
                ):(
                  null
                )
              }
            </div>
            <div className="second-para">{parser(`${info.description}`)}</div>
          </div>

          <div className="col-thr">
            <div className="block-one">
              <h1>
                {lang === "uz"
                  ? "So’ngi yangiliklar"
                  : lang === "ru"
                  ? "последние новости"
                  : lang === "en"
                  ? "Last news"
                  : ""}
              </h1>
              {news && news.status === 1 ? (
                news.data && news.data.map((el, inx) => (
                  <News
                    key={inx}
                    date={el.created_on}
                    id={el.id}
                    title={el.title}
                  />
                ))
              ) : (
                <h1>Loading ...</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
