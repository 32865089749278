import "./sectioncard.scss";
import { Link } from "react-router-dom";

import Date from "../../assets/icons/Group (3).png";
import React from "react";

const SectionCard = ({ id, date, title, description, image, type, region }) => {
  return (
    <Link to={`/single/${type}/${id}`} className="section-card">
      <div className="left-side">
        <div className="date">
          <img src={Date} alt="" />
          <span>{date}</span>
        </div>
        <img className="bg-image" src={image} alt="NoPhoto" />
      </div>
      <div className="right-side">
        <h2 className={region ? "region-title" : null}>
          {title}
          {region ? <p>{region}</p> : null}
        </h2>
        <div className="desc">{description}</div>
      </div>
    </Link>
  );
};

export default SectionCard;
