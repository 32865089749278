import React from 'react';
import {Route} from "react-router-dom";
import About from "./nwrmp/About";
import Hikoya from "./nwrmp/Hikoya";
import Benefitsarlar from "./nwrmp/Benefitsarlar";
import LoHiView from "./nwrmp/LoHiView";

function Nwrmp({lang}) {
    return (
        <>
            <Route path="/project/activities/nwrmp/about">
                <About lang={lang} />
            </Route>
            <Route path="/project/activities/nwrmp/beneficiaries">
                <Benefitsarlar lang={lang} />
            </Route>
            <Route exact path="/project/activities/nwrmp/story">
                <Hikoya lang={lang} />
            </Route>
            <Route path="/project/activities/nwrmp/story/:id">
                <LoHiView lang={lang} />
            </Route>
        </>
    );
}

export default Nwrmp;