import React, {useEffect, useState} from 'react';
import MapContent from "./MapContent/MapContent";
import classes from "../Map/Map.module.scss";
import SectionCard from "../../components/sectionCard/SectionCard";
import {useSelector} from "react-redux";
import {request} from "../../api/request";
function GMap(props) {
    const [datas, setDatas] = useState([]);
    const lang = useSelector((state) => state.data.lang);
    const [filterReg, setFilterReg] = useState(0)
    const [filterDis, setFilterDis] = useState(0)

    useEffect(() => {
        get_projects();
    },[]);
    const get_projects = (region_id) => {
        request
            .get("/content/all/project?lang=uz", {
                headers: {
                    "api-token": "p12378",
                },
                params: {
                    lang,
                    region_id,
                },
            })
            .then((res) => setDatas(res.data.data))
            .catch((err) => console.log(err));
    };
    const fetchCard = (card) =>{
        if(card && typeof(card.meta)!== "undefined"){
            if(typeof(card.meta.focus_keywords) !== "undefined"){
                if(card.meta.focus_keywords.split(",").length){
                    if(filterReg === 0 || card.meta.focus_keywords.split("~")[0].split(",").includes(filterReg.toString())){
                        if(typeof(card.meta.focus_keywords.split("~")[1]) !== 'undefined'){
                            if(card.meta.focus_keywords.split("~")[1].split(",").includes(filterDis.toString()) || filterDis === 0){
                                return <SectionCard
                                    title={card.title}
                                    image={`${
                                        card.image ||
                                        "https://assets.yoshlarforumi.uz/images/default-image.png"
                                    }`}
                                    description={card.extra_desc}
                                    date={card.created_on}
                                    id={card.id}
                                    type={"project"}
                                    key={card.id}
                                    region={card.region_name}
                                />
                            }
                        }
                    }
                }
            }
        }
        // setNotfound(0)
        return null;
    }
    return (
        <div>
            <div className="container">
                <MapContent
                    regions={
                        datas && datas.length &&
                        datas.map(d=>d.meta.focus_keywords.split("~")[0])
                    }
                    districts={
                        datas && datas.length &&
                        datas.map(d=>d.meta.focus_keywords.split("~")[1])
                    }
                    onFilterByRegion={setFilterReg}
                    onFilterByDistrict={setFilterDis}
                />
                <div className={classes.projectWrapper}>
                    <h2 className={classes.title}>Loyihalar</h2>
                    <div className={classes.cardWrapper}>
                        {datas && datas.length > 0 &&
                            datas.map((card) => {
                                return fetchCard(card)
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GMap;