import { Pagination } from "antd";
import React, {useEffect, useState} from "react";
import Card2 from "../../../../components/Card/Card2";
import {request} from "../../../../api/request";
import { paginate } from "../../../../components/paginate/paginate";

export default function Benefitsarlar({lang}) {
    const [menu, setMenu] = useState([
        {
            video_link: "https://www.youtube.com/watch?v=RDNipyAU2PA",
            title:
                "Farg‘ona tumanidagi “Ulug‘-Umid” fermer xo‘jaligi rahbari Muhammadjuma Sulaymonov",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/Umid%20ulug'_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=6TukGYRPZQo",
            title:
                "Tojiboev Baxodir Shavkatovich, Namangan viloyati, Toʼraqoʼrgʼon tumani",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/namangan_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=SfxoIoGDb10&feature=youtu.be",
            title: `Xorazm viloyati Yangiariq tumanidagi "Guljahon-Kamola" fermer xoʼjaligining raxbari Jahongir Аlimov`,
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/Xorazm_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=50kV0lLWxEc&feature=youtu.be",
            title:
                "Samarqand tumani Аʼzam Аzamat agrofermer xoʼjaligi raxbari SULTONOV АXMАDJON",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/%D1%84%D0%BB%D1%84_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=v0OE2rB5cDQ",
            title: "Xolmirzaeva Nasiba Turakulovna, Navoi viloyati",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/nasiba%20opa%20rasm_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=YpaTp3eQpVk",
            title: `Qashqadaryo viloyati Nishon tumanidagi "Imkon" fermer xoʼjaligini`,
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/Screenshot_2_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=Na92J0cy1zI",
            title: `Fargʼona viloyati Toshloq tumanidagi "Аminjon bobo" fermer xoʼjaligi rahbari Jumaboy Аbdusalomov`,
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/aminbobo_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=_OGsvl8VfcU&list=PLyYaCIbku9lFZmv_63xG00gbJPQHvLjPY&index=15",
            title: "Benefitsiarlar Ovozi | Baxtiyorjon To'lxiyev",
            img: "",
        },
        {
            video_link: "https://www.youtube.com/watch?v=4LGeOrXR_0o&list=PLyYaCIbku9lFZmv_63xG00gbJPQHvLjPY&index=13",
            title: "Benefitsiarlar Ovozi | Shaxnoza Hamroyeva",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/shaxnoza_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=XoABQvDGT48&list=PLyYaCIbku9lFZmv_63xG00gbJPQHvLjPY&index=12",
            title: "Benefitsiarlar Ovozi | Behzod Komiljonov",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/behzod_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=Pw5wWw1GVlY&list=PLyYaCIbku9lFZmv_63xG00gbJPQHvLjPY&index=10",
            title: "Benefitsiarlar Ovozi | Shavkat Hamroyev",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/shavkat_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=rfmebxN738E&list=PLyYaCIbku9lFZmv_63xG00gbJPQHvLjPY&index=9",
            title: "Benefitsiarlar Ovozi | Tohir Doliyev",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/tohir%20doliyev_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=oJic85bvwd8&list=PLyYaCIbku9lFZmv_63xG00gbJPQHvLjPY&index=6",
            title: "Benefitsiarlar Ovozi | Ilhom Ramatov",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/benefitsiar_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=TJg1sClXQuA&list=PLyYaCIbku9lFZmv_63xG00gbJPQHvLjPY&index=6",
            title: "Benefitsiarlar Ovozi | Anna Gritsenko",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/benefist_270x0_b7d.jpg",
        },
        {
            video_link: "https://www.youtube.com/watch?v=5kX7BIUVvzw",
            title: "Vohidjon Ahmadjonov",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/tomchi/Screenshot_10_270x0_d6d.png",
        },
        {
            video_link: "https://www.youtube.com/watch?v=J7FN2ypF-6M",
            title: "Rustam Qarshiyev - Plakatlar haqida",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/tomchi/Screenshot_4_270x0_d6d.png",
        },
        {
            video_link: "https://www.youtube.com/watch?v=RjbY4UaRobM",
            title: "Rustam Qarshiyev ",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/tomchi/Screenshot_5_270x0_d6d.png",
        },
        {
            video_link: "https://www.youtube.com/watch?v=NOB22zqy7gE",
            title: "Baxtiyor Shokirov",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/tomchi/Screenshot_6_270x0_d6d.png",
        },
        {
            video_link: "https://www.youtube.com/watch?v=cZLI7Izou9M",
            title: "Farxod Iskandarov",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/tomchi/Screenshot_7_270x0_d6d.png",
        },
        {
            video_link: "https://www.youtube.com/watch?v=oJic85bvwd8",
            title: "Ilhom Ramatov",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/tomchi/Screenshot_8_270x0_d6d.png",
        },
        {
            video_link: "https://www.youtube.com/watch?v=hFS5E3nM1mA",
            title: "Hamidjon Burxonov",
            img: "https://old.iarc.uz/assets/cache/images/uploads/images/tomchi/Screenshot_9_270x0_d6d.png",
        },
    ]);

    // const [menu2, setMenu2] = useState([]);
    const [page, setPage] = useState(1);
    const paginated = paginate(menu, page, 10);
    const fetchBenefitsiars = async () => {
        request
            .get(`/tomchi/beneficiary?lang=${lang === null ? 'uz' : lang}`, {
                headers:{
                    "api-token":"p12378"
                }
            })
            .then((res) => {
                if(typeof res.data.data !== 'undefined'){
                    setMenu(res.data.data)
                }
            })
            .catch((err) => console.log(err))
    }
    useEffect(()=>{
        fetchBenefitsiars()
    },[lang])

    return (
        <div>
            {paginated.map((e,index) => (
                <Card2 key={index} img={e.img} target={1} title={e.title} link={e.video_link}></Card2>
            ))}
            <Pagination
                defaultCurrent={1}
                total={menu.length}
                onChange={(e) => setPage(e)}
            />
        </div>
    );
}
