import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {request} from "../../../api/request";
import styled from "styled-components";
const Wrapper = styled.div`
  .top-img {
    display: flex;
    justify-content: space-between;
  }

  img {
    width: 100%;
  }

  .one {
    width: 20%;

    h1 {
      font-size: 12px;
    }
  }

  .two {
    width: 30%;

    h1 {
      font-size: 20px;
      text-align: center;
    }
  }

  .three {
    width: 20%;

    h1 {
      font-size: 12px;
    }
  }
`
const GetOne = ({lang}) => {
    const { id } = useParams();
    const fetchStory = async () =>{
        request
            .get(`/tomchi/coordination_view/${id}?lang=${lang === null ? 'uz' : lang}`, {
                headers:{
                    "api-token":"p12378"
                }
            })
            .then((res) => {
                if(typeof res.data.data !== 'undefined'){
                    document.getElementById("content").innerHTML = res.data.data.content
                }
            })
            .catch((err) => console.log(err))
    }
    useEffect(()=>{
        fetchStory()
    },[lang,id])
    return (
        <Wrapper id="content"></Wrapper>
    );
}

export default GetOne;