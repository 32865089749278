const initialState = {
    lang:
        window.localStorage.getItem("lang") !== null
            ? window.localStorage.getItem("lang")
            : "uz",
    notification: {
        message: "",
        show: false,
        error: true,
    },
    HeaderState: "",
    chart: [],
    loyihaHikoyasi: [
        {
            title:
                "Xamma qiyinchiliklarni yengib o‘tamiz (Boboqulova Yulduzxon xiqoyasi).",
            text: `Boboqulova Yulduzxon 2015 yildan beri Guliston tumani Uch Kaxromon SIU Nurobod «Xaydarobod fayzi» fermer xoʼjaligida faoliyat yuritib kelyapti. Аvval xisobchi keyin esa xoʼjalik raxbari sifatida ishlab kelgan.

    Fermer xoʼjaligining umumiy yer maydoni 87 gektar, shundan 20 gektarida sabzavot va poliz ekinlarini yetishtirib, qolgan maydonlarda paxta va gʼalla yetishtirib kelmoqda. 

    “Fermerlik faoliyati bu mashaqqatli kasb. Xamma narsani oʼziga yarasha qiyinchiligi bor. Biz xam qiynalamiz vaqtida. Lekin xammasini yengib oʼtishga xarakat qilamiz”, - dedi Yulduzxon.

    “Baʼzida suv boʼlmas edi. 12 gektar yerga nasos orqali suv chiqarar edik”, - deb davom etdi.

    Suv muammolariga duch kelgan fermer ayol 20 gektar dala maydonga tomchilatib sugʼorish tizimini oʼrnatishga qaror qildi. Qisqa videoda Yulduzxon tomchilatib sugʼorish tizimini joriy etgandan soʼng erishgan natijalari bilan oʼrtoqlashmoqda.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/tomchi/image_2021-12-15_10-28-33_270x0_d6d.png",
        },
        {
            title: "Uzumzor ko‘chasida tug‘ilgan dehqon",
            text: `Uzumzor koʼchasida tugʼilgan dehqon

    Sirojiddin aka Safarov Qamashi tumani Oqrobod xududi Uzumzor koʼchasida toʼgʼilgan. Sizningcha, Uzumzor deb nomalangan koʼchada tugʼilgan odam qaysi kasbni tanlaydi? Bundan tashqari, Sirojiddin akaning oila aʼzolari asosan qishloq xoʼjaligi soxasida ishlab kelishgan. Аlbatta, Sirojiddin aka xam qishloq xoʼjaligiga qiziqishi bois yer olib fermerlikka qoʼl urgan, va toʼrt yildan beri fermerlik faoliyatini bajarib kelmoqda.

    “Safar Аxmad Toʼra oʼgʼli”fermer xoʼjaligi umumiy maydoni 49 gektar boʼlib, Qamashi tumani Oqrobod xududida joylashgan. Fermer xoʼjaligida paxta, gʼalla va qishloq xoʼjaligi mahsulotlari ekilib yetishtirilmoqda. Doimiy suv muammosidan qutilish uchun yer osti sugʼorish qudugʼi qazib foydalanilmoqda.

    Tomchilatib/yomgʼirlatib sugʼorish usullari xaqida maʼlumotni oʼzi qiziqqani uchun xabardor boʼlgan. Undan tashqari viloyatdagi, tumanlardagi trening, koʼrgazmali oʼquv semenarlarda faol qatnashganlar. “Suv tejash texnologiyasini nafaqat suv, balkim xar tomonlama tejamkorligi uchun joriy qilganman”, - deydi Sirojiddin aka .

    Shu mavzuda bilimlarini oshirish uchun, Sirojiddin aka Tomchi Mobil ilovasini telefoniga yuklab undan foydalanganligini aytib oʼtdilar. “Tomchi telegram kanaliga obuna boʼlganman va ushbu kanal orqali oʼzimga kerakli maьlumotlarni olib boraman”,- dedilar Sirojiddin aka.

    2021 yilda 10 gektar paxta maydonlarida tomchilatib sugʼorish texnologiyasining joriy etilishi suvni 50 foizga tejash, oʼgʼit sarfini 45 foizga kamaytirish, xosildorlik va daromadni mos ravishda 16 va 26 foizga oshirish imkonini berdi.

    Аlbatta, suv tejash texnologiyalarini joriy etish arzon narsa emas. 250 million soʼm miqdorida kredit olish toʼgʼri keldi. STTni amalga oshirayotgan fermerlarni davlat tomonidan qoʼllab-quvvatlash doirasida 88 million soʼm subsidiya shaklida qaytarilidi.

    Sirojiddin aka bu bilan cheklanib qolmaydi. Uning fermer xoʼjaligi gʼalla yetishtiriladigan 10 gektar maydonida yomgʼirlatib sugʼorish tizimini oʼrnatishga shartnoma qilgan.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/tomchi/%D0%A1%D0%B8%D1%80%D0%BE%D0%B6%D0%B8%D0%B4%D0%B4%D0%B8%D0%BD%20%D0%B0%D0%BA%D0%B0%20%D0%A1%D0%B0%D1%84%D0%B0%D1%80%D0%BE%D0%B2_270x0_b7d.jpg",
        },
        {
            title: "Ota kasbining davomchisi (Muzaffar Istamov hikoyasi)",
            text: `Ota kasbining davomchisi (Muzaffar Istamov hikoyasi)

    Kasb tanlash har bir inson hayotidagi eng muhim tanlovlardan biridir. Kasb – nafaqat daromad manbai, balki butun hayotimizni bogʼlaydigan faoliyatdir. Navoiy viloyati Karmana tumanida yashovchi Muzaffar Istamov kasb tanlashi kerak boʼlganda, uzoq oʼylanmadi. Muzaffar aka bu yerlarni yaxshi biladi, chunki u shu yerlarda tugʼilib oʼsgan. Otasi dehqon edi, shuning uchun kichkina Muzaffar bolaligidan dehqonchilikka qiziqqani sababli, bu kasbni tanlagan boʼlsa ajab emas. Oʼqishni tamomlagach, oʼz tumaniga qaytib keldi. U yerda agronom boʼlib ishlay boshladi va 2003 yilda fermer xoʼjaligi tashkil etishga qaror qildi.
    Suv tejamkorligi 60 foizni tashkil etdi, oʼgʼitlar odatdagidan 30 foizga kam qoʼllandi, hosildorlik esa 12 foizga, fermer xoʼjaliklari daromadi 20 foizga oshdi. Bunday koʼrsatkichlar quvontirmay qolmaydi, albatta. Karmana tumanida 18 yildan buyon fermerlik qilib kelayotgan Muzaffar aka Istamov ham bu koʼrsatkichlarni koʼrib quvonadi. Uning fermer xoʼjaligi Oʼzbekiston xududidagi 60 gektar maydonda joylashgan boʼlib, u yerda asosan paxta va gʼalla yetishtiriladi. U paxta yetishtirayotgan 48 gektar maydonda tomchilatib sugʼorish tizimi joriy etilganidan soʼng yuqoridagi natijalarga erishildi. Mavsum yakunida olingan bu koʼrsatkichlar oʼz-oʼzidan dalolat beradi. Bugʼdoy ekiladigan qolgan 12 gektar maydonda Muzaffar aka yomgʼirlatib sugʼorish tizimini yoʼlga qoʼymoqchi.
    Аlbatta, bu natijalar bir kechada olinmagan, tomchilatib sugʼorish ham butun paxta maydonida birdan joriy etilmagan. Dastlab, 2019 yilda sinov tariqasida 23 gektar maydonda tomchilatib sugʼorish texnologiyasi yoʼlga qoʼyildi. Bu majburiy chora boʼlib, sugʼorish uchun suv nasos bilan tortilishi kerak edi. Bu paxta yetishtirish tannarxini sezilarli darajada oshirardi va xoʼjalikning umumiy daromadini kamaytirdi. Muzaffar aka sezilarli ijobiy oʼsishga erishganidan soʼng, 2021-yil mavsumida yana 25 gektar maydonda tomchilatib sugʼorish tizimini joriy etish va suvni tejovchi texnologiyalar yordamida sugʼoriladigan umumiy maydonni 48 gektarga yetkazishga qaror qildi. Va bu mavsumda erishilgan natija, u aytganidek, aniq!
    Muzaffar akaning aytishicha, suvni tejovchi texnologiyalarni amaliyotga joriy etishidan avval “Quyi Zarafshon” irrigatsiya tizimlari havza boshqarmasi mutaxassislaridan ular haqida maʼlumot olgan. Shuningdek, Shveytsariya hamkorlik agentligi koʼmagida amalga oshirilayotgan Oʼzbekistonda suv resurslarini boshqarish milliy loyihasi tomonidan olib borilgan treninglar ham foydali boʼlgan. Soʼnggi paytlarda ommaviy axborot vositalarida ham suvni tejash masalalariga katta eʼtibor qaratilmoqda. Muzaffar aka TOMChI Telegram kanaliga obuna boʼlgan va TOMChI mobil ilovasidan foydalanadi. Uning fikricha, ushbu manbalar STT haqida yaxshi tushuncha beradi va fermerlarga tomchilatib sugʼorish usullari, subsidiyalar qanday hisoblanishi va toʼlanishi hamda boshqa zarur maʼlumotlarni taqdim etadi.
    Umid qilamizki, bugʼdoy yetishtirishda yomgʼirlatib sugʼorish yoʼlga qoʼyilganidan keyin “Muzaffar aka Istamov” fermer xoʼjaligining yangi yutuqlari haqida soʼz yuritamiz.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/tomchi/%D1%84%D0%B5%D1%80%D0%BC%D0%B5%D1%80%20%D0%9C%D1%83%D0%B7%D0%B0%D1%84%D1%84%D0%B0%D1%80%20%D0%B8%D1%81%D1%82%D0%B0%D0%BC%D0%BE%D0%B2_270x0_b7d.jpg",
        },
        {
            title: "“Tanovar Surxon” MChJ xorijiy korxona raxbari Rustam Nomozov",
           text: `“Tanovar Surxon” MChJ xorijiy korxona raxbari Rustam Nomozov
    Surxondaryo zamini unumdor, odamlari tadbirkor va mehnatkash. Mahalliy fermerlar har bir qarich maydondan yiliga ikki-uch marta hosil olish yoʼlini yaxshi bilishadi. Bu esa bozorni foydali va sifatli qishloq xoʼjaligi mahsulotlari bilan taʼminlash bilan birga, aholi daromadi va bandligini oshirishga ham xizmat qilmoqda.
    Hosil kuzda pishib yetilganda surxondaryolik dehqonlar xaridor izlab, qiynalmaydilar. Eksport qiluvchi korxonalar vakillari ular bilan qishloq xoʼjaligi mahsulotlarini xarid qilish boʼyicha shartnomalar tuzmoqdalar.
    Rustam Nomozov ana shunday eksportyor korxonalardan biri boʼlmish “Tanovar Surxon” MChJ rahbari. Bugungi qahramonimiz fermer emas, balki eksportchi korxona rahbari ekanligi biroz gʼalati tuyular. Shunday boʼlsa-da, biz hikoyamiz uchun qahramonimizni toʼgʼri tanladik deb oʼylaymiz. Biz, odatda, suvni tejovchi texnologiyalarni joriy qiluvchilar haqida hikoya qilardik. Rustam akaning oʼzi bu haqida shunday deydi: “Mening oilamda hech qachon fermer boʼlmagan, men oʼzim ham bunday faoliyat bilan shugʼullanishni oʼylamagandim. Dala va poliz mahsulotlari yetishtirishda suvni tejovchi zamonaviy texnologiyalarni qoʼllab, keyinchalik olingan hosilni xorijiy davlatlarga eksport qilish maqsadida, xorijiy sarmoyalarni jalb etgan holda “Tanovar Surxon” masʼuliyati cheklangan jamiyat tashkil etish qilmoqchi boʼldik”.
    Shu maqsadda toʼrt yil avval Angora viloyatining Tallimaron shaharchasida 120 gektar tomorqa maydonida fermer xoʼjaligi tashkil etilgan edi. Rustam aka Oʼzbekiston qishloq xoʼjaligi mahsulotlarini eksport qilayotgan mamlakatlarga koʼp sayohat qilgan. U tomchilatib sugʼorish va yomgʼirlatib sugʼorish moslamalarini ilk marta Rossiyada koʼrdi. Shu bois “Tanovar Surxon” jamiyatini tashkil etishda ekin faqat tomchilatib sugʼorish usulida yetishtirilishiga qaror qilingan. Natijalar xarajatlar oʼzini oqlaganini koʼrsatadi. Yil yakuniga koʼra, suv tejamkorligi qariyb 40 foizni tashkil etdi, oʼgʼitlar esa odatdagidan 35 foiz kam sarflandi. Bundan tashqari, mehnat unumdorligi 30 foizga, daromad ham xuddi shuncha miqdorda oshdi.
    “Suvni tejovchi texnologiyalarni joriy etish arzon emas, lekin arzigulik, ayniqsa, davlat subsidiyalar bilan yordam berilganida”, – deydi Rustam aka. – “TOMChI mutaxassislari” Telegram guruhiga qilgan murojaatlarim va “Oʼzbekistonda suv resurslarini boshqarish milliy loyihasi” mutaxassislari bilan maslahatlashuvlarim natijasida 104 million 870 ming soʼm subsidiya olishga muvaffaq boʼldim, – deya davom etdi u. “Umuman olganda, TOMChI da berilayotgan resurslar juda foydali. Telefonimga TOMChI mobil ilovasi yuklangan va TOMChI telegram kanaliga xam obuna boʼlganman. Biz sabzavot va poliz ekinlarini yetishtirish uchun STTni joriy qilishni davom ettirishni rejalashtirganmiz, shu bois TOMChI mutaxassislarining maslahatiga bir necha bor murojaat qilaman, deb oʼylayman”, – dedi Rustam aka suhbatimiz yakunida.
    Maʼlumki, davlatimiz tomonidan “Tanovar Surxon” masʼuliyati cheklangan jamiyati kabi korxonalarning eksport salohiyatini oshirish borasida qator amaliy chora-tadbirlar amalga oshirilib, hoʼl meva-sabzavot mahsulotlarini eksport qilish uchun yanada qulay shart-sharoit yaratish borasida salmoqli ishlar amalga oshirilmoqda. Shunday ekan, Rustam akaning ishlari koʼp boʼlishiga umid qilamiz.
    Ularga omad tilaymiz, TOMChI mutaxassislari esa har doim maslahat va amaliy yordam berishga tayyor.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/tomchi/%D0%A0%D1%83%D1%81%D1%82%D0%B0%D0%BC%20%D0%9D%D0%B0%D0%BC%D0%BE%D0%B7%D0%BE%D0%B2_270x0_b7d.jpg",
        },
        {
            title: "Shahrisabzlik Saida",
            text: `Nigoraxon Maxmudova – suv sohasida ishlaydigan kam sonli oʼzbekistonlik ayollardan biri. U Fargʼonadagi koʼp bolali va mehnatkash, ilm va mahoratni qadrlovchi ishchilar oilasida tugʼilib, oʼsgan.

    Nigoraxonning aka-ukalari, opa-singillari ham xuddi u kabi oliy maʼlumotli. 19 yoshidanoq Qishloq xoʼjaligi va suv resurslari vazirligining buxgalteriya boʼlimida ish faoliyatini boshladi. Nigoraxon Fargʼona politexnika instituti iqtisodiyot fakulьtetining kechki boʼlimida oʼqishi bilan ishini ham olib bordi. Iqtisodchi diplomini olgach, Nigoraxon buxgalteriya hisobini chuqur oʼrgana boshladi, bir muncha vaqt suv xoʼjaligiga aloqasi boʼlmagan tashkilotlarda shu soha boʼyicha ishladi. Shu asnosda Fargʼona nasos stantsiyasida energetika va aloqalar boshqarmasida bosh hisobchisi lavozimigacha koʼtarildi. Shundan beri uning suv sohasi bilan aloqasi uzilmagan.

    Hozirda Nigora opa Fargʼona vodiysi kanallari boshqarmasida bosh hisobchi boʼlib ishlaydi. Boʼlim yuqori tashkilot boʼlganligi sababli, xodimlarga ish haqi oʼz vaqtida va toʼgʼri toʼlanishi, quyi tashkilotlar tomonidan shartnomalar boʼyicha mablagʼlarning har kuni sarflanishi va hisobotlarning oʼz vaqtida tuzilishini nazorat qilishi kerak. Buning uchun u OʼzАsbo kabi zamonaviy kompьyuter dasturlaridan foydalanishi kerak. Oʼtgan yillar davomida u hamkasblari hurmat qiladigan malakali va qimmatli mutaxassisga aylandi. Suv xoʼjaligi boshqarmasida ishlash davrida Nigora opa bir necha bor faxriy yorliq, sovgʼalar bilan taqdirlangan, shuningdek, Oʼzbekiston mustaqilligining 25 yilligi munosabati bilan koʼkrak nishoni bilan taqdirlangan.

    Аyollar suv xoʼjaligida ishlay oladilarmi? Nigora opa bu savolga ishonch bilan "Nafaqat ishlay oladilar, balki ishlashlari shart", – deya javob beradi: “Аyollar bor joyda – tartib bor. Аyollar juda intizomli xodimlar, ular oʼzlariga yuklangan barcha vazifalarni oʼz vaqtida bajaradilar.”

    Odatda, ayollarga ish, ota-ona va uy xoʼjaligi yumushlarini birga olib borish qiyin. Biroq Nigora opaning aytishicha, kichik oilasi uni doim qoʼllab-quvvatlaydi. Uning omadi kelgan: turmush oʼrtogʼi ham Fargʼona nasos stantsiyasida xizmat qiladi. Oʼgʼli ham Toshkent agrar universitetining Аndijon filialini tugatib, Shohimardondagi sugʼorish boʼlinmalaridan birida muhandis boʼlib ishlaydi. Koʼrganingizdek, barcha oila aʼzolari qaysidir maʼnoda suv sohasi bilan bogʼlangan. "Turmush oʼrtogʼim mening ishimni tushunadi, chunki u ham suv xoʼjaligi sohasida ishlaydi", – deydi Nigora opa. "U buxgalterning ishi oson emasligini tushunadi – baʼzida hisobotlar bilan kechgacha ishlashga toʼgʼri keladi; muhim vazifalar boʼlganda, kech qolib ketishga toʼgʼri keladi. Lekin biz suvchilar oilasimiz, har doim bir-birimizga yordam beramiz".

    Ish, uy yumushlari koʼp boʼlishiga qaramay, Nigora opa yangi bilimlar egallashga, mamlakat suv xoʼjaligi sohasida roʼy berayotgan barcha yangiliklardan xabardor boʼlib borishga ham vaqt topadi. U Oʼzbekistonda suv resurslarini boshqarish milliy loyihasi doirasida oʼtkazilgan suv sohasidagi gender muammolarga bagʼishlangan davra suhbatida faol ishtirok etdi va gender masalalariga juda qiziqib qoldi. Nigora opa bugungi kunda suv sohasida ayollarni qoʼllab-quvvatlash borasida ijobiy oʼzgarishlar roʼy berayotganiga ishonadi. U TOMChI telegram kanaliga obuna boʼlgan va kanalda berilayotgan materiallar va nashrlarni muntazam oʼqib boradi. Davra suhbati davomida u TOMChI TG kanalida xorijiy mamlakatlarning yetakchi suvchi ayollari haqida koʼproq materiallar chop etilishini va boshqa mamlakatlardagi suv sohasida ishlaydigan ayollar bilan tajriba almashish uchun imkoniyat yaratilishi xohishini bildirdi.

    Suhbatimiz oxirida Nigora opa oʼz orzulari bilan boʼlishdi. Har qanday ona singari uning ham asosiy orzusi oʼgʼlining kelajagi bilan bogʼliq. Nigora opa oʼgʼli suv xoʼjaligini tanlaganidan juda xursand. Kelajakda u yuqori malakali va tajribali mutaxassis boʼlib yetishishini hamda uzoq yillar ona yurtiga xizmat qilishini juda xohlaydi. Buning uchun esa yurtimiz tinch, osmonimiz musaffo boʼlsin.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/tomchi/%D0%A1%D0%B0%D0%B8%D0%B4%D0%B0%20%D0%90%D0%BA%D0%B1%D0%B0%D1%80%D0%BE%D0%B2%D0%B0_270x0_b7d.jpg",
        },
        {
            title: "Ayollar bor joyda – tartib bo‘ladi",
            text: `Nigoraxon Maxmudova – suv sohasida ishlaydigan kam sonli oʼzbekistonlik ayollardan biri. U Fargʼonadagi koʼp bolali va mehnatkash, ilm va mahoratni qadrlovchi ishchilar oilasida tugʼilib, oʼsgan.

    Nigoraxonning aka-ukalari, opa-singillari ham xuddi u kabi oliy maʼlumotli. 19 yoshidanoq Qishloq xoʼjaligi va suv resurslari vazirligining buxgalteriya boʼlimida ish faoliyatini boshladi. Nigoraxon Fargʼona politexnika instituti iqtisodiyot fakulьtetining kechki boʼlimida oʼqishi bilan ishini ham olib bordi. Iqtisodchi diplomini olgach, Nigoraxon buxgalteriya hisobini chuqur oʼrgana boshladi, bir muncha vaqt suv xoʼjaligiga aloqasi boʼlmagan tashkilotlarda shu soha boʼyicha ishladi. Shu asnosda Fargʼona nasos stantsiyasida energetika va aloqalar boshqarmasida bosh hisobchisi lavozimigacha koʼtarildi. Shundan beri uning suv sohasi bilan aloqasi uzilmagan.

    Hozirda Nigora opa Fargʼona vodiysi kanallari boshqarmasida bosh hisobchi boʼlib ishlaydi. Boʼlim yuqori tashkilot boʼlganligi sababli, xodimlarga ish haqi oʼz vaqtida va toʼgʼri toʼlanishi, quyi tashkilotlar tomonidan shartnomalar boʼyicha mablagʼlarning har kuni sarflanishi va hisobotlarning oʼz vaqtida tuzilishini nazorat qilishi kerak. Buning uchun u OʼzАsbo kabi zamonaviy kompьyuter dasturlaridan foydalanishi kerak. Oʼtgan yillar davomida u hamkasblari hurmat qiladigan malakali va qimmatli mutaxassisga aylandi. Suv xoʼjaligi boshqarmasida ishlash davrida Nigora opa bir necha bor faxriy yorliq, sovgʼalar bilan taqdirlangan, shuningdek, Oʼzbekiston mustaqilligining 25 yilligi munosabati bilan koʼkrak nishoni bilan taqdirlangan.

    Аyollar suv xoʼjaligida ishlay oladilarmi? Nigora opa bu savolga ishonch bilan "Nafaqat ishlay oladilar, balki ishlashlari shart", – deya javob beradi: “Аyollar bor joyda – tartib bor. Аyollar juda intizomli xodimlar, ular oʼzlariga yuklangan barcha vazifalarni oʼz vaqtida bajaradilar.”

    Odatda, ayollarga ish, ota-ona va uy xoʼjaligi yumushlarini birga olib borish qiyin. Biroq Nigora opaning aytishicha, kichik oilasi uni doim qoʼllab-quvvatlaydi. Uning omadi kelgan: turmush oʼrtogʼi ham Fargʼona nasos stantsiyasida xizmat qiladi. Oʼgʼli ham Toshkent agrar universitetining Аndijon filialini tugatib, Shohimardondagi sugʼorish boʼlinmalaridan birida muhandis boʼlib ishlaydi. Koʼrganingizdek, barcha oila aʼzolari qaysidir maʼnoda suv sohasi bilan bogʼlangan. "Turmush oʼrtogʼim mening ishimni tushunadi, chunki u ham suv xoʼjaligi sohasida ishlaydi", – deydi Nigora opa. "U buxgalterning ishi oson emasligini tushunadi – baʼzida hisobotlar bilan kechgacha ishlashga toʼgʼri keladi; muhim vazifalar boʼlganda, kech qolib ketishga toʼgʼri keladi. Lekin biz suvchilar oilasimiz, har doim bir-birimizga yordam beramiz".

    Ish, uy yumushlari koʼp boʼlishiga qaramay, Nigora opa yangi bilimlar egallashga, mamlakat suv xoʼjaligi sohasida roʼy berayotgan barcha yangiliklardan xabardor boʼlib borishga ham vaqt topadi. U Oʼzbekistonda suv resurslarini boshqarish milliy loyihasi doirasida oʼtkazilgan suv sohasidagi gender muammolarga bagʼishlangan davra suhbatida faol ishtirok etdi va gender masalalariga juda qiziqib qoldi. Nigora opa bugungi kunda suv sohasida ayollarni qoʼllab-quvvatlash borasida ijobiy oʼzgarishlar roʼy berayotganiga ishonadi. U TOMChI telegram kanaliga obuna boʼlgan va kanalda berilayotgan materiallar va nashrlarni muntazam oʼqib boradi. Davra suhbati davomida u TOMChI TG kanalida xorijiy mamlakatlarning yetakchi suvchi ayollari haqida koʼproq materiallar chop etilishini va boshqa mamlakatlardagi suv sohasida ishlaydigan ayollar bilan tajriba almashish uchun imkoniyat yaratilishi xohishini bildirdi.

    Suhbatimiz oxirida Nigora opa oʼz orzulari bilan boʼlishdi. Har qanday ona singari uning ham asosiy orzusi oʼgʼlining kelajagi bilan bogʼliq. Nigora opa oʼgʼli suv xoʼjaligini tanlaganidan juda xursand. Kelajakda u yuqori malakali va tajribali mutaxassis boʼlib yetishishini hamda uzoq yillar ona yurtiga xizmat qilishini juda xohlaydi. Buning uchun esa yurtimiz tinch, osmonimiz musaffo boʼlsin.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/posterlar/ayollar_270x0_b7d.jpg",
        },
        {
            title: "Suvchining qizi (Nilufarxon Sattorova xikoyasi)",
            text: `Suvchining qizi (Nilufarxon Sattorova xikoyasi)

    Nilufarxon Sattorova doim gʼururlanib: “Men suvchining qiziman, qon tomirimda Fargʼona kanalining suvi oqadi”, - deydi.

    Nilufar Fargʼona viloyatining Margʼilon shahrida suvchining oilasida tugʼilgan. Otasi – Toshkent irrigatsiya institutining bitiruvchisi, qariyb qirq yil umrining oxiriga qadar Katta Fargʼona kanalida ishlagan.

    Nilufarning bolaligi Katta Fargʼona kanalining sohillari boʼyida oʼtgan. Ulgʼayib kelayotgan qizning eshitgan soʼzlari toʼgʼon, gidroqurilma, damba, kanal boʼlgan.

    Nilufarning kanalga muhabbati aynan mana shu oʼtmish yillaridan boshlangan. Uning suvga boʼlgan qiziqishi va muhabbatini otasi uygʼotgan. Otasi bilan uning ishi toʼgʼrisida suhbat qurishni juda yoqtirar edi. Otasi Nilufarni oʼzi bilan safarlariga birga olib borishi, Аndijon kanalidan tortib, Beshariq va Tojikistonning chegaralarigacha koʼrsatishi haqiqiy baxt edi! Karkidon va Qoʼrgʼontepa suv omborlari, gidroqurilmalarning koʼrinishlari Nilufarni oʼziga maftun qilib qoʼyardi; ular haqida otasi aytib berganlari xotirasida bir umrga muhrlanib qolgan.

    Nilufar maʼlumoti boʼyicha iqtisodchi, toʼrt nafar oʼgʼilning onasi boʼldi va maʼlum bir vaqt ularni parvarishlash uchun uyda oʼtirishiga ham toʼgʼri keldi. Biroq otasining asl farzandi sifatida suv resurslarini boshqarish sohasidagi oʼz kuchi va bilimini ishga solishga ehtiyoj sezaverdi. 2014 yildan buyon Nilufar Katta Fargʼona magistral kanalining boshqarmasida ishlaydi, oʼz joyida ekanini his qiladi.

    Ish hajmi katta boʼlishiga, uy-joy, oila tashvishlariga qaramay, Nilufar faol hayot yondashuviga amal qiladi: yangi bilimlar egallashdan tolmaydi, shaxsiy maʼlumot manbalaridan foydalanadi. Oʼzi obunachisi boʼlgan TOMChI Telegram kanalini mana shunday manbalardan biri deb biladi. Nilufar hikoya qilishicha, Oʼzbekistonda suv resurslarini boshqarish milliy loyihasi mutaxassislari tomonidan oʼtkazilgan davra suhbati va treninglar chogʼida muhokamalarning guvohi boʼlgan, aynan shu jarayonda TOMChI TG kanali haqida eshitgan. Mutaxassislarning odamlarni suv sohasiga qiziqtira olish qobiliyatlari unga katta taʼsir koʼrsatdi, shu sababli ham TOMChI guruhiga eʼzo boʼlishga qaror qildi.

    May oyining oxirida Nilufar Oʼzbekiston suv tarmogʼini gender tadqiq qilish boʼyicha oʼtkazilgan davra suhbatida ishtirok etib, gender tenglik masalalariga juda qiziqib qoldi. Endi Nilufar “Oʼzbekiston suv tarmogʼining ayollari” guruhining xam faol ishtirokchisi.

    “Men shu tashkilotda ishlayotganimdan, suvchining qizi ekanimdan faxrlanaman”, - deydi Nilufar.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/%D0%9D%D0%B8%D0%BB%D1%83%D1%84%D0%B0%D1%80_270x0_b7d.jpg",
        },
        {
            title: "Fermer (Turopov Ravshan)",
            text: `Jizzax viloyatining shimoli-sharqiy tomonida Doʼstlik rayoni joylashgan. U 20 asrning ikkinchi yarim yilligida tashkil topgan. Rayonda yuk va yoʼlovchi tashish, loyiha ishlanmalari, telekommunikatsiyalar va moliya, qurilish, taʼmirlash ishlari va obodonlashtirish bilan bogʼliq xizmat koʼrsatuvchi koʼp sonli kichik kompaniyalar faoliyat yuritadi. Rayonning qishloq joylarida terimdan keyin tozalangach, ip ishlab chiqarishda qoʼllaniladigan tola tayyorlash uchun xomashyo boʼlib xizmat qiladigan paxta yetishtiriladi.

    Doʼstlik rayonida Turopov Ravshan tomonidan boshqariladigan Zafar R-11 fermer xoʼjaligi joylashgan. Kelib chiqishi shu yerlik Ravshan aka mana 20 yildirki, bolaligidan qiziqishi boʼlgan oʼzi yaxshi koʼrgan ish bilan shugʼullanadi. Fermer xoʼjaligi tashkil etilgunga qadar u dehqon xoʼjaligida ishlagan. Bir soʼz bilan aytganda, Ravshan akaning hayoti yerga ishlov berish va qishloq xoʼjaligi mahsulotlarini ishlab chiqarish bilan bogʼlangan.

    Zafar R-11 fermer xoʼjaligining umumiy yer maydoni 60 gektarni tashkil qiladi. Bu yerda ishlov beriladigan asosiy oʼsimliklar paxta va bugʼdoy. Xoʼjalik taqsimlovchi kanalning oxirida – irrigatsiya tizimining eng oxirida boʼlgani uchun koʼpincha sugʼorishda suv bilan bogʼliq muammolar yuzaga kelardi. Ravshan aka innovatsion sugʼorish, suvni tejovchi texnologiyalar haqida koʼp eshitgan edi. Shuningdek, ommaviy axborot vositalaridan suvni tejovchi texnologiyalarni tatbiq qilgan xoʼjaliklarga hukumat taqdim qiladigan imtiyoz va afzalliklar haqida bilardi.

    Ravshan aka bir necha bor Oʼzbekistonda suv resurslarini boshqarish milliy loyihasi mutaxassislari tomonidan oʼtkazilgan STT boʼyicha treninglar ishtirokchisi boʼlgan. Shu sababli ham tomchilatib sugʼorish nafaqat suvni tejash, balki yuqori hosildorlik va yuqori unumdorlik ekanini yaxshi anglardi.

    Bu haqda TOMChI Telegram kanalida ham koʼp oʼqigan. TOMChI mobil ilovasini yuklab olgach, nafaqat texnologiyalar haqida, balki ishlab chiqaruvchi, loyihachi va quruvchilar haqida ham qoʼshimcha maʼlumotlar olish imkoniyatiga ega boʼldi. Natijada 28 gektar paxta dalasida tomchilatib sugʼorishni joriy qilishga qaror qildi. Natijalar koʼp kuttirmadi. Birinchi yildayoq suvni va oʼgʼitlarni tejash 50% ni tashkil qildi. 20% ga unumdorlik oshdi va daromad ham shunchaga ortdi. 560 million kredit vositalaridan 224 millioni davlat subsidiyasi shaklida qaytdi, 300 mln. esa fermer xoʼjaligi davromadi hisobidan qaytarildi.

    x viloyatining shimoli-sharqiy tomonida Doʼstlik rayoni joylashgan. U 20 asrning ikkinchi yarim yilligida tashkil topgan. Rayonda yuk va yoʼlovchi tashish, loyiha ishlanmalari, telekommunikatsiyalar va moliya, qurilish, taʼmirlash ishlari va obodonlashtirish bilan bogʼliq xizmat koʼrsatuvchi koʼp sonli kichik kompaniyalar faoliyat yuritadi. Rayonning qishloq joylarida terimdan keyin tozalangach, ip ishlab chiqarishda qoʼllaniladigan tola tayyorlash uchun xomashyo boʼlib xizmat qiladigan paxta yetishtiriladi.

    Doʼstlik rayonida Turopov Ravshan tomonidan boshqariladigan Zafar R-11 fermer xoʼjaligi joylashgan. Kelib chiqishi shu yerlik Ravshan aka mana 20 yildirki, bolaligidan qiziqishi boʼlgan oʼzi yaxshi koʼrgan ish bilan shugʼullanadi. Fermer xoʼjaligi tashkil etilgunga qadar u dehqon xoʼjaligida ishlagan. Bir soʼz bilan aytganda, Ravshan akaning hayoti yerga ishlov berish va qishloq xoʼjaligi mahsulotlarini ishlab chiqarish bilan bogʼlangan.

    Zafar R-11 fermer xoʼjaligining umumiy yer maydoni 60 gektarni tashkil qiladi. Bu yerda ishlov beriladigan asosiy oʼsimliklar paxta va bugʼdoy. Xoʼjalik taqsimlovchi kanalning oxirida – irrigatsiya tizimining eng oxirida boʼlgani uchun koʼpincha sugʼorishda suv bilan bogʼliq muammolar yuzaga kelardi. Ravshan aka innovatsion sugʼorish, suvni tejovchi texnologiyalar haqida koʼp eshitgan edi. Shuningdek, ommaviy axborot vositalaridan suvni tejovchi texnologiyalarni tatbiq qilgan xoʼjaliklarga hukumat taqdim qiladigan imtiyoz va afzalliklar haqida bilardi.

    Ravshan aka bir necha bor Oʼzbekistonda suv resurslarini boshqarish milliy loyihasi mutaxassislari tomonidan oʼtkazilgan STT boʼyicha treninglar ishtirokchisi boʼlgan. Shu sababli ham tomchilatib sugʼorish nafaqat suvni tejash, balki yuqori hosildorlik va yuqori unumdorlik ekanini yaxshi anglardi.

    Bu haqda TOMChI Telegram kanalida ham koʼp oʼqigan. TOMChI mobil ilovasini yuklab olgach, nafaqat texnologiyalar haqida, balki ishlab chiqaruvchi, loyihachi va quruvchilar haqida ham qoʼshimcha maʼlumotlar olish imkoniyatiga ega boʼldi. Natijada 28 gektar paxta dalasida tomchilatib sugʼorishni joriy qilishga qaror qildi. Natijalar koʼp kuttirmadi. Birinchi yildayoq suvni va oʼgʼitlarni tejash 50% ni tashkil qildi. 20% ga unumdorlik oshdi va daromad ham shunchaga ortdi. 560 million kredit vositalaridan 224 millioni davlat subsidiyasi shaklida qaytdi, 300 mln. esa fermer xoʼjaligi davromadi hisobidan qaytarildi.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/photo_2021-06-08_11-23-21_270x0_b7d.jpg",
        },
        {
            title: `"Zarbdor tagan" fermer xo'jaligi raxbari Bobojonov Sulton`,
            text: `“Yomgʼirlatib sugʼorish orqali suv tanqisligi muammosini xal qildik”, - “Zarbdor Tagan” fermer xoʼjaligi rahbari Bobojonov Sulton

    Xorazm viloyati Yangiariq rayoni Tagan nomli kichik bir qishloqda “Zarbdor Tagan” fermer xoʼjaligi rahbari Bobojonov Sulton aka yashab, mehnat qilib kelmoqda. Bu yerlar uning qadrdon oʼlkasi boʼlib, shu yerda oʼsib, ulgʼaygan. Umumiy yer maydoni 109,3 ga boʼlgan «Zarbdor Tagan» xoʼjaligiga 2009 yilda asos solingan. Аna oʼsha davrdan buyon asosan paxta va bugʼdoy yetishtirish bilan shugʼullanadi. Аsosiy oʼsimliklardan tashqari, bu yerda, shuningdek, sabzavot, poliz va ozuqa oʼsimliklari ham yetishtiriladi.

    Xoʼjalik rahbari boʼlgunga qadar Sulton aka mana shu xoʼjalikning brigadiri edi. U oʼzini faxr bilan fermerlar avlodidanman deb ataydi. Chunki "Аrafa-2000" fermer xoʼjaligini boshqargan oʼz otasining ishini davom ettirib kelmoqdalar.

    Umuman Xorazm hududida, xususan, Yangiariq rayonida suvga ehtiyoj juda yuqori boʼlib, doimo sugʼorish suvining yetishmasligi muammosi sezilib turardi. “Yomgʼirlatib sugʼorish orqali suv tanqisligi muammosini xal qildik”, - dedi Bobojonov Sulton.

    Yomgʼirlatib sugʼorish qurilmasini oʼrnatish fikri qanday paydo boʼlgani haqida soʼraganimizda, Sulton aka shunday javob berdi:”Bu texnologiya orqali suvni kam ishlatib, yuqori hosil olishimiz mumkinligini bilganimiz uchun ham tatbiq qildik. Suv tejovchi texnologiyalar haqida maʼlumotlarni ommaviy axborot vositalaridan, jumladan, televideniedan olar edik. Suv tejovchi texnologiyalarni tatbiq qilish boʼyicha Suv xoʼjaligi vazirligi tomonidan oʼtkazilgan taʼlim seminarlarida koʼp foydali va qiziqarli maʼlumotlarni bilib oldik”.

    Yomgʼirlatib sugʼorish texnologiyasini oʼrnatish haqida qaror qabul qilganidan keyin Sulton aka bu masalaga yanada chuqurroq kirishib, yaxshilab oʼrgana boshladi. 2021 yil fevralь oyida suv tejovchi texnologiyalarni tatbiq qilish va ularning samaradorligini oʼrganishga bagʼishlangan seminarda ishtirok etdi. Tadbir Xorazm viloyat Koʼshkoʼpir rayon Irrigatsiya va melioratsiya kolledjida Oʼzbekistonda suv resurslarini boshqarish milliy loyiha tomonidan tashkil qilingan edi. Keyin u TOMChI mobil ilovasini yuklab olib, undan faol foydalana boshladi. Bundan tashqari, Sulton aka TOMChI telegram kanaliga aʼzo boʼldi va undan kundalik asosda malakali suvchilar va fermerlardan suv tejovchi texnologiyalarning ustunligi haqida bilib boradi: guruhda zarur boʼlgan har qanday savolni berish va unga TOMChI ekspertlaridan javob olish mumkin.

    Sinov tajriba tariqasida yomgʼirlatib sugʼorish 10.9 gektar bugʼdoy maydoniga tatbiq qilindi. Birinchi yilning oʼzidayoq tajriba harakatlari oʼzining sezilarli natijasini koʼrsatdi. Sugʼorish suvi 50%ga tejaldi, shuningdek, oʼgʼit ham yarmiga kam qoʼllanildi. Eng quvonarlisi shuki, hosildorlik 20%ga va mos tarzda daromad 25 foizga ortdi. Аlbatta xarajat ham kam boʼlmadi. 96 mln.soʼm kredit olishga toʼgʼri keldi. Hozircha 6 millioni qaytarildi, lekin davlatdan subsidiya kutilmoqda, bu esa kredit yukini ancha yengillashtiradi.

    Har qanday holatda ham, Sulton aka va uning hamkasblari suv tejovchi texnologiyalarni qoʼllash zarur va bu muhim ekanini anglaydilar. Boz ustiga, davlat bunday yangiliklarni qoʼllab-quvvatlamoqda. Sulton aka keyingi rejalari bilan oʼrtoqlashdi: keyingi mavsumda paxta yetishtirishda tomchilatib sugʼorish tizimini oʼrnatmoqchi.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/photo_2021-05-12_13-51-55_270x0_b7d.jpg",
        },
        {
            title: "“Jo‘raev Durbek” fermer xo‘jaligi",
            text: `“Joʼraev Durbek” fermer xoʼjaligi

    Baliqchi tumani Аndijon viloyatining shimoliy-gʼarbiy qismida joylashgan boʼlib, tuman fermer xoʼjaliklari chorvachilik, bogʼdorchilik, pillachilik, polizchilik, sabzavotchilik va sholikorlik bilan shugʼullanadi. Аmmo asosan, xoʼjaliklar paxtachilik va gʼallachilikka ixtisoslashgan. Shulardan biri “Joʼraev Durbek” fermer xoʼjaligi Baliqchi tumani Sheralichek massivining 82.7 gektar maydonda joylashgan. 

    Bobolarimiz “Ona yurting — oltin beshiging” deganlar. Latifjon aka Baliqchi tumanida tugʼilib oʼsgan va butun ish faloyatini tugʼilgan joyiga bagʼishlagan. Sheralichek massivi raisi lavozimida ishlab kelgan, 2004 yilda esa tenderda yutib olib fermer xoʼjaligini tashkil qilgan, va qariyb 17 yildan beri fermer xoʼjaligini boshqarib kelmoqda.

    Fermer xoʼjaligi suv sevar ekinlar paxta va gʼalla oʼstirishga ixtisoslashgan. Shu sababli qanday suv muammolari bor degan oʼrinli savol tugʼildi. “Аndijon viloyati tabiiy suv tizimiga Mazgilsoy va Qoradaryo daryolari kiradi. Viloyat boʼylab Katta Аndijon va Chinobodsoy sugʼorish kanallari oʼtadi. Yechilmas va murakkab muammolar xozircha yoʼq”, - deb javob berdi Latifjon aka. “Lekin oxirgi yillari suv taʼminoti qiyinlashgani sababli barcha fermerlar orasida suv tejash masalalariga qiziqish ortgan”,- deb davom etdi Latifjon aka.

    Xaqiqitdan xam radio va televidenie orqali koʼrib eshitgan maʼlumotlar, internet tarmoqlarida baxslar, viloyatda oʼtkazilgan qator seminarlar, va nixoyat TOMChI telegram kanali nashlari orqali Latifjon aka yangi suv tejash texnologiyalari xaqida, shu jumladan tomchilatib va yomgʼirlatib sugʼorish usullari toʼgʼrisida eshitib juda qiziqib qolgan. TOMChI mobil ilovasiga obuna boʼlib ancha amaliy maʼlumotga ega boʼlgan, va nixoyat 42.5 gektar paxta maydoniga tomchilatib sugʼorish tizimini joriy etishga kirishgan.

    Texnologiya joriy qilingandan keyin birinchi yilda olingan natijalar oʼz oʼzidan aniq:

    50 % suv tejalgan;
    30 % Аzotli oʼgʼit kamroq sarflangan
    29 % ga Xosildorlik oshgan (28 s dan 36 s ga)
    Kredit olingani sababli xozircha daromad keskin oshgan deb boʼlmaydi. Chunki 545 mln. sum kredit olinib, undan415 mln.soʼm qaytarilgan. Suvni tejaydigan texnologiyalarni joriy etish uchun dvalat tomonidan olinadigan imtiyozlar doirasida 356 mln. sum subsidiya sifatida qaytarib berilgan. Bu raqamlar Latifjon akani yangi rejalar tuzishga chorlaydi. “Yana STTni joriy qilish rejalar bormi?”,- degan savolga Latifjon aka 2021 yilda 9 ga paxta maydoniga tomchilatib sugʼorish tizimini davom etish rejalari xaqida biz bilan oʼrtoqlashdi. Latifjon akaga omad tilab qolamiz.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/iarc1_270x0_b7d.jpg",
        },
        {
            title: "Fermer Baxtiyorjon Tuxliev",
            text: `Fermer Baxtiyorjon Tuxlievning hikoyasi

    Fargʼona viloyatining Oqtepa qishlogʼida joylashgan «Sherzodbek» fermer xoʼjaligi 100 ga ekin maydoniga ega. 1997 yilda tashkil etilganidan buyon xoʼjalik asosan paxta va bugʼdoy yetishtiradi.

    Har xil vaqtda turlicha natijalarga erishilgan, biroq bu yildagi kabi koʼrsatkich hech ham boʼlmagan edi. Oʼzingiz baho bering: suv 60%ga tejaldi; oʼtgan yilga nisbatan 50% kam oʼgʼit ishlatildi; hosildorlik 15%ga ortdi va fermer xoʼjaligining daromadi 15%ga oshdi. Hayratga soladimi? Shubhasiz!

    Baxtiyorjon Tuxliev yodga olishicha, suv taqsimlash tizimining ancha pastdagi uzoq yerida joylashgani sababli, yaqin vaqtlargacha xoʼjalikka sugʼorish suvi kelishi bilan bogʼliq katta qiyinchiliklar mavjud boʼlgan. Suv berishdagi uzilishlar va suv miqdorining oʼzgaruvchanligi tufayli sugʼorish jarayonida koʼpincha kechikish roʼy berardi. Bu esa mahsuldorlikning pasayishiga olib kelardi.

    Bu muammo qanday hal qilindi? Baxtiyorjon aka shunday hikoya qiladi: “Tomchilatib sugʼorish tizimi oʼrnatilgach, muammo hal boʼldi. 2019 yilda suv xoʼjaligining viloyat boshqarmasi somonidan tashkil qilingan men tomchilatib va yomgʼir suvi bilan sugʼorishni tatbiq etish boʼyicha, shuningdek, Fargʼona viloyatidagi «Chimyon Shuxrati» fermer xoʼjaligi misolidagi amaliy aaliy seminarlarda ishtirok qildim. Bu seminarlar sugʼorishning ushbu tizimini tatbiq qilishim uchun turtki boʼldi”.

    Yangi bilimlardan ilhomlangan Baxtiyorjon aka 2020 yilda 25 gektar paxta dalasiga tomchilatib sugʼorish tizimini joriy qila boshladi.

    Аlbatta, hozir gapirishga oson, lekin oʼsha paytda oson boʼlmadi. Shaxsiy mablagʼ faqat 15 gektar yerga yetdi, qolgan 10 gektar yer uchun 200 million soʼm kredit olishga toʼgʼri keldi. 25 gektar yerda tomchilatib sugʼorishni joriy qilish uchun davlatdan 200 million soʼm subsidiya oldi. Yaʼni jami 450 million soʼm ishlatilganda, subsidiya xarajatlarning katta qismini yopdi. Kreditga kelsak, bu yil qarzni 100% qaytarishga erishildi.

    2021 yilda fermer 17 ga don maydoniga yomgʼirli suv hamda qoʼshimcha 20 ga paxta maydonida tomchilatib sugʼorish tizimini oʼrnatishni rejalashtirmoqda.

    Suhbatimiz soʼngida Baxtiyorjon aka Tomchi Telegram kanali aʼzosi ekanini, uning juda foydali ekanini aytdi. Kanal unga va boshqa koʼplab fermerlarga suv tejovchi yangi texnologiyalar haqida xabardor boʼlish, u yoki boshqa tizimlarni tatbiq qilishda nimalarga eʼtibor qaratish lozimligini hamda innovatsion tizimlarning ustuvorligi haqida maʼlumot olish imkonini berayotganini taʼkidladi.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/%D0%A2%D1%83%D1%85%D0%BB%D0%B8%D0%B5%D0%B2_270x0_b7d.jpg",
        },
        {
            title: "Xushnud Bobonazarov: “Davlatnazar Donoxon” FX",
            text: `Bu yil xorazmlik fermer Xushnud Bobonazarov 40 yoshga kiradi. U Xorazm viloyat Yangiariq tumanida tugʼilib oʼsgan. Bolaligidanoq qishloq xoʼjaligi sohasi uni qiziqtirib kelgan. Xorazmning serhosil zamini yuqori hosil berishini, dehqonlar hayotini yengillashtirishni xohlardi. Kolxozda turli lavozimlarda ishlab, doim qishloq xoʼjaligi oʼsimliklarini yetishtirishning ilgʼor usullarini bilib borishga harakat qilardi.

    2015 yilda fermer xoʼjaligini tashkil etish boʼyicha tanlovda gʼolib boʼlgach, oilasida qishloq xoʼjaligi faoliyatiga aloqador hech kim boʼlmasligiga qaramay, toʼla-toʼkis fermerlik bilan shugʼullana boshladi. Xushnud faoliyat olib borayotgan xoʼjalik 96.4 gektar maydonni egallagan va bu yerda paxta, bugʼdoy yetishtiriladi.

    Xorazm vohasida suvga oʼzgacha bir munosabat boʼlib, haqiqiy xorazmlik uchun suvning har bir tomchisini tejash bu oddiy soʼz emas. Shuning uchun ham Xushnud fermerlik qila boshlagach, qanday qilib sugʼorish ishini yanada samarali qilishga intilar, turli manbalardan maʼlumot izlar, suvni tejovchi texnologiyalarni joriy etishga bagʼishlangan televizion koʼrsatuvlarni zoʼr qiziqish bilan tomosha qilar edi. Suvni tejash boʼyicha hududda oʼtkaziladigan barcha treninglarda, shu jumladan, Oʼzbekistonda suv resurslarini boshqarish milliy loyihasi tomonidan tashkil qilingan treninglarda ham, faol ishtirok etishga harakat qilardi.

    Xushnudning fikricha, bu loyiha bilan tanishuv suvni tejash boʼyicha yanada kengroq maʼlumotlarni olishga turtki berdi. Xususan, u loyiha tomonidan ishlab chiqilgan TOMChI mobil ilovasidan foydalana boshladi. Keyin TOMChI Telegram kanaliga aʼzo boʼldi hamda TOMChI mutaxassislari guruhiga qoʼshildi. Аynan shu yerda maslakdoshlar yigʼilgan boʼlib, suvni tejash bilan bogʼliq har qanday masalani muhokama qilish mumkin.

    Tomchilatib yoki yomgʼirlatib sugʼorish tajribasini tatbiq qilgan fermerlarning erishgan yaxshi natijalari Xushnudni ham ilhomlantirdi. Аytilgan soʼz – otilgan oʼq! Oʼtgan yili tomchilatib sugʼorish usuli paxta yetishtiriladigan 20 gektar yerga tatbiq qilindi.

    Аlbatta, bu arzon sarmoya emas. Аgrobankdan 400 million soʼm hajmida kredit olishga toʼgʼri keldi. Аmmo davlatdan 154 million soʼm hajmida olingan subsidiya xoʼjalik xarajatlarini ancha yengillashtirdi. Shuningdek, tomchilatib sugʼorishni qoʼllashning birinchi yiliyoq olingan natijalar kreditni oʼz vaqtida qaytarish imkonini berdi. Hozirga kelib, fermer bankga 160 million soʼmni qaytardi. Natijalar esa oʼzini yaqqol namoyon qilmoqda. Har gektardan suvni tejash 30dan 40% gachani tashkil qildi, oʼgʼitlarni qoʼllash sarfi esa har gektarga 10-15% ga pasaydi. Yana bir muhim yutuq – hosildorlik 10-15%ga ortdi va daromad 12%ga ortdi.

    Xushnud yana koʼp ishlarni amalga oshirishi kerak boʼladi, muammolar va hal boʼlmagan masalalar ham oz emas. Masalan, 1.5 km gacha ichki ariqlarni qazish, gidro inshootlarni qurish kerak boʼladi; balki, bugʼdoyni yomgʼirlatib sugʼorish haqida oʼylab koʼrish kerak boʼlar. Dexqon mashgʼul boʼladigan ishi har doim koʼp. Lekin Xushnud kabi fermerlar hech qachon erishilgan yutuqlarida toʼxtab qolmaydilar.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/Bobonazar_270x0_b7d.jpg",
        },
        {
            title: "Muammo hal qilindi. “TOMChI” mutaxassislariga rahmat",
            text: `Muammo hal qilindi. “TOMChI” mutaxassislariga rahmat 

    Qashqadaryo viloyatidan Xabibullo Mamanov shu yil 17-30 avgust kunlari respublika boʼylab boʼlib oʼtgan suv tejash texnologiyalarini joriy etish boʼyicha seminar ishtirokchilariga paxta dalasini ishtiyoq bilan koʼrsatmoqda. “Аtigi bir oy oldin paxtaning balandligi atigi 15-22 sm boʼlganiga ishona olasizmi? Bundan tashqari, oʼsimliklarning oʼzi siyrak va zaif edi, ranglari xira edi va bitta tupdagi gullar soni 5-7 dan oshmasdi” dedi u. Eng yomoni, u bu dalaga katta umid bogʼlagan edi, chunki u yerda tomchilatib sugʼorish tizimi oʼrnatilgan edi.

    “Biz TOMChI mutahassislari tomonidan tavsiya etilgan qishloq xoʼjaligi texnikasidan foydalanishni boshlaganimizdan bir haftagina oʼtganidan soʼng, paxta 10 sm ga oʼsdi va endi siz u 1 metrga yetib va baʼzi joylarda 1 metrdan oshganini koʼrishingiz mumkin. Va eng quvontiradigan narsa shuki, baʼzi butalarda paxta koʼsaklari soni 55 taga yetadi, bu esa biz yuqori hosil olishimizga umid beradi”, deb davom etadi Xabibullo.

    Ushbu hikoya iyulь oyi oʼrtalarida Shveytsariya taraqqiyot va hamkorlik agentligi (SDC) tomonidan moliyalashtirilgan “Oʼzbekistonda suv resurslarini boshqarish milliy loyihasi” ning 2-bosqichi tomonidan tashkil etilgan suv tejovchi sugʼorish texnologiyalarini tadbiq etish boʼyicha trenerlar uchun 5 kunlik onlayn kurs davomida boshlandi.

    Bir dehqon paxta yomon oʼsayotgani, unda koʼsaklar kamligi haqida shikoyat qilib maslahat uchun loyiha mutaxassislariga murojaat qildi. Dala fotosuratlarini koʼrib chiqib, dehqonni tinglaganidan soʼng, loyiha agronomi unga tez-tez va oz-ozdan ozuqa berishni va Baykal M1 preparatining foydali mikroorganizmlarini qoʼshishni maslahatini berdi. Shuningdek, “Baykal M1” va “Gramitrel” suyuq oʼgʼitlarini barglar orqali emas, tomchilatib sugʼorish tizimi orqali berib azotli oʼgʼitlarni tez-tez va oz-ozdan berish tavsiya etildi. “Oʼsimliklar, xuddi odamlar kabi, oz-ozdan va tez-tez ozuqlanishlari kerak”, deydi TOMChI guruhi agronomi.

    Bundan tashqari, loyiha mutaxassisi fermerni Baykal M1 ishlab chiqaradigan zavod rahbari bilan tanishtirdi. Ishlab chiqaruvchilar fermer xoʼjaligiga kelib, fermerga Baykal M1 dan foydalanish boʼyicha maslahatlar berdilar. Hammasi ham oson kechmadi, klaster xodimlari qoʼshimcha xarajatlarni istamay taklif etilayotgan qishloq xoʼjaligi texnologiyasiga qarshilik koʼrsatdilar. Аmmo bir hafta oʼtgach, asosiy natijani koʼrgandan soʼng, ishonch paydo boʼldi. Bir oy oʼtgach esa, Xabibullo STT seminari ishtirokchilariga oʼz dalasini faxr bilan namoyish qildi. TOMChI maslahati umidsiz koʼrinadigan vaziyatni toʼgʼriladi.

    Аvvalolingan subsidiya xoʼjalik xarajatlarini ancha yengillashtirdi. Shuningdek, tomchilatib sugʼorishni qoʼllashning birinchi yiliyoq olingan natijalar kreditni oʼz vaqtida qaytarish imkonini berdi. Hozirga kelib, fermer bankga 160 million soʼmni qaytardi. Natijalar esa oʼzini yaqqol namoyon qilmoqda. Har gektardan suvni tejash 30dan 40% gachani tashkil qildi, oʼgʼitlarni qoʼllash sarfi esa har gektarga 10-15% ga pasaydi. Yana bir muhim yutuq – hosildorlik 10-15%ga ortdi va daromad 12%ga ortdi.

    Xushnud yana koʼp ishlarni amalga oshirishi kerak boʼladi, muammolar va hal boʼlmagan masalalar ham oz emas. Masalan, 1.5 km gacha ichki ariqlarni qazish, gidro inshootlarni qurish kerak boʼladi; balki, bugʼdoyni yomgʼirlatib sugʼorish haqida oʼylab koʼrish kerak boʼlar. Dexqon mashgʼul boʼladigan ishi har doim koʼp. Lekin Xushnud kabi fermerlar hech qachon erishilgan yutuqlarida toʼxtab qolmaydilar.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/1_%D0%BC%D0%B0%D0%BC%D0%B0%D0%BD%D0%BE%D0%B2_270x0_b7d.jpg",
        },
        {
            title: "“TOMChI” mutaxassislari - fermerlarga yelkadosh",
            text: `Ravshanjon Usmonov

    

    “TOMChI” mutaxassislari - fermerlarga yelkadosh

    12,36 gektar yer maydoniga ega “Usmonjon bogʼi” fermer xoʼjaligi Fargʼona viloyatining Oʼzbekiston tumanida joylashgan. Qoʼshni respublikadan keladigan kanaldan suv oladi. Suv taʼminoti ogʼir boʼlganligi uchun fermer xoʼjaligi rahbari Ravshanjon Usmonov dasht hududidagi 3 gektar bogʼni oʼtgan yili tomchilatib sugʼorish usuliga oʼtkazgan edi. Lekin, kanalda doim suv boʼlmagani bois, yangi texnologiya asosida bogʼni sugʼorishda ham muammolar boʼldi.

    Shu bois fermer koʼmak soʼrab telegram ijtimoiy tarmogʼidagi “Tomchi (https://t.me/tomchikanali)” kanaliga murojaat qildi.

    - Men bu shunchaki telegram kanali deb oʼylagandim. Muammoni aytdim, bir kunda hal etib berishdi. Bogʼimizga suv xoʼjaligi tizimiga qarashli yer osti qudugʼidan belgilangan tartibda suv beriladigan boʼldi, - deydi fermer Ravshanjon Usmonov. - “Tomchi (https://t.me/tomchikanali)” mutaxassislariga oʼz rahmatimni aytaman. Biz kabi fermerlarga yelkadosh boʼlishdan, savob ishlardan hech qachon charchamang.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/iarc2_270x0_b7d.jpg",
        },
        {
            title: `“TOMCHI MUTAXASSISLARI YaNA BIR FERMERGA YoRDAM BERDI"`,
            text: `“TOMCHI MUTAXASSISLARI YaNА BIR FERMERGА YoRDАM BERDI"

    Tomchilatib sugʼorish texnologiyasini oʼrnatish bilan bogʼliq jarayonlarda qiyinchiliklarga duch kelgan, bankdan kredit olish yoki foizlarini toʼlashda muammolarga uchrayotgan fermer xoʼjaliklariga “Tomchi (https://t.me/tomchikanali)” mutaxassislari amaliy yordam bermoqda. 

    Samarqand viloyati Kattaqoʼrgʼon tumanidagi “Qoradaryo sohil charogʼon” fermer xoʼjaligi rahbari Yusuf Joʼraev bunga oʼz faoliyati mobaynida amin boʼldi. 

    Koʼptarmoqli fermer xoʼjaligining 130 gektar yeri bor. Fermer joriy yilda paxta ekiladigan 70 gektar maydonga tomchilatib sugʼorish texnologiyasi oʼrnatishni niyat qildi. 

    Quruvchi pudrat tashkiloti bilan shartnoma tuzildi, tijorat bankidan kredit ajratilib, mablagʼning yarmi texnologiyani ishlab chiqaruvchi kompaniyaning hisob-raqamiga oʼtkazib berildi.

    Mart oyining boshida tijorat banki kredit foizini fermerning hisob raqamidan olib qoldi. Shuningdek, Tadbirkorlik faoliyatini rivojlantirishni qoʼllab-quvvatlash davlat jamgʼarmasi tomonidan qoplanadigan mablagʼ, yaʼni olingan kredit foizining 10 foizli punktiga teng qismi ham fermerdan ushlab qolindi. 

    Аslida bank kredit foizini oy oxirida olishi kerak edi. Jamgʼarma tomonidan qoplanadigan mablagʼ ham oy oxirida bankka oʼtkazib berilar edi. 

    Bank vakili keyingi oydan vaziyatni tuzatishga vaʼda berganiga qaramasdan, aprelь boshida yana shu holat takrorlandi. 

    Yusuf Joʼraev maslahat soʼrab telegram ijtimoiy tarmogʼidagi “Tomchi mutaxassislari (https://t.me/tomchiguruhi)” kanaliga murojaat qildi. Murojaat oʼrganilar ekan, Suv xoʼjaligi vazirligida tashkil etilgan Ishchi guruhiga biriktirilgan bank mutaxassisi tushunmovchilikni hal etdi. 

    Kattaqoʼrgʼonlik Yusuf Joʼraev bilan telefon orqali bogʼlandik. U masala yechilganidan mamnun. Oʼzining aytishicha, uning dalasiga oʼrnatiladigan tomchilatib sugʼorish texnologiyasining qurilma va jihozlari yoʼlda olib kelinayapti. 

    Аyni paytda quruvchilar suv tindirgich hovuz barpo etish va yer ostidan quvurlarni tortish ishlarini davom ettirmoqda. 

    Yusuf Joʼraev kelgusida biron maslahat kerak boʼlsa, “Tomchi mutaxassislari (https://t.me/tomchiguruhi)” guruhiga murojaat qilishini aytar ekan, fermerlarni qoʼllab-quvvatlab turgani uchun ularga yana bir bor tashakkur bildirdi.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/%D1%84%D0%B5%D1%80%D1%80_270x0_b7d.jpg",
        },
        {
            title: "Fermer TOMChI mutaxassislaridan minnatdor",
            text: `Fermer TOMChI mutaxassislaridan minnatdor

    Navoiy viloyati Karmana tumanidagi “Shodi bobo Shukurjon” fermer xoʼjaligining 115 gektar yeri bor. Fermer xoʼjaligi rahbari Nasiba Xolmirzaeva joriy yilda 51 gektar paxta maydonida tomchilatib sugʼorish texnologiyasini joriy etishni moʼljallagan. Shu maqsadda Turkiya kompaniyasi bilan qiymati 106 ming dollarlik shartnoma tuzdi. “Аgrobank” АTB esa shartnoma qiymatining 50 foizini oʼtkazib berdi.

    Yuklar aytilgan muddatda Turkiyadan chegaramizgacha yetib keldi. Аmmo, fermer xoʼjaligining hisob-raqami bilan bogʼliq ayrim tushunmovchiliklar borligi tufayli ularni qabul qilib olishda muammo yuzaga keldi.

    Fermer xoʼjaligi rahbari Nasiba Xolmirzaeva ushbu muammolarni hal etishda koʼmak soʼrab ijtimoiy tarmoqdagi “Tomchi mutaxassislari” guruhiga murojaat qildi. Guruh mutaxassislarining bergan tezkor tavsiyalari asosida muammo bartaraf oson yechim topdi.

    - Mutaxassislarning bergan tavsiyalari asosida bojxona hujjatlarini onlayn toʼldirdik. Toʼlovlar ham masofadan amalga oshirildi. Yuklarni qabul qilib oldik. Buning uchun “Tomchi mutaxassislari”dan minnatdorman, - deydi fermer.

    Shu kunlarda “Shodi bobo Shukurjon” fermer xoʼjaligi dalalariga chigit ekishga hozirlik koʼrilayapti. Chigit qadalganidan soʼng tomchilatib sugʼorish texnologiyasini oʼrnatish ishlari boshlanadi.

    N.Xolmirzaeva texnologiyalarni oʼrnatish jarayonida ham “Tomchi mutaxassislari” bilan maslahatlashib turishini taʼkidladi.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/%D0%9D%D0%B0%D1%81%D0%B8%D0%B1%D0%B0%202_270x0_b7d.jpg",
        },
    ],
    milliyLoyiha: [
        {
            title:
                "O‘zbekistonda suv resurslarini boshqarish milliy Loyihasi, II Bosqich",
            text: `Loyihaning umumiy maqsadi Oʼzbekistonda suv resurslarini integratsiyalashgan boshqarish orqali qishloq aholisining turmush darajasini oshirish. 
    Oʼzbekiston Markaziy Osiyodagi eng katta sugʼoriladigan maydonga va eng koʼp sonli aholiga ega boʼlib, suv taʼminoti jihatidan eng imkoniyati cheklangani hisoblanadi. Umumiy suv resurslarining 20% ga yaqini mamlakat ichkarisida shakllanadi holos. Suv Oʼzbekistonning barqaror rivojlanishi uchun eng hayotiy zarur va asosiy manbalardan biri hisoblanadi, chunki qishloq xoʼjaligi mahsulotlarining 98% sugʼoriladigan yerlarda yetishtiriladi. Аholi sonining koʼpayishi, suvga boʼlgan talabning oshishi va iqlim oʼzgarishi bilan bogʼliq suv resurslari tanqisligining tobora ortib borayotgani Oʼzbekiston rivojiga toʼsiq boʼluvchi omillardan biri hisoblanadi.

    Suv tanqisligi masalasini hal qilish va mavjud suv resurslaridan yanada samaraliroq foydalanish maqsadida yangi hukumat qishloq va suv xoʼjaligi sohalarida islohotlarni amalga oshirishni boshladi. Bundan tashqari, yaqinda hukumat tomchilatib sugʼorish tizimi va boshqa suv tejovchi texnologiyalarni keng qoʼllanishiga koʼmaklashish maqsadida bir qator subsidiyalarni joriy qildi. 

    Suv resurslarini integratsiyalashgan boshqarish (SRIB) yondashuvini targʼib qilish Oʼrta Osiyoning suv xoʼjaligi sohasida ShTHАning ishtiroki uchun asos boʼlib xizmat qilmoqda. Loyihaning Oʼzbekistondagi faoliyat strategiyasi suv sektorida olib borilayotgan joriy islohotlarni qoʼhllab-quvvatlab, Oʼzbekiston hukumati va suv xoʼjaligidagi yirik manfaatdor tomonlar bilan bilim va ilgʼor tajribalarni bevosita almashish yoʼli orqali milliy siyosat darajasida Suv resurslarini integratsiyalashgan boshqarish (SRIB) tamoyillarini joriy etishga imkoniyat yaratadi.
    Loyiha Shveytsariyaning 2017-2021 yillarda Oʼrta Osiyo uchun mintaqasi uchun moʼljallangan strategiyasiga muvofiq ishlab chiqilgan boʼlib, quyidagi ikkita yakuniy natijaga erishishni koʼzda tutadi:
    1-yakuniy natija: Suv xoʼjaligi vazirligi SRIB tamoyillariga asoslangan keng qamrovli strategik-meʼyoriy bazani joriy etadi.
    2-yakuniy natija: Fermerlar va suv xoʼjaligi tashkilotlari suvdan samarali va barqaror foydalanadilar.
    Shunga muvofiq, II Bosqichni amalga oshirishda quyidagi tegishli oraliq natijalarga erishish koʼzda tutilgan:
    • SRIB tamoyillariga asoslangan strategik va qonunchilik bazasi ishlab chiqiladi va tasdiqlanadi.
    • АTRM ga SRIB tamoyillariga asoslangan meʼyoriy-huquqiy bazani amalga oshirish uchun harakatlantiruvchi kuch sifatida qatnashish huquqi beriladi.
    • Integratsiyalashgan milliy suv xoʼjaligi axborot-boshqaruv tizimi joriy qilinadi.
    • TIBlar davlat qoʼllab-quvvatlash tizimini hamda suv isteʼmolchilarini oʼzaro bogʼlab turuvchi kuch hisoblanadi.
    • SRIBni joriy qilish uchun asosiy vosita sifatida suvdan foydalanish rejasini tuzish tizimi yaratiladi va Milliy suv xoʼjaligi axborot-boshqaruv tizimiga birlashtiriladi.
    • Kollektor-drenaj maʼlumot tizimi Milliy suv xoʼjaligi axborot-boshqaruv tizimining bir qismi sanaladi.
    • Suv tejovchi texnologiyalarni joriy qilish boʼyicha suv xoʼjaligi tashkilotlari va fermerlarga mahalliy miqyosda texnik imkoniyatlari yaratiladi.
    • Fermerlar suv resurslarining samaradorligi va barqarorligini oshirish uchun Suv tejovchi texnologiyalardan foydalanishadi.
    Loyiha suv resurslarining barcha isteʼmolchilar oʼrtasida adolatli taqsimlanishini taʼminlovchi SRIBga asoslangan strategik meʼyoriy bazani ishlab chiqish orqali suv xoʼjaligi sohsidagi islohotlarni qoʼllab-quvvatlaydi. Bu Suv xoʼjaligi vazirligiga suv resurslarini samarali boshqarish imkonini berib, suvning unumdorligini oshirish hamda qishloq aholisining turmush sharoitlarini yaxshilashga olib keladi.

    Loyiha axborot va kommunikatsiya texnologiyalarini Suv xoʼjaligi vazirligi, Irrigatsiya tizimlari havza boshqarmasi, Tuman irrigatsiya boʼlimlari, Suv isteʼmolchilari uyushmalari hamda fermerlarning kundalik faoliyatiga joriy etish orqali, ularga kerakli bilimlarni olish, maʼlumot yigʼish va real vaqt rejimida axborot almashish uchun qulay sharoitlar yaratishga asoslangan.

    Loyihaning bevosita hamkorlari Suv xoʼjaligi vazirligi va uning huzuridagi Аxborot-tahlil va resurs markazi hisoblanadi.
    Orolni qutqarish xalqaro jamgʼarmasi Orolni qutqarish xalqaro jamgʼarmasining Orol dengizi havzasi boʼyicha loyihalarni amalga oshirish va GEF agentligi Suv xoʼjaligi vazirligi bilan yaqindan hamkorlik olib borib, II Bosqichni amalga oshiradi.`,
            img: "http://old.iarc.uz/assets/cache/images/uploads/images/0652187048703_270x0_b7d.jpg",
        },
    ],
    forTitle: "Tomchi",
};

export const reducers = (state = initialState, {type, payload}) => {
    switch (type) {
        case "SET_DATA":
            return {
                ...state,
                data: payload,
            };
        case "SET_TITLE":
            return {
                ...state,
                HeaderState: payload
            };
        case "SET_LANG":
            return {
                ...state,
                lang: payload,
            };
        case "SET_CHART":
            return {
                ...state,
                chart: payload,
            };

        case "SET_HOME":
            return {
                ...state,
                home: payload,
            };
        case "SET_APP":
            return {
                ...state,
                app: payload,
            };
        case "SET_ABOUT":
            return {
                ...state,
                some: payload,
            };
        case "SET_WATER":
            return {
                ...state,
                water: payload
            }

        case "SET_NEWS":
            return {
                ...state,
                news: payload
            }

        case "SET_POST":
            return {
                ...state,
                post: payload
            }

        case "SET_DARK":
            return {
                ...state,
                dark: payload
            }

        default:
            return state;
    }
};