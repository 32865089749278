import { Pagination } from "antd";
import React, {useEffect, useState} from "react";
import Acardion from "./Acardion";
import { paginate } from "../../../components/paginate/paginate";
import {request} from "../../../api/request";

export default function SavolJavob({lang}) {
const [data, setData] = useState([]);
  const fetchAnswers = async () => {
    request
        .get(`/tomchi/answers?lang=${lang === null ? 'uz' : lang}`, {
          headers:{
            "api-token":"p12378"
          }
        })
        .then((res) => {
          setData(res.data.data)
        })
        .catch((err) => console.log(err))
  }
  useEffect(() => {
    fetchAnswers()
  },[lang]);


  const [page, setPage] = useState(1);
  const pagenated = paginate(data, page, 10);
  return (
    <div>
      <Acardion data={pagenated} />
      <Pagination
        defaultCurrent={1}
        onChange={(e) => setPage(e)}
        total={data.length}
      />
    </div>
  );
}
