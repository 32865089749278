import React, { useState } from "react"
import parser from 'html-react-parser'
const Card = ({ el }) => {

    const [changeOne, setChangeOne] = useState(false)


    return (
        <div className="direc-card">
            <div className="card-top">
                <div className="profile-photo">
                    <img src={el.image} alt="" />
                </div>
                <div className="card-info">
                    <p>
                        {el.title}
                    </p>
                    <h2>
                        {el.fio}
                    </h2>
                    <a href={`mailto: ${el.email}`}>
                        Pochtaga yozish
                    </a>
                </div>
            </div>

            <div className="card-bottom">
                <button className={`${changeOne === 1 ? "active" : ""}`} id={el.id} onClick={() => changeOne !== 1 ? setChangeOne(1) : setChangeOne(null)}>Umumiy ma'lumot</button>
                <button className={`${changeOne === 2 ? "active" : ""}`} id={el.id} onClick={() => changeOne !== 2 ? setChangeOne(2) : setChangeOne(null)}>Vazifalar taqsimoti</button>
                
                <div className="change-block">
                    <div className={`open-info ${changeOne === 1 ? "active" : ""}`}>
                        <div className="infos border">
                            <span>Telefon raqami</span>
                            <a href={`tel=${el.tel_number}`}>{el.tel_number}</a>
                        </div>
                        <div className="infos border">
                            <span>Qabul kunlari</span>
                            <h4>{el.day_reception}</h4>
                        </div>
                        <div className="infos border">
                            <span>Ish vaqti</span>
                            <h4>{el.work_day}</h4>
                        </div>
                        <div className="infos">
                            <span>Xona raqami</span>
                            <h4>{el.room_number}</h4>
                        </div>
                    </div>


                    <div className={`another-info ${changeOne === 2 ? "active" : ""}`}>
                        <div className="tasks" style={{
                            margin: "10px 0",
                            background: "#fff",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius:"10px"}}>{parser(`${el.task}`)}</div>
                    </div>
                </div>



            </div>
        </div>
    )
}
export default Card