import './experience.scss'
import './experienceResponse.scss'
import {useState,useEffect}from 'react'
import {
    Hero
}from '../../container/index'
import TomchiLogo from '../../assets/icons/TOMCHILOGO.png'
import QRcode from '../../assets/qrCode.png'
import {
    News,
    DownloadZip,
    Helm
}from '../../components/index'
import React from 'react'

import { useSelector } from 'react-redux'
import { request } from '../../api/request'
const Experience = () =>{
    const mobile_app = useSelector(state=>state.data.app)
    const lang = useSelector(state=>state.data.lang)
    const news = useSelector(state => state.data.news)

    const [pageContent, setPageContent]=useState({isFetched:false,data:{},error:null})
    useEffect(()=>{
        request
        .get(`/segment/all/seminar?lang=${lang === null?'uz':lang}`,{headers:{"api-token":"p12378"}})
        .then((res)=>{setPageContent({isFetched:true,data:res.data.data.reverse(),error:false})})
        .catch((err)=>{setPageContent({isFetched:true,data:[],error:err})})
    },[lang])
    return (
      <div className="experience">
        <Helm id={"35"}/>
        <div className="hero-page">
          <Hero
            id={"35"}
            nameOne={lang === 'uz' ? 'Bosh sahifa' : lang === 'ru' ? 'Домой' : lang === 'en' ? 'Home page' : ''}
            nameTwo={lang === 'uz' ? 'Malaka oshirish' : lang === 'ru' ? 'Профессиональное развитие' : lang === 'en' ? 'Professional development' : ''}
            linkOne={"/"}
            linkTwo={"/experience"}
          />
        </div>
        <div className="inner-page container">
          <div className="column-one">

            <a
              className="tomchi-link"
              href={`${mobile_app ? mobile_app.data[43].settings_value : ""}`}
            >
              <img src={QRcode} alt="" />
            </a>
            <div className="tomchi-links">
                <img src={TomchiLogo} alt="" />
                <h3 style={{
                  fontSize: '13px'
                }} className='tomchi-title'>QR code skanerlang va Tomchi mobil ilovasini yuklab oling.</h3>
              </div>

          </div>
          

          <div className="column-two">
            <div className="holder-title">
              <h1>{lang === 'uz'? ' Malaka oshirish' : lang === 'ru' ? 'Профессиональное развитие' : lang === 'en' ? 'Experience' : ''}</h1>
            </div>
            <div className="exper-blocks" id="linkone">
              {pageContent.isFetched ? (
                pageContent.data &&
                pageContent.data.map((el, inx) => (
                  <DownloadZip
                    image={el.image}
                    title={el.title}
                    key={inx}
                    id={el.id}
                    link={el.file}
                    size={el.file_size}
                  />
                ))
              ) : (
                <h1>Loading ...</h1>
              )}
            </div>

          </div>

          <div className="column-thr">
            <h1 className="news-holder">{lang === 'uz' ? 'So’ngi yangiliklar' : lang === 'ru' ? 'последние новости' : lang === 'en' ? 'Last news' : ''}</h1>
            {news && news.status === 1 ? (
              news.data &&
              news.data.map((el, inx) => (
                <News
                  key={inx}
                  date={el.created_on}
                  title={el.title}
                  id={el.id}
                />
              ))
            ) : (
              <h1>Loading ...</h1>
            )}
          </div>
        </div>
      </div>
    );
}

export default Experience