import './hero.scss'
import HeroBg from '../../assets/images/abouthero.png'
import { BreadCrumb } from '../../components'
import React from 'react'

const HeroTwo = ({title,linkOne,nameOne,nameTwo})=>{
    return (
        <div
            className="hero-about"
            style={{ backgroundImage: `url(${HeroBg})` }}
        >
            <h1 className="hero-title">
                {
  title
                }
            </h1>
            <div className="overlay"></div>
            <BreadCrumb nameOne={nameOne} nameTwo={nameTwo} linkOne={linkOne}/>
        </div>
    )
}




export default HeroTwo

// window.location.includes("post") ? "yanfgiliklar" : ""
