import { Pagination } from "antd";
import React, { useState } from "react";
import Card2 from "../../components/Card/Card2";
import "./tomchi.scss";

export default function MenuComponent({lang}) {
  const [menu, setMenu] = useState([
    {
      path: "/tomchi/about",
      title: {
        uz:"TOMCHI mobile ilova haqida",
        ru:"О мобильном приложении TOMCHI",
        en:"About TOMCHI mobile application"
      },
      img: "http://old.iarc.uz/assets/cache/images/uploads/images/%D0%BD%D0%BD_270x0_b7d.jpg",
    },
    {
      path: "/tomchi/savol_javob",
      title: {
        uz:"Sizdan savol - bizdan javob",
        ru:"Вопрос от вас - ответ от нас",
        en:"Question from you - an answer from us"
      },
      img: "http://old.iarc.uz/assets/cache/images/uploads/images/tomchi/customer-support-frequently-asked-questions_270x0_b7d.jpg",
    },
    {
      path: "/tomchi/milliy_loyiha",
      title: {
        uz:"O'zbekiston suv resurslarini boshqarish milliy loyihasi",
        ru:"Национальный проект управления водными ресурсами Узбекистана",
        en:"National project of water resources management of Uzbekistan"
      },
      img: "http://old.iarc.uz/assets/cache/images/uploads/images/259b5550ae698b288c30d87027821895-1038x576_270x0_b7d.jpg",
    },
    {
      path: "/tomchi/benefitsiarlar",
      title: {
        uz:"Benefitsiarlar ovozi",
        ru:"Мнение бенефициара",
        en:"Beneficiary opinion",
      },
      img: "http://old.iarc.uz/assets/cache/images/uploads/images/photo_2021-02-21_17-23-07_270x0_b7d.jpg",
    },
    {
      path: "/tomchi/loyiha_hikoyasi",
      title: {
        uz:"Loyiha hikoyasi",
        ru:"История проекта",
        en:"Project story"
      },
      img: "http://old.iarc.uz/assets/cache/images/uploads/images/17_270x0_d6d.png",
    },
  ]);

  return (
    <div>
      {menu.map((e) => (
        <Card2 title={lang === "uz"
            ? e.title.uz
            : lang === "ru"
                ? e.title.ru
                : lang === "en"
                    ? e.title.en
                    : e.title.uz} link={e.path} img={e.img} />
      ))}
    </div>
  );
}
