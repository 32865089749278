import { Form, Input, Button } from 'antd';
import axios from 'axios';
import React from 'react'
const Forma = () => {


  const onFinish = (values) => {
    const form = new FormData()
    form.append("username", values.username)
    form.append("password", values.password)

    const config = {
      method: 'post',
      url: `http://iarc-api.iarc.uz/account/signin`,
      headers: { 
          'api-token': 'p12378'
      },
      data : form

  };
  axios(config)
      .then(res => {
        console.log('kkkk',res.data.data);
        window.localStorage.setItem('USER_CREDENTIALS', JSON.stringify({ token: res.data.data.user_token, signature: res.data.data.user_signature, fullName: res.data.data.user.fullname }))
        window.location.reload();
      })
      .catch(err => console.log(err))

  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };



  return (
    <>
    
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>


      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    </>
  );
};


export default Forma