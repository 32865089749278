import {
  Home,
  About,
  KnowPack,
  Experience,
  Project,
  Events,
  Single,
  NewsPage,
  Seminar,
  WaterSaving,
  SingleTwo
}from './pages/index'
import React from 'react'
import BackToTop from "react-back-to-top-button";


import { set_app } from './redux/action'
import { set_water } from './redux/action'
import { set_news } from './redux/action'
import { set_post } from './redux/action'
import { request } from './api/request'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Arrow from './assets/icons/up-arrow.png'
import Favicon from 'react-favicon'

import {
  Header,
  Footer
}from './container/index'

import {
  Switch,
  Route,
} from "react-router-dom";

import "./assets/styles/main.scss";
import Map from "./pages/Map/Map";
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import GMap from "./pages/GMap";
import Tomchi from "./pages/Tomchi/Tomchi";
import NotFound from "./pages/notFound/NotFound";

function App() {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.data.lang);
// console.clear()



  useEffect(() => {

    // settings //
    request
      .get(`/system/settings/all?lang=${lang}`, {
        headers: {
          "api-token": "p12378",
        },
      })
      .then((res) => dispatch(set_app(res.data)))
      .catch((err) => console.log(err));

    // water saving //  
      request
      .get(`/content/all/water?lang=${lang === null ? 'uz' : lang}`, {
        headers:{
          "api-token":"p12378"
        }
      })
      .then((res)=> dispatch(set_water(res.data)))
      .catch((err) => console.log(err))

    // news
      request
      .get(`/content/all/post?lang=${lang === null ? 'uz' : lang}&sortby=view_count&sort=DESC&limit=5`, {
        headers:{
          "api-token":"p12378"
        }
      })
      .then((res) => dispatch(set_news(res.data)))
      .catch((err) => console.log(err))


    // home hero banner
    request
      .get(`/content/all/post?lang=${lang === null ? 'uz' : lang}&post_main_item=1`,{
        headers:{
          "api-token":"p12378"
        }
      })
      .then(res => dispatch(set_post(res.data)))
      .catch(err => console.log(err))

  }, [lang]);

 
  const logo = useSelector((state) => state.data.app);

  return (
    <>
    {/*<ScrollToTop/>*/}
      <div className="App">
        {logo && logo.status === 1 ? (
          logo.data &&
          logo.data.map((el, index) =>
            el.settings_key === "site_favicon" ? (
              <Favicon key={index} url={`${el.settings_value}`} />
            ) : (
              ""
            )
          )
        ) : (
          <></>
        )}
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/knowpack" component={KnowPack} />
            <Route path="/experience" component={Experience} />
            <Route path="/project/:page" component={Project} />
            <Route path="/events" component={Events} />
            <Route path="/single/:type/:id" component={Single} />
            <Route path="/singleTwo/:id" component={SingleTwo} />
            <Route path="/news/*" component={NotFound} />
            <Route path="/news" component={NewsPage} />
            <Route path="/seminar/:id" component={Seminar} />
            <Route path="/waterSaving" component={WaterSaving} />
            <Route path="/map" component={GMap} />
            <Route path="/tomchi" component={Tomchi} />
          </Switch>
          <Footer />
      </div>
      <BackToTop
        showOnScrollUp
        showAt={100}
        speed={1500}
        easing="easeInOutQuint"
      >
        <img src={Arrow} alt="" />
      </BackToTop>
    </>
  );
}

export default App;
