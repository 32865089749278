import "./events.scss";
import "./eventsResponse.scss";
import { Link } from "react-router-dom";
import { Hero } from "../../container/index";
import React, { useState } from "react";
import { News, Helm } from "../../components/index";
import { useEffect } from "react";
import { request } from "../../api/request";
import TomchiLogo from '../../assets/icons/TOMCHILOGO.png'
import { useDispatch } from "react-redux";
import { set_data } from "../../redux/action";
import { useSelector } from "react-redux";
import parser from "html-react-parser";
import QRcode from '../../assets/qrCode.png'
const Events = () => {
  const dispatch = useDispatch();

  const event = useSelector((state) => state.data.data);
  const lang = useSelector((state) => state.data.lang);
  const news = useSelector(state => state.data.news)
  const [categories, setCategories] = useState([])

  const [active, setActive] = useState(0)

  useEffect(() => {
    request
      .get(`/content/all/event?sort=DESC&sortby=id&lang=${lang === null ? "uz" : lang}`, {
        headers: {
          "api-token": "p12378",
        },
      })

      .then((res) => dispatch(set_data(res.data)))
      .catch((err) => console.log(err));

      request
      .get(`/segment/all/event_category?lang=${lang === null ? "uz" : lang}`, {
        headers: {
          "api-token": "p12378",
        },
      })

      .then((res) => setCategories(res.data))
      .catch((err) => console.log(err));
  }, [lang]);
  return (
    <div className="events-page">
      <Helm id={"37"} />
      <div className="hero-page">
        <Hero
          id={"37"}
          nameOne={
            lang === "uz"
              ? "Bosh sahifa"
              : lang === "ru"
              ? "Домой"
              : lang === "en"
              ? "Home page"
              : ""
          }
          nameTwo={
            lang === "uz"
              ? "Ochiq malumotlar"
              : lang === "ru"
              ? "Открытые данные"
              : lang === "en"
              ? "Open data"
              : ""
          }
          linkOne={"/"}
          linkTwo={"/events"}
        />
      </div>

      <div className="inner-pages container">
        <div className="col-one">
          <div className="events-category" style={{display: "none"}}>
          
          {
               categories && categories.status === 1 ? (
                   categories.data && categories.data.map((el, index) =>(
                       <button key={index} className={`${active === index ? 'active' : ''}`} onClick={()=> setActive(index)}>{el.title}</button>
                   ))
               ): null
           }
          
          </div>
          <div className="tomchi">
              <img src={QRcode} alt="" />
          </div>
          <div className="tomchi-links">
                <img src={TomchiLogo} alt="" />
                <h3 style={{
                  fontSize: '13px'
                }} className='tomchi-title'>QR code skanerlang va Tomchi mobil ilovasini yuklab oling.</h3>
              </div>
        </div>
        <div className="col-two">
          <div className="heading">
            <h1 className="news-holder">Ochiq ma'lumotlar</h1>
          </div>
          {event && event.status === 1 ? (
            event.data &&
            event.data.map((el, index) => (
              <div className="events-card" key={index}>
                <div className="events-card_heading">
                  <div className="event-theme">{parser(el.title)}</div>
                </div>

                <div className="events-date" style={{display: 'none'}}>
                  <div className="dates">
                    <h4>Бошланиш вақти: </h4>
                    <span>{el.begin_date_time}</span>
                  </div>
                  <div className="dates">
                    <h4>Тугаш вақти: </h4>
                    <span>{el.end_date_time}</span>
                  </div>
                </div>

                <div className="events-title">
                  <div className="card-title">
                    {parser(el.description)}
                    <Link href="#link" to={`/single/event/${el.id}`} style={{display: 'none'}}>
                      {lang === "uz"
                        ? "ko'rish"
                        : lang === "ru"
                        ? "видеть"
                        : lang === "en"
                        ? "see more"
                        : ""}
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h1>Loading ...</h1>
          )}
        </div>
        <div className="col-thr">
          <h1 className="news-holder">
            {lang === "uz"
              ? "So’ngi yangiliklar"
              : lang === "ru"
              ? "последние новости"
              : lang === "en"
              ? "Last news"
              : ""}
          </h1>
          {news && news.status === 1 ? (
            news.data &&
            news.data.reverse().map((el, index) => (
              <News
                date={el.created_on}
                key={index}
                title={el.title}
                id={el.id}
              />
            ))
          ) : (
            <h1>Loading ...</h1>
          )}
        </div>
      </div>

      <div className="margin"></div>
    </div>
  );
};

export default Events;
