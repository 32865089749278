import React, {useState} from 'react';
import Popup from "./Popup";


function Socials({lang}) {

    const [popup, setPopup] = useState(false)
    function title({lang}){
        switch (lang){
            case 'uz': return "TOMCHI ijtimoiy tarmoqlarda"; break;
            case 'ru': return "ТОМЧИ в социальных сетях"; break;
            case 'en': return "TOMCHI on social networks"; break;
            default: return "TOMCHI ijtimoiy tarmoqlarda"; break;
        }
    }
    return (
        <div>
            <h2>{title(lang)}</h2>
            <div className="socials-block">
                <div className="block">
                    <a href="https://www.youtube.com/@Tomchi" target="_blank">
                        <img src="/img/youtube.png" alt=""/>
                    </a>
                </div>
                <div className="block">
                    <a href="https://www.facebook.com/groups/tomchiguruhi/" target="_blank">
                        <img src="/img/fb.png" alt=""/>
                    </a>
                </div>
                <div className="block">
                    <a href="#" onClick={(e)=>{e.preventDefault();setPopup(true)}} target="_blank">
                        <img src="/img/tg.png" alt=""/>
                    </a>
                </div>
                {
                    popup?<Popup setPopup={setPopup}/>:null
                }
            </div>
        </div>
    );
}

export default Socials;