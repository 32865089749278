import React, {useState} from "react";
import L, {GeoJSON, MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import './MapContent.css'
import regions from '../../../assets/geo/regions.json'
import districts from '../../../assets/geo/districts.json'
import region_centers from '../../../assets/geo/rcenters.json'
import dcenters from '../../../assets/geo/districtscenter.json'

export default React.memo(
function MapContent(props){

    const [map, setMap] = useState(null)
    const [stroke,setStroke] = useState([1,1,1,1,1,1,1,1,1,1,1,1,1,1])
    const [fill,setFill] = useState(["#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00"])
    const fillColor = ["#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00","#0000ff00"]
    const [distStroke,setDistStroke] = useState([1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1])
    const [soato, setSoato] = useState(null)
    const [distr,setDistr] = useState([]);
    const L = require('leaflet');

    const myIcon = L.icon({
        iconUrl: require('../../../assets/icons/marker-icon.png'),
        iconAnchor:[12,41],
        iconSize: [25,41],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });
    const setDistricts = (s) => {
        setDistr([])
        setSoato(s)
        const dists = [];
        districts['features'].map(a=>{
            if(a.properties.soato!==null){
                if(a.properties.soato.toString().includes(s)){
                    dists.push(a)
                }
            }
            return false
        })
        setDistr(dists)
    }
    const setmarker = (dis) => {

        let m = <Marker position={dis.center} icon={myIcon}><Popup className="custom-popup">{dis.properties['DISTR_NAME']}</Popup></Marker>
        return m
    }
    return (
        <div className="map-content">
            <MapContainer
                center={[41.559953,63.292248]} zoom={6} scrollWheelZoom={true}
                ref={setMap}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    regions['features'].map((reg,i)=>{
                        return <GeoJSON eventHandlers={{
                            click: (e) =>{
                                map.fitBounds(e.target.getBounds())
                                setDistricts(reg.properties['soato'])
                                let a = fillColor
                                a[i] = "#FF7F7F"
                                setFill(a)
                                props.onFilterByRegion(reg.properties['OBJECTID'])
                                props.onFilterByDistrict(0)
                            },
                            mouseout:() => {
                                let str = [1,1,1,1,1,1,1,1,1,1,1,1,1,1]
                                setStroke(str)
                            },
                            mouseover: () => {
                                let str = [1,1,1,1,1,1,1,1,1,1,1,1,1,1]
                                str[i] = 3
                                setStroke(str)
                            },

                        }} pathOptions={{ fillColor: fill[i], color: 'green', weight:stroke[i]}} key={i} data={reg.geometry}/>
                    })
                }
                {
                    props.regions && props.regions.length ?
                        region_centers.map((c,i)=>{
                           return (props.regions.toString().slice(",").includes((c.id)) || props.regions.includes(null) ) && soato !== c.soato ?
                                <Marker key={i} position={c.latlng}>
                                    <Popup>
                                        {c.name}
                                    </Popup>
                                </Marker>
                            : null
                        }):null
                }
                {
                    distr.map((dis,i)=>{
                        return (
                            <div key={i}>
                                <GeoJSON title={"asdaad"} eventHandlers={{
                                    click: (e) =>{
                                        map.fitBounds(e.target.getBounds())
                                        props.onFilterByDistrict("t"+dis.properties['OBJECTID'].toString()+"n")
                                    },
                                    mouseout:() => {
                                        setDistStroke([1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1])
                                    },
                                    mouseover: () => {
                                        let str2 = [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
                                        str2[i] = 3
                                        setDistStroke(str2)
                                    },

                                }} pathOptions={{ fillColor: '#0000ff00', color: '#3388ff', weight:distStroke[i]}} key={i} data={dis.geometry} filter={false}/>
                                {

                                    dis && typeof(dis.center) !== "undefined" && props.districts.toString().split(",").includes("t"+dis.properties['OBJECTID'].toString()+"n")?
                                        setmarker(dis):null
                                }
                            </div>
                        )
                    })
                }
            </MapContainer>
        </div>
    )
});

// export default MapContent;