import React, {useEffect, useState} from "react";
import styled from "styled-components";
import axios from "axios";
import {request} from "../../../api/request";
import {set_news} from "../../../redux/action";

const Wrapper = styled.div`
  h3 {
    font-size: 18px;
  }
  .top-img {
    margin-top: 60px;
    margin-bottom: 20px;
    img {
      width: 140px;
    }
  }
  .bottom-img {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    img {
      width: 33%;
    }
  }
  .havolalar {
    h1 {
      margin-top: 30px;
      font-size: 24px;
    }
    h4 {
      margin: 30px 0;
      font-size: 16px;
    }
  }
`;

export default function About({lang}) {
    const fetchAbout = async () => {
        request
            .get(`/tomchi/about?lang=${lang === null ? 'uz' : lang}`, {
                headers:{
                    "api-token":"p12378"
                }
            })
            // .then((res) => setContent(JSON))
            .then((res) => {
                console.log(res)
                if(typeof res.data.data !== 'undefined'){
                    if(typeof res.data.data.content !== 'undefined'){
                        document.getElementById('wrap').innerHTML = res.data.data.content
                    }
                }
            })
            .catch((err) => console.log(err))
    }
    useEffect(() => {
        fetchAbout()
    },[lang]);
    return (
        <Wrapper id="wrap"></Wrapper>
    );
}
