import { Link } from "react-router-dom";
import "./news.scss";

import React from 'react'

const News = ({ date, title, id }) => {
  return (
    <Link to={`/single/post/${id}`}>
      <div className="news-wrapper">
        <div className="news">
          <div className="top">
            <h2 className="date">{date}</h2>
          </div>

          <div className="news-overview">
            <p className="title">{title}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default News;
