import { Fragment, useEffect, useState } from "react"
import parser from 'html-react-parser'
import { request } from "../../api/request"
import { useSelector } from "react-redux"
import React from 'react'

const Structure = ()=>{

    
    const [structure, setStructure] = useState({
        isFetched:false,
        data:{},
        error:null
    })

    const lang = useSelector(state =>state.data.lang)

    useEffect(()=>{
        request
        .get(`/content/one/about?id=41&lang=${lang === null ? 'uz' : lang}`,{
            headers:{
                "api-token":"p12378"
            }
        })

        .then((res)=>{
            setStructure({
                isFetched:true,
                data:res.data.data,
                error:false
            })
        })

        .catch((err)=>{
            setStructure({
                isFetched:true,
                data:[],
                error:err
            })
        })
    },[lang])

    return (
        <Fragment>
            <h1 className="nizom-title">{structure.data && structure.data.title}</h1>
            <div>{parser(`${structure.data && structure.data.description}`)}</div>
        </Fragment>
    )
}

export default Structure