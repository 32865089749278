import {Link, NavLink}from 'react-router-dom'
import React from 'react'
import './headermenu.scss'
import './headermenuResponse.scss'
import { useState, useEffect } from 'react'
import { request } from '../../api/request'
import { useSelector, useDispatch } from 'react-redux'
import { set_lang } from '../../redux/action'

const HeaderMenu = ()=>{

    const lang = useSelector(state =>state.data.lang)
    const [menu, setMenu] = useState({
        isFetched:false,
        data:{},
        error:null
    })

    const dispatch = useDispatch()
    const onChange = (lang)=>{
        dispatch(set_lang(lang))
        window.localStorage.setItem("lang", lang)
        setBold(lang)
    }
    const [bold, setBold] = useState(window.localStorage.getItem("lang"))


    useEffect(()=>{
        request
        .get(`/menu/all?lang=${lang}`,{
            headers:{
                "api-token":"p12378"
            }
        })
        .then(res=>{
            setMenu({
                isFetched:true,
                data:res.data.data,
                error:false
            })
        })
        .catch(err=>{
            setMenu({
                isFetched:true,
                data:[],
                error:err
            })
        })
    },[lang])


    


    return (
        <div className="menu-wrapper">
            <div className="inner-menu">
                <div className="header-bottom">
                {
            menu.isFetched ? (
              menu.data.map((el, index) => (
                <ul class="dropdown" key={index}>
                  {
                    el.group_key === "main-menu" ? (
                      el.child.map((el, index) => (
                        <li key={index}>
                          {el.data.link_target === "_blank" ? (
                            <a
                              href={el.data.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={`drop-link_name ${el.child && el.child.length !== 0 ? "" : "activeTwo"
                                }`}
                            >
                              {el.data.name}
                            </a>
                          ) : (
                            <NavLink
                              to={`${el.data.link}`}
                              exact
                              className={`drop-link_name ${el.child && el.child.length !== 0 ? "" : "activeTwo"
                                }`}
                            >
                              {el.data.name}
                            </NavLink>
                          )}
                          <ul>
                            {el.child &&
                              el.child.map((item, index) => (
                                <li key={index}>
                                  {
                                    item.data.link_target === "_blank" ? (
                                      <a
                                        key={index}
                                        href={item.data.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="forward-link"
                                      >
                                        {item.data.name}
                                      </a>
                                    ) : (
                                      <Link to={item.data.link} className="forward-link">
                                        {item.data.name}
                                      </Link>
                                    )

                                  }

                                  <ul>
                                    {item.child &&
                                      item.child.map((item, index) => (
                                        <li key={index}>
                                          {
                                            item.data.link_target === "_blank" ? (
                                              <a
                                                key={index}
                                                href={item.data.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="forward-link"
                                              >
                                                {item.data.name}
                                              </a>
                                            ) : (
                                              <Link to={item.data.link} className="forward-link">
                                                {item.data.name}
                                              </Link>
                                            )

                                          }

                                          <ul>
                                            {item.child &&
                                              item.child.map((item, index) => (
                                                <li key={index}>
                                                  {
                                                    item.data.link_target === "_blank" ? (
                                                      <a
                                                        key={index}
                                                        href={item.data.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="forward-link"
                                                      >
                                                        {item.data.name}
                                                      </a>
                                                    ) : (
                                                      <Link to={item.data.link} className="forward-link">
                                                        {item.data.name}
                                                      </Link>
                                                    )

                                                  }

                                                  <ul>
                                                    {

                                                    }
                                                  </ul>
                                                </li>
                                              ))}
                                          </ul>
                                        </li>
                                      ))}
                                  </ul>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))
                    ) : (
                      null
                    )
                  }
                </ul>
              ))
            ) : (
              null
            )
          }
                </div>
            </div>
            <div className="languages">
              <ul>
                <li>
                  <button
                    className={`${bold === "uz" ? "active" : ""}`}
                    onClick={(e) => {
                      onChange("uz");
                    }}
                  >
                    Uzb
                  </button>
                </li>
                <li>
                  <button
                    className={`${bold === "ru" ? "active" : ""}`}
                    onClick={() => {
                      onChange("ru");
                    }}
                  >
                    Pyc
                  </button>
                </li>
                <li>
                  <button
                    className={`${bold === "en" ? "active" : ""}`}
                    onClick={() => {
                      onChange("en");
                    }}
                  >
                    Eng
                  </button>
                </li>
              </ul>
            </div>
        </div>
    )
}


export default HeaderMenu
