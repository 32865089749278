import { Fragment } from "react"
import { useSelector } from "react-redux"
import { useState,useEffect } from "react"
import parser from 'html-react-parser'
import { request } from "../../api/request"
import React from 'react'
import './down.scss'
const Down = ()=>{
    const [down, setDown] = useState({
        isFetched:false,
        data:{},
        error:null
    })
    const lang = useSelector(state =>state.data.lang)

    useEffect(()=>{
        request
        .get(`/content/one/about?id=43&lang=${lang === null?'uz':lang}`,{
            headers:{
                "api-token":"p12378"
            }
        })

        .then((res)=>{
            setDown({
                isFetched:true,
                data:res.data.data,
                error:false
            })
        })

        .catch((err)=>{
            setDown({
                isFetched:true,
                data:[],
                error:err
            })
        })
    },[lang])

    return(
        <Fragment>
            <h1 className="nizom-title">{down.data && down.data.title}</h1>
            <div className="desc-block">{parser(`${down.data && down.data.description}`)}</div>
        </Fragment>
    )
}

export default Down